import { IAnalytics, PlatformAnalyticsFactory } from '@myworkplace/api';
import { IRootElementService, IScope } from 'angular';
import { isInternet as isInternetHost } from '../../../util/host.utils';
import { ILanguage } from '../../../util/language.model.interface';
import { UrlHelper } from '../../../util/url.helper';
import { ActionConstants } from '../../actionLog/action-constants';
import { ActionLogService } from '../../actionLog/action-log.service';
import { ApplicationControlOptions, ApplicationControls, IApplication } from '../../apps/application.model.interface';
import { AppsService } from '../../apps/apps.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import { TrackingService } from '../../feature-tracking/tracking.service';
import {
  ILayoutServiceChannelEventMessage,
  ILayoutServiceChannelResizeMessage,
  ILayoutServiceChannelUpdateMessage,
  LayoutService,
} from '../../layout/layout.service';
import { IMenuServiceSubMenuMessage, MenuService } from '../../menu/menu.service';
import {
  UserWebComponentSettings,
  WebComponentSettingsService,
} from '../../web-components/web-component-settings.service';
import { PopupService } from '../../notification/popup.service';
import { ITab } from '../../tab/tab';
import { ITabContent } from '../../tab/tab.content.interface';
import { TabManager } from '../../tab/tab.manager';
import { ITabServiceChannelTabMessage, TabService } from '../../tab/tab.service';
import { IWorkplaceTask } from '../../task/tasks.model.interface';
import { TasksService } from '../../task/tasks.service';
import { User } from '../../user/user.model';
import { IUserServiceAuthUpdatedMessage, UserService } from '../../user/user.service';
import { MwpStoreService } from '../../web-components/mwp-store/mwp-store.service';
import { IWorkplaceProperty } from '../../workplace/workplace-property.interface';
import { WorkplaceApiService } from '../../workplace/workplace.api.service';
import { WorkplaceContextService } from '../../workplace/workplace.context.service';
import { LAYOUT_SETTINGS_FEATURES } from '../analytics/feature-analytics-conf-layout';
import { Component } from '../component';
import { APP_COPY_LINK, DASHBOARD_COPY_LINK, TAB_FEATURES } from './analytics/feature-analytics-conf';
import { OnboardingFlowService } from '../../web-components/onboarding-flow/onboarding-flow.service';
import { ViewToggleService } from '../../viewToggle/view.toggle.service';
import { DashboardActionsService } from '../../dashboard/dashboard-actions/dashboard-actions.service';
import { NavigationComponentsService } from '../../web-components/navigation/navigation.service';
import { WebComponentSharedHandlersService } from '../../web-components/web-component-shared-handlers.service';

('use strict');

/**
 * Tabview component
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class Tabview extends Component {
  private static readonly CREATE_ACTION_LOG_FUNC = 'createActionLog';
  /**
   * Provided by attribute
   */
  id: string;
  /**
   * Provided by attribute.
   * Defaults to 20.
   */
  maxTabs: number = TabManager.MAX_TABS;

  minTabWidth = 120;
  /**
   * Current split direction
   * @type {string}
   */
  splitDirection: string = 'row';
  layoutServiceChannelEvent: IChannelDefinition<ILayoutServiceChannelEventMessage>;
  channelResize: IChannelDefinition<ILayoutServiceChannelResizeMessage>;
  channelTabChange: any;
  openFilesModal = {
    fileTitle: '',
    fileUrl: '',
    isModalOpen: false,
  };

  private linkModalEl: Element & { [key: string]: any };

  public layoutService: LayoutService;
  isInternet: boolean = true;
  selectedDashboardId = '';
  private authLevelUpdated: IChannelDefinition<IUserServiceAuthUpdatedMessage>;
  private _user: User;
  private _subscriptions: ISubscriptionDefinition<any>[];
  private _timeoutService: ng.ITimeoutService;
  private _appsService: AppsService;
  private _promiseUpdate: ng.IPromise<any>;
  private _userService: UserService;
  private _menuService: MenuService;
  private _popupService: PopupService;
  private _dashboardService: DashboardService;
  private _workplaceApiService: WorkplaceApiService;
  private _workplaceContextService: WorkplaceContextService;
  private _actionLogService: ActionLogService;
  private contextMenuClicked: boolean;
  private dashboardsMenuSettings: UserWebComponentSettings;
  private authLevel: number;
  private DASHBOARDS_MENU_ID: string = 'dashboards-menu';
  private environment = '';

  // Handler for the outputs sent by the finder menu
  private finderMenuHandlers = {
    openAppFromMwpWebComponent: (event: MouseEvent) => this.webComponentSharedHandlersService.openAppHandler(event),
    openDashboard: event => this.webComponentSharedHandlersService.openDashboardHandler(event.detail),
    openLink: (event: MouseEvent) => {
      this.webComponentSharedHandlersService.openLinkHandler(event);
    },
    openFileModal: (event: MouseEvent) => this.openFileModal(event),

    openOnboardingFlow: async () => {
      await this.onboardingFlowService.startOnboardingScreen({ triggeredFromInsideMyWorkplace: true });
    },
  };

  //Handlers for the outputs sent by the SEARCH MENU
  private searchMenuHandlers = {
    openAppFromMwpWebComponent: (event: MouseEvent) => this.webComponentSharedHandlersService.openAppHandler(event),
    openStoreAppDetails: (event: MouseEvent) =>
      this.webComponentSharedHandlersService.openStoreAppDetailsHandler(event),
    copyLink: (event: MouseEvent) => this.webComponentSharedHandlersService.copyLinkHandler(event),
    openDashboard: event => this.webComponentSharedHandlersService.openDashboardHandler(event.detail),
    openInNewWindow: (event: MouseEvent) => this.webComponentSharedHandlersService.openInNewWindowHandler(event),
    openLink: (event: MouseEvent) => this.webComponentSharedHandlersService.openLinkHandler(event),
    openInWidgetStore: (event: MouseEvent) =>
      this.webComponentSharedHandlersService.openStoreWidgetDetailsHandler(event),
    openFileModal: (event: MouseEvent) => this.openFileModal(event),
  };

  // STORE HANDLERS - TO BE DELETED BEFORE FINDER RELEASE
  private mwpStoreHandlers = {
    openAppFromMwpWebComponent: (event: MouseEvent) => this.webComponentSharedHandlersService.openAppHandler(event),
    shareItem: (event: MouseEvent) => this.webComponentSharedHandlersService.shareItemHandler(event),
  };

  // Handlers for the outputs sent by the SETTINGS MENU
  private navbarRightMenusHandlers = {
    roleChange: (event: MouseEvent) => this.selectRole(),
    logout: () => this._popupService.showLogoutConfirmationDialog(),
    switchAuth: () => this.openLoginModal(),
    openAppFromMwpWebComponent: (event: MouseEvent) => this.webComponentSharedHandlersService.openAppHandler(event),
    openInWidgetStore: (event: MouseEvent) =>
      this.webComponentSharedHandlersService.openStoreWidgetDetailsHandler(event),
    emitLink: (event: CustomEvent) => this.emitLinkHandler(event),
    openAppAction: (event: { detail: { appId: string; url?: string } }) => {
      const { appId: name, url: path } = event.detail;
      this._appsService.openAppByName({ name, path });
    },
    splitScreen: (event: MouseEvent) => this._viewToggleService.splitScreen(event.detail as unknown as string),
  };

  private headerTabsHandlers = {
    selectTab: (event: MouseEvent) => this.handleTabClick(event.detail as unknown as string),
    closeTab: (event: MouseEvent) => this.closeTab(event.detail as unknown as string),
    copyLink: (event: MouseEvent) => this.copyDeepLink(this.tabService.getTabById(event.detail as unknown as string)),
    refreshTab: (event: MouseEvent) => this.handleRefreshClick(event.detail as unknown as string),
    showContextHelp: (event: MouseEvent) => this.handleHelpCLick(event.detail as unknown as string),
    openNewWindow: (event: MouseEvent) => this.webComponentSharedHandlersService.openInNewWindowHandler(event),
    showHiddenTab: (event: MouseEvent) => this.showHiddenTab(event.detail as unknown as string),
    tabSort: (event: MouseEvent) => {
      const { tabId, newIndex, tabViewId } = event.detail as unknown as {
        tabId: string;
        newIndex: number;
        tabViewId?: string;
      };
      this.onTabSort(tabId, newIndex, tabViewId);
    },
  };

  private NAVIGATION_HANDLERS = {
    mwpSearch: this.searchMenuHandlers,
    navbarRightSideMenus: this.navbarRightMenusHandlers,
    finder: this.finderMenuHandlers,
    headerTabs: this.headerTabsHandlers,
    //TO BE DELETED BEFORE THE FINDER RELEASE
    mwpStore: this.mwpStoreHandlers,
  };
  private headerTabsEl: Element & { [key: string]: any };
  private _viewToggleService: ViewToggleService;

  /**
   * @ngInject
   */
  constructor(
    readonly tabService: TabService,
    layoutService: LayoutService,
    $timeout: ng.ITimeoutService,
    appsService: AppsService,
    $animate: angular.animate.IAnimateService,
    jQuery: JQueryStatic,
    readonly userService: UserService,
    menuService: MenuService,
    popupService: PopupService,
    dashboardService: DashboardService,
    workplaceApiService: WorkplaceApiService,
    workplaceContextService: WorkplaceContextService,
    private readonly onboardingFlowService: OnboardingFlowService,
    actionLogService: ActionLogService,
    readonly language: ILanguage,
    private webComponentSettingsService: WebComponentSettingsService,
    private readonly postal: IPostal,
    private readonly mwpStoreService: MwpStoreService,
    private readonly tasksService: TasksService,
    private readonly trackingService: TrackingService,
    private readonly $element: IRootElementService,
    private readonly navigationComponentsService: NavigationComponentsService,
    private readonly webComponentSharedHandlersService: WebComponentSharedHandlersService,
    private readonly viewToggleService: ViewToggleService,
    private readonly dashboardActionsService: DashboardActionsService
  ) {
    super();
    this._timeoutService = $timeout;
    this.layoutService = layoutService;
    this._appsService = appsService;
    this._menuService = menuService;
    this._popupService = popupService;
    this._dashboardService = dashboardService;
    this._workplaceApiService = workplaceApiService;
    this._workplaceContextService = workplaceContextService;
    this._actionLogService = actionLogService;
    this._subscriptions = [];
    this.layoutServiceChannelEvent = postal.channel(LayoutService.CHANNEL_EVENT);
    this._userService = this.userService;
    this.userService.getUser().then((user: User) => {
      this._user = user;
      this.authLevel = this._user.authLevel;
    });
    this.isInternet = isInternetHost();
    this.setupAuthLevelChannel();
    this.channelResize = postal.channel(LayoutService.CHANNEL_RESIZE);
    this._viewToggleService = viewToggleService;
    this._workplaceContextService.getProperty('workplace.environment').then((prop: IWorkplaceProperty) => {
      this.environment = prop.value;
      this.setUpAnalytics();
    });
  }

  /**
   * All tabs displayed by this component
   * @returns {ITab[]}
   */
  get tabs(): ITab<ITabContent>[] {
    if (this.tabService && this.tabService.getTabManager(this.id)) {
      this.setPageTitle();
      return this.tabService.getTabManager(this.id).tabs;
    }
    return [];
  }

  /**
   * All tabs that are currently invisible
   * @returns {ITab[]}
   */
  get invisibleTabs(): ITab<ITabContent>[] {
    return this.tabs.filter((tab: ITab<ITabContent>) => !tab.visible);
  }

  /**
   * Current user id
   */
  get userId(): string | null {
    if (isInternetHost()) {
      return this._user?.cnumber || null;
    }
    return this._user?.userId || null;
  }

  setUpAnalytics(): void {
    this.trackingService.setTracker(this.environment !== 'dev', this.getPlatformAnalytics());
    this.trackingService.getTracker().initFeatures(TAB_FEATURES);
    this.trackingService.getTracker().initFeatures(LAYOUT_SETTINGS_FEATURES);
  }

  setupAuthLevelChannel() {
    this.authLevelUpdated = this.postal.channel(WorkplaceContextService.UPDATED_AUTH_LEVEL);
    this.authLevelUpdated.subscribe(
      WorkplaceContextService.UPDATED_AUTH_LEVEL,
      ({ authLevel }) => (this.authLevel = authLevel)
    );
  }

  setPageTitle() {
    if (!this._user) {
      return;
    }

    let tabManagers = this.tabService.getTabManagers();
    let arrOfTabTitles = [];

    for (let tabManager in tabManagers) {
      this.tabService.getTabManager(tabManager).tabs.forEach((tab: any) => {
        if (tab.active && tab.title !== 'myWorkplace') {
          arrOfTabTitles.push(tab.title);
        }
      });
    }
    document.title = this.composeTitle(arrOfTabTitles);
  }

  composeTitle(arrOfTabTitles) {
    const baseTitle = 'myWorkplace';
    if (arrOfTabTitles.length > 0) {
      return baseTitle + ' - ' + arrOfTabTitles.join(' | ');
    }
    return baseTitle;
  }

  isDefaultDashboardTab(tab: ITab<ITabContent>): boolean {
    return this.tabService.isDefaultDashboardTab(tab);
  }

  /**
   * Copy the deep link of a tab
   * @param tab
   */
  copyDeepLink(tab: any) {
    if (tab.content.app) {
      this.trackingService.getTracker().trackFeature(APP_COPY_LINK, 1);
    } else if (tab.content.dashboardName) {
      this.trackingService.getTracker().trackFeature(DASHBOARD_COPY_LINK, 1);
    }

    let url = '';
    let pathName = window.location.pathname;

    // if tab has url
    if (tab.content.app && tab.content.app.url) {
      url = `${window.location.origin}${window.location.pathname}#/app/${tab.content.app.name}`;
      if (tab.path) {
        let params = UrlHelper.fromUrl(tab.path);
        let esParams = UrlHelper.toQueryString(params);
        let path = UrlHelper.removeQueryString(tab.path.split().pop()).replace(UrlHelper.HASH_REG_EXP, '');
        let regHash = UrlHelper.HASH_REG_EXP.exec(tab.path);
        let hash = regHash ? regHash[1] : null;
        if (hash && !esParams) {
          esParams = '-';
        }
        if (path && path.charAt(0) !== '/') {
          path = '/' + path;
        }

        let dEncodedPath = path ? encodeURIComponent(encodeURIComponent(path)) : '';
        let dEncodedHash = hash ? encodeURIComponent(encodeURIComponent(hash)) : '';
        url = url + `${dEncodedPath}/${esParams}${dEncodedHash ? '/' + dEncodedHash : ''}`;
      }
    }

    // if tab is dashboard
    if (tab.content.containsDashboard) {
      url = `${window.location.origin}${pathName}#/dashboard/${tab.content.dashboardName}`;
    }

    // if tab is a layout
    if (tab.content.frameLayout) {
      url = `${window.location.origin}${pathName}#/layout/${tab.content.frameLayout.id}`;
    }

    this.webComponentSharedHandlersService.copyLinkHandler({ detail: url } as any);
  }

  /**
   * Close a given tab
   *
   * @param tabId
   */
  closeTab(tabId: string): void {
    this.tabService.closeTab(tabId);
  }

  /**
   * Show a tab that has been previously hidden (e.g. is in tab dropdown menu)
   * @param tabId
   */
  showHiddenTab(tabId: string): void {
    this.tabService.moveTabToBack(tabId);
    this.tabService.selectTab(tabId);
  }

  /**
   * Move tabs from the tab header to the dropdown if a minimum width for a single tab has been reached
   * @param el
   */
  updateVisibleTabs(el: JQuery): void {
    if (this._promiseUpdate) {
      this._timeoutService.cancel(this._promiseUpdate);
    }
    this._promiseUpdate = this._timeoutService(() => {
      const headerTabs = el.find('.header-tabs');
      if (!headerTabs) return;

      const headerTabsWidth = headerTabs.width();
      const totalWidth = this.invisibleTabs ? headerTabsWidth - 64 : headerTabsWidth;
      // show at least one tab!
      const possibleTabs = Math.floor(totalWidth / this.minTabWidth) || 1;
      const tabsTooMany = this.tabs.length - possibleTabs;
      this.tabs.forEach(
        (tab: ITab<ITabContent>, index: number) =>
          (tab.visible = this.isDefaultDashboardTab(tab) || index >= tabsTooMany)
      );
      this._promiseUpdate = null;
      if (this.headerTabsEl) {
        this.updateTabsInput();
      }
    });
  }

  /**
   * Message callback from tab service, indicating that tabs have been changed
   * @param el
   * @param data
   * @param envelope
   */
  tabServiceMessageCallback(
    el: JQuery,
    data: ITabServiceChannelTabMessage,
    envelope: IEnvelope<ITabServiceChannelTabMessage>
  ): void {
    if (data.tabviewId === this.id) {
      this.updateVisibleTabs(el);
    }
  }

  /**
   * Message callback from layout service, indicating that viewport has been resized
   * @param el
   * @param data
   */
  layoutServiceResizeMessageCallback(el: JQuery, data: ILayoutServiceChannelResizeMessage): void {
    this.updateVisibleTabs(el);
  }

  /**
   * Message callback from layout service, indicating that viewport has been resized
   * @param el
   * @param data
   */
  layoutServiceResponsiveMessageCallback(el: JQuery, data: ILayoutServiceChannelResizeMessage): void {
    // nothing to do
  }

  /**
   * Message callback from layout service, layout has been changed, such as split or removal of a view
   * @param el
   * @param data
   */
  layoutServiceUpdateMessageCallback(el: JQuery, data: ILayoutServiceChannelUpdateMessage): void {
    this.updateVisibleTabs(el);
  }

  /**
   * Handle tab click
   * @param tabId
   */
  handleTabClick(tabId: string): void {
    this.tabService.selectTab(tabId);
    this.updateTabsInput();
    this.webComponentSharedHandlersService.closeMobileAppsMenu();
  }

  handleHelpCLick(tabId: string): void {
    const tab = this.tabService.getTabById(tabId);
    this._appsService.openHelp(tab.content.app);
  }

  onTabSort(tabId: string, index: number, tabViewId?: string): void {
    const offset = this.invisibleTabs.length;
    const newIndex = offset + index + (this.isDefaultDashboardTab(this.tabs[0]) ? 1 : 0);

    if (tabViewId && tabViewId !== this.id) {
      this._timeoutService(() => this.tabService.moveTabToIndex(tabId, newIndex, this.id));
    } else {
      this.tabService.moveTabToIndex(tabId, newIndex);
    }
  }

  /**
   * Component has been rendered
   * @param el
   * @param scope
   */
  async onRenderComponent(el: JQuery, scope: IScope): Promise<void> {
    await this.loadMwpWebComponents();
    this.updateVisibleTabs(el);

    this._subscriptions.push(
      this.tabService.channel.subscribe(TabService.TOPIC_TAB_ADD, this.tabServiceMessageCallback.bind(this, el))
    );
    this._subscriptions.push(
      this.tabService.channel.subscribe(TabService.TOPIC_TAB_REMOVE, this.tabServiceMessageCallback.bind(this, el))
    );
    this._subscriptions.push(
      this.tabService.channel.subscribe(TabService.TOPIC_TAB_SELECT, (message: ITabServiceChannelTabMessage) => {
        const tab = this.tabService.getTabById(message.tabId);
        if (!tab) return;

        this.setPageTitle();
        this.updateTabsInput();

        if (!tab.visible) {
          this.tabService.moveTabToBack(tab.id);
        }

        if (!tab.content['containsDashboard']) return;

        this.selectedDashboardId = message.tabId;
        const shell = document.querySelector('mwp-shell');
        if (shell) {
          shell['selectedDashboardId'] = this.selectedDashboardId;
        }
      })
    );
    this._subscriptions.push(
      this.tabService.channel.subscribe(TabService.TOPIC_TAB_MOVE, this.tabServiceMessageCallback.bind(this, el))
    );
    this._subscriptions.push(
      this.layoutService.channelResize.subscribe(
        LayoutService.TOPIC_RESIZE,
        this.layoutServiceResizeMessageCallback.bind(this, el)
      )
    );
    this._subscriptions.push(
      this.layoutService.channelResize.subscribe(
        LayoutService.TOPIC_RESPONSIVE_LAYOUT,
        this.layoutServiceResponsiveMessageCallback.bind(this, el)
      )
    );
    this._subscriptions.push(
      this.layoutService.channelUpdate.subscribe(
        LayoutService.TOPIC_UPDATE,
        this.layoutServiceUpdateMessageCallback.bind(this, el)
      )
    );
    this._subscriptions.push(
      this._menuService.channelSubMenu.subscribe(MenuService.TOPIC_SUBMENU_SHOW, this.handleShowSubmenu.bind(this))
    );
    this._subscriptions.push(
      this.layoutService.channelSidebar.subscribe(LayoutService.TOPIC_SIDEBAR, this.closeOnSidebarOpen.bind(this))
    );
    this._subscriptions.push(
      this.layoutService.channelEvent.subscribe(
        LayoutService.TOPIC_EVENT_BODY_CLICK,
        this.closeSettingsOverlay.bind(this)
      )
    );
    this._subscriptions.push(
      this._dashboardService.channel.subscribe(
        DashboardService.TOPIC_DASHBOARD_CONTEXT_MENU_OPEN,
        this.closeSettingsOverlay.bind(this)
      )
    );
    this._subscriptions.push(
      this._menuService.channelSubMenu.subscribe(
        MenuService.TOPIC_SUBMENU_SHOWN,
        (message: IMenuServiceSubMenuMessage) => this.closeTabContextualMenus.bind(this)
      )
    );
    this._subscriptions.push(
      this.layoutServiceChannelEvent.subscribe(LayoutService.CLOSE_WEB_COMPONENTS_SIDE_PANEL, () =>
        this.navigationComponentsService.closeOverlays()
      )
    );
  }

  private createHeaderTabsSubscriptions(): void {
    const dashboardRefreshedSub = this.dashboardActionsService.channel.subscribe(
      DashboardActionsService.DASHBOARD_REFRESHED,
      () => this.updateTabsInput()
    );
    const dashboardRenamedSub = this._dashboardService.channel.subscribe(DashboardService.DASHBOARD_RENAMED, () =>
      this.updateTabsInput()
    );

    this._subscriptions.push(dashboardRefreshedSub, dashboardRenamedSub);
  }

  private updateTabsInput(): void {
    this.headerTabsEl.tabs = this.tabs;
  }

  handleShowSubmenu() {
    this.closeSettingsOverlay();
    this.navigationComponentsService.closeOverlays();
  }

  /**
   * Setup component
   */
  setupComponent(scope: ng.IScope): void {
    this.tabService.registerTabview(this.id, { maxTabs: this.maxTabs });
  }

  /**
   * Destroy component
   */
  destroy(): void {
    if (this._promiseUpdate) {
      this._timeoutService.cancel(this._promiseUpdate);
      this._promiseUpdate = null;
    }
    this._subscriptions.forEach((sub: ISubscriptionDefinition<any>) => sub.unsubscribe());
    this.tabs.forEach((tab: ITab<ITabContent>) => {
      tab.visible = true;
      tab.contextMenuExpanded = false;
    });
    this.layoutService = null;
    this._timeoutService = null;
    this._appsService = null;
    this._userService = null;
    this._popupService = null;
    this._workplaceContextService = null;
    this._actionLogService = null;
  }

  private getPlatformAnalytics(): IAnalytics {
    return PlatformAnalyticsFactory({
      callApi: (name: string, ...params: any[]): Promise<any> => {
        if (name !== Tabview.CREATE_ACTION_LOG_FUNC) {
          return Promise.reject('Wrong API function');
        }
        const logEntry = params[0];
        this._actionLogService.logAction(logEntry);
      },
    });
  }

  private injectStyles(css: string): void {
    const style = document.createElement('style');
    style.appendChild(document.createTextNode(css));
    this.$element.append(style);
  }

  handleRefreshClick(tabId: string): void {
    this.tabService.refreshTab(tabId);
  }

  private async openFileModal(event: MouseEvent) {
    const { title, url } = event.detail as any;

    this.openFilesModal.fileTitle = title;
    this.openFilesModal.fileUrl = url;

    if (!this.linkModalEl) {
      this.linkModalEl = await this._popupService.initFileModal('search');
    }

    if (this.linkModalEl) {
      this.linkModalEl.isModalOpen = true;
    }
  }

  private emitLinkHandler(event: CustomEvent): void {
    const {
      detail: { entityId, shareType, description },
    }: { detail: { entityId: string; shareType: string; description: string } } = event;

    const lowerCaseShareType = shareType.toLowerCase();

    if (lowerCaseShareType === 'dashboard') {
      this._dashboardService.openDashboardByName(entityId);
    } else if (shareType === 'STORE-ITEM-WIDGET' || shareType === 'STORE-ITEM-APPLICATION') {
      this.mwpStoreService.openStoreItemDetails(entityId, shareType);
    } else {
      this.tasksService.openTask(<IWorkplaceTask>{ id: entityId, system: 'workplace' });
    }
  }

  private openLoginModal() {
    const strongAuth = this.authLevel === 1000 ? 4000 : 7000;
    this._workplaceApiService.checkStrongAuthPermissions({
      strongAuth,
    } as IApplication);
  }

  private dismissDashboardsMenuTooltip = async () => {
    this.dashboardsMenuSettings = {
      settings: {
        ...this.dashboardsMenuSettings?.settings,
        tooltipDismissed: true,
      },
      updatedAt: new Date().getTime(),
    };

    await this.webComponentSettingsService.saveSettings(this.DASHBOARDS_MENU_ID, this.dashboardsMenuSettings);
  };

  /**
   * Closes the dropdown overlay when oder overlays are triggered somewhere in the workplace.
   */
  private closeSettingsOverlay(): void {
    if (this.contextMenuClicked) {
      this.contextMenuClicked = false;
      return;
    }
    this.closeTabContextualMenus();
  }

  private closeOnSidebarOpen($event: any): void {
    if ($event.config?.expanded) {
      this.closeTabContextualMenus();
      this.navigationComponentsService['searchEl']?.['closeSidePanel']();
    } else if ($event.config?.expanded === false) {
      this.navigationComponentsService.closeOverlays();
    }
  }

  private closeTabContextualMenus(): void {
    if (!this.tabs) {
      return;
    }
    this.tabs.forEach((tab: ITab<ITabContent>) => (tab.contextMenuExpanded = false));
  }

  private selectRole(): void {
    const role = this._user ? this._user.getSelectedRole() : null;
    this._actionLogService.logAction({
      category: ActionConstants.CATEGORY_ROLES,
      action: ActionConstants.ACTION_ROLE_MENU,
      actionInfo: 'select role',
    });
    this._userService.selectRole(role);
    this.webComponentSharedHandlersService.closeMobileAppsMenu();
  }

  private async loadMwpWebComponents() {
    this.navigationComponentsService.importAll(this.NAVIGATION_HANDLERS);
    this.headerTabsEl = await this.navigationComponentsService.importHeaderTabs(this.id);
    this.createHeaderTabsSubscriptions();
    this.splitScreenSubscribe();
    import('dashboards/guided-tour/styles').then(tour => this.injectStyles(tour.default.toString()));
  }

  private splitScreenSubscribe() {
    //Update the Header Tabs Element in order to tell the component that there is a new split screen
    const els = document.querySelectorAll(`header-tabs`);

    if (!els || els.length === 0) return;
    const isSingleTabHeader = els.length > 1;

    els.forEach((el: Element & { [key: string]: any }) => {
      el.hasSplitScreen = isSingleTabHeader;
    });
  }
}
