import { ITab } from './tab';
import { IFactory } from '../../util/factory.interface';
import { IApplication } from '../apps/application.model.interface';
import { TabManager } from './tab.manager';
import { ITabContent, ITabContentIFrame, ITabContentTemplate, TabContentType } from './tab.content.interface';
import _ from 'lodash';
import { ISupportConfig } from '../support/support-config.interface';
import { ActionConstants } from '../actionLog/action-constants';
import { ActionLogService } from '../actionLog/action-log.service';
import { IUserSettingsStorage } from '../workplace/user-settings-storage.interface';
import { IUserSettingsStoreable } from '../workplace/user-settings-storeable.interface';
import { TabHistoryStorage } from './tab.history.storage';

/**
 * Tab service handles state of multiple tab views.
 * Handling tabs is delegated to {@link TabManager} instances.
 * There is a default tab manager available.
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class TabService implements IUserSettingsStoreable {
  static CHANNEL: string = 'ChannelTabService';
  static CHANNEL_APP_SUPPORT: string = 'ChannelAppServiceTabService';
  static CHANNEL_TAB_SELECTION: string = 'ChannelTabSelection';
  static TOPIC_TAB_ADD: string = 'TabServiceTabAdd';
  static TOPIC_TAB_REMOVE: string = 'TabServiceTabRemove';
  static TOPIC_TAB_MOVE: string = 'TabServiceTabMove';
  static TOPIC_TAB_CLOSE: string = 'TabServiceTabClose';
  static TOPIC_TAB_REFRESH: string = 'TabServiceTabRefresh';
  static TOPIC_TAB_SELECT: string = 'TabServiceTabSelected';
  static TOPIC_TABVIEW_SPLIT: string = 'TabServiceTabviewSplit';
  static TOPIC_TABVIEW_UNREGISTER: string = 'TabServiceTabviewUnregister';
  static TOPIC_APP_SUPPORT: string = 'TabServiceTabviewAppSupport';
  static TOPIC_FIRST_TAB_SELECTION_WITH_APP: string = 'FirstTabSelectionWithApp';

  /**
   * Tab managers
   */
  tabManagers: { [index: string]: TabManager };
  tabManagerDefault: string;
  channel: IChannelDefinition<ITabServiceChannelTabMessage>;
  channelAppSupport: IChannelDefinition<ITabServiceAppSupportMessage>;
  channelTabSelection: IChannelDefinition<ITabServiceTabSelectionMessage>;
  settingsStorageKey = 'workplace';

  private _tabManagerFactory: IFactory<TabManager>;
  private _actionLogService: ActionLogService;
  private _userSettingsStorageService: IUserSettingsStorage;
  private tabsHistory: TabHistoryStorage[];

  /**
   * @constructor
   * @ngInject
   */
  constructor(
    postal: IPostal,
    tabManagerFactory: IFactory<TabManager>,
    actionLogService: ActionLogService,
    userSettingsStorageService: IUserSettingsStorage
  ) {
    this.tabManagers = {};
    this.channel = postal.channel(TabService.CHANNEL);
    this.channelAppSupport = postal.channel(TabService.CHANNEL_APP_SUPPORT);
    this.channelTabSelection = postal.channel(TabService.CHANNEL_TAB_SELECTION);
    this._tabManagerFactory = tabManagerFactory;
    this._actionLogService = actionLogService;
    this._userSettingsStorageService = userSettingsStorageService;
    this.tabsHistory = [];
  }

  getSettingsStoragePath(): string[] {
    return ['tabs'];
  }

  /**
   * Register a tabview. This will create a {@link TabManager} instance.
   * The tab manager becomes default in case there is no default yet.
   */
  registerTabview(id: string, config?: any): void {
    this.createTabmanager(id, config);
    if (!this.tabManagerDefault) {
      this.tabManagerDefault = id;
    }
  }

  /**
   *
   * Create a new tab manager
   *
   * @param id
   * @param config
   */
  createTabmanager(id: string, config?: any): void {
    if (!this.tabManagers[id]) {
      this.tabManagers[id] = this._tabManagerFactory.createInstance(id, config);
      this.tabsHistory.push(new TabHistoryStorage(this._userSettingsStorageService, id));
    }
  }

  removeFromTabsHistory(id: string): void {
    this.tabsHistory.forEach(th => th.remove(id));
  }

  /**
   * Unregister a tabview
   */
  unregisterTabview(id: string): void {
    if (_.keys(this.tabManagers).length <= 1) {
      return;
    }

    this.tabManagers[id] = null;
    delete this.tabManagers[id];
    if (id === this.tabManagerDefault) {
      this.tabManagerDefault = null;
    }
    this.publishTabviewUnregisterMessage({
      tabviewId: id,
      tabId: null,
    });
  }

  /**
   * Returns a TabManager for the given tabview id
   * @param tabviewId
   * @returns {TabManager} returns undefined if manager was not found
   */
  getTabManager(tabviewId: string): TabManager {
    return this.tabManagers[tabviewId];
  }

  /**
   * Get the default tab manager
   * @returns {TabManager}
   */
  getTabManagerDefault(): TabManager {
    return this.getTabManager(this.tabManagerDefault);
  }

  isDefaultDashboardTab(tab: ITab<ITabContent>): boolean {
    if (tab.content.type !== TabContentType.TEMPLATE) {
      return false;
    }

    if (!(<ITabContentTemplate>tab.content).containsDashboard) {
      return false;
    }

    return (
      (<ITabContentTemplate>tab.content).dashboardParentName === 'home' ||
      (<ITabContentTemplate>tab.content).dashboardName === 'home'
    );
  }

  /**
   * Set a tabview to be default for opening new tabs
   * @param tabviewId
   */
  setTabviewDefault(tabviewId: string): void {
    const tm = this.getTabManager(tabviewId);
    if (tm) {
      this.tabManagerDefault = tm.id;
    }
  }

  /**
   * Open a new tab. If no tabviewId has been provided, the tab will open in the default tabview.
   * @throws {Error} if there is tabview registered
   */
  openTabAt(tab: ITab<ITabContent>, index?: number, tabviewId?: string, autoSelect: boolean = true): void {
    let tm = this.getTabManager(tabviewId);
    if (!tm) {
      tm = this.getTabManagerDefault();
    }
    if (!tm) {
      throw new Error('TabService -> openTab: Cannot open tab without tabview.');
    } else {
      tm.addTab(tab, index);
      if (autoSelect) {
        this.selectTab(tab.id);
      }
      this.publishTabAddMessage({
        tabviewId: tm.id,
        tabId: tab.id,
      });
    }
  }

  openTab(tab: ITab<ITabContent>, tabviewId?: string, autoSelect: boolean = true): void {
    return this.openTabAt(tab, null, tabviewId, autoSelect);
  }

  /**
   *
   * @param tabId
   * @returns {TabManager}
   */
  findTabManagerForTabId(tabId: string): TabManager {
    return Object.values(this.tabManagers).find((tm: TabManager) => {
      return tm.getTabById(tabId) !== null;
    });
  }

  /**
   * Find all open tabs for a given app. Tabs are returned in no particular order
   *
   * @param app
   */
  findOpenTabsForApp(app: IApplication): ITab<ITabContent>[] {
    return _.flatten(
      Object.values(this.tabManagers).map((tabManger: TabManager) =>
        tabManger.tabs.filter((tab: ITab<ITabContent>) => tab.id.startsWith(app.name))
      )
    );
  }

  /**
   * Find all open tabs for a given dashboard. Tabs are returned in no particular order
   */
  findOpenTabsForDashboard(dashboardName: string): ITab<ITabContentTemplate>[] {
    return _.flatten(
      Object.values(this.tabManagers).map((tabManger: TabManager) =>
        tabManger.tabs.filter(
          (tab: ITab<ITabContentTemplate>) =>
            tab.content.containsDashboard && tab.content.dashboardName === dashboardName
        )
      )
    );
  }

  /**
   * Close a tab.
   * @param tabId
   */
  closeTab(tabId: string): void {
    const tm = this.findTabManagerForTabId(tabId);
    const tab = tm.getTabById(tabId);
    if (tab.content.type !== TabContentType.IFRAME) {
      this.removeTab(tabId);
    }

    this.publishTabCloseMessage({
      tabviewId: tm.id,
      tabId,
    });
  }

  /**
   * Remove tab.
   */
  removeTab(tabId: string): void {
    const tabManager = this.findTabManagerForTabId(tabId);
    if (tabManager) {
      const tab = tabManager.getTabById(tabId);
      tabManager.removeTabById(tabId);

      const tabHistory = this.getTabHistory(tabManager.id);
      tabHistory.remove(tabId);

      if (tab.active && tabManager.tabs && tabManager.tabs.length !== 0) {
        const lastTab = tabHistory.getLast();

        if (!lastTab) {
          tabManager.selectDefault();
        } else {
          tabManager.selectTabById(tabHistory.getLast());
        }

        this.publishTabSelectMessage({
          tabviewId: tabManager.id,
          tabId: tabManager.selectedTab.id,
        });
      }
      this.publishTabRemoveMessage({
        tabviewId: tabManager.id,
        tabId,
      });

      if (tabManager.tabs.length < 1) {
        this.unregisterTabview(tabManager.id);
      }
    }
  }

  /**
   * Replaces the tab with the given tabId with the new given tab content.
   * @param {string} tabId
   * @param {ITab<ITabContent>} tab
   */
  replaceTab(tabId: string, tab: ITab<ITabContent>): void {
    const tm = this.findTabManagerForTabId(tabId);
    if (!tm) {
      throw new Error('TabService -> replaceTab: Cannot replace a tab without a tabview.');
    }
    tm.replaceTab(tabId, tab);
    this.selectTab(tab.id);
  }

  /**
   * Select a tab by given tabId
   * @param tabId
   */
  selectTab(tabId: string): void {
    const tabManager = this.findTabManagerForTabId(tabId);
    if (!tabManager) {
      return;
    }
    const tabDetails = { ...tabManager.getTabById(tabId) }; // get tab details before they are modified
    tabManager.selectTabById(tabId);
    this.tabManagerDefault = tabManager.id;
    this.publishTabSelectMessage({
      tabviewId: tabManager.id,
      tabId,
    });

    const tabHistory = this.getTabHistory(tabManager.id);
    tabHistory.add(tabDetails.id);

    if (tabDetails.content.type === TabContentType.IFRAME && tabDetails.neverSelected) {
      let tabContent: ITabContentIFrame = tabDetails.content;
      this.publishFirstSelectedAppTab({ appName: tabContent.app.name });
    }
  }

  /**
   * Move a tab to a new index
   *
   * @param tabId
   */
  moveTabToBack(tabId: string): void {
    const tabManager = this.findTabManagerForTabId(tabId);
    if (tabManager) {
      tabManager.moveTabToBack(tabId);
      this.publishTabMoveMessage({
        tabviewId: tabManager.id,
        tabId,
      });
    }
  }

  refreshTab(tabId: string): void {
    const tm = this.findTabManagerForTabId(tabId);
    // publish before tab is removed, so we can react
    this.publishTabRefreshMessage({
      tabviewId: tm.id,
      tabId,
    });
  }

  /**
   * Move a tab to a new index
   */
  moveTabToIndex(tabId: string, newIndex?: number, tabViewId?: string, autoSelect: boolean = true): void {
    let newManager: TabManager;
    if (tabViewId) {
      const previousManager = this.findTabManagerForTabId(tabId);
      const tab = previousManager.getTabById(tabId);
      newManager = this.getTabManager(tabViewId);
      if (previousManager.id === newManager.id) {
        return;
      }
      this.removeTab(tabId);
      if (typeof newIndex === 'undefined') {
        newIndex = newManager.tabs.length;
      }
      this.openTabAt(tab, newIndex, tabViewId, autoSelect);
    } else {
      newManager = this.findTabManagerForTabId(tabId);
      if (typeof newIndex === 'undefined') {
        newIndex = newManager.tabs.length;
      }
      newManager.moveTab(tabId, newIndex);
    }
    this.publishTabMoveMessage({
      tabviewId: newManager.id,
      tabId,
    });
  }

  /**
   * Move all tabs to a specific tabview
   * @param tabviewId
   */
  moveAllTabsTo(tabviewId: string): void {
    Object.keys(this.tabManagers)
      .filter((key: string): boolean => key !== tabviewId)
      .forEach((id: string) => this.moveAllTabsFromTo(id, tabviewId));
  }

  /**
   * Move all tabs from one tabview to a specific tabview
   * @param tabviewId
   * @param targetTabviewId
   */
  moveAllTabsFromTo(tabviewId: string, targetTabviewId: string): void {
    const tm = this.getTabManager(tabviewId);
    const tabs = _.clone(tm.tabs);

    const sourceTabHistory = this.getTabHistory(tabviewId);
    const targetTabHistory = this.getTabHistory(targetTabviewId);
    targetTabHistory.copyHistory(sourceTabHistory.getSavedTabs());

    // [MWP-7513] Updated call to only select the active tab when moving all the tabs(e.g from a split screen to normal screen)
    // tabs.forEach((tab: ITab<ITabContent>) => this.moveTabToIndex(tab.id, undefined, targetTabviewId, false));
    tabs.forEach((tab: ITab<ITabContent>) => this.moveTabToIndex(tab.id, undefined, targetTabviewId, tab.active));
  }

  /**
   * Split a tabview
   */
  splitTabview(config: ISplitTabOptions): void {
    const newTabViewId = _.uniqueId(config.tabviewId + '-split-' + config.direction + '-');
    this.createTabmanager(newTabViewId);
    this.moveTabToIndex(config.tabId, config.index, newTabViewId, !!!config.skipSelection);
    this._actionLogService.logAction({
      category: ActionConstants.CATEGORY_GENERAL,
      action: ActionConstants.ACTION_SPLIT_SCREEN,
    });
    this.publishTabviewSplitMessage({
      tabviewId: config.tabviewId,
      tabId: config.tabId,
      split: {
        newTabViewId,
        direction: config.direction,
        index: config.index,
      },
    });
  }

  /**
   *
   * @param tabId
   * @returns {ITab} can return undefined
   */
  getTabById(tabId: string): ITab<ITabContent> {
    const tm = this.findTabManagerForTabId(tabId);
    if (tm) {
      return tm.getTabById(tabId);
    }
  }

  /**
   * Returns the number of all tabs
   */
  getTabCount(): number {
    return Object.values(this.tabManagers).reduce(
      (lastValue: number, tabManager: TabManager) => lastValue + tabManager.tabs.length,
      0
    );
  }

  getTabManagers(): { [index: string]: TabManager } {
    return this.tabManagers;
  }

  /**
   * Shows the app support overlay for the tab with specified id
   * @param tabId
   * @param supportConfig
   * @param timeout
   */
  showAppSupportOverlay(tabId: string, supportConfig: ISupportConfig, timeout: number = 0): void {
    this.channelAppSupport.publish(TabService.TOPIC_APP_SUPPORT, { tabId, supportConfig, timeout });
  }

  /**
   * True if there are multiple tabmanagers active and the selected tab for every tab manager is a dashboard.
   * @return {boolean}
   */
  get onlyDashboardsActive(): boolean {
    const keys: string[] = Object.keys(this.tabManagers);
    if (!keys || keys.length < 2) {
      return this.getTabManagerDefault().selectedTab.content.type === TabContentType.TEMPLATE;
    }

    let selectedTab: ITab<ITabContent>;
    for (let key of keys) {
      selectedTab = this.tabManagers[key].selectedTab;
      if (
        selectedTab &&
        selectedTab.content &&
        (selectedTab.content.type !== TabContentType.TEMPLATE ||
          !(<ITabContentTemplate>selectedTab.content).containsDashboard)
      ) {
        return false;
      }
    }

    return true;
  }

  getDashboardTabIds(): string[] {
    const keys: string[] = Object.keys(this.tabManagers);
    const dashboardTabs: string[] = [];
    if (!keys) {
      return dashboardTabs;
    }

    for (let key of keys) {
      const selectedTab = this.tabManagers[key].selectedTab?.content;
      if ((<ITabContentTemplate>selectedTab)?.containsDashboard) {
        dashboardTabs.push((<ITabContentTemplate>selectedTab).dashboardName);
      }
    }

    return dashboardTabs;
  }

  /**
   * Publish message that a tab has been added
   * @param message
   */
  publishTabAddMessage(message: ITabServiceChannelTabMessage): void {
    this.channel.publish(TabService.TOPIC_TAB_ADD, message);
  }

  /**
   * Publish message that a tab has been removed
   * @param message
   */
  publishTabRemoveMessage(message: ITabServiceChannelTabMessage): void {
    this.channel.publish(TabService.TOPIC_TAB_REMOVE, message);
  }

  /**
   * Publish message that a tab has been removed
   * @param message
   */
  publishTabMoveMessage(message: ITabServiceChannelTabMessage): void {
    this.channel.publish(TabService.TOPIC_TAB_MOVE, message);
  }

  /**
   * Publish message that a tab has been selected
   * @param message
   */
  publishTabSelectMessage(message: ITabServiceChannelTabMessage): void {
    this.channel.publish(TabService.TOPIC_TAB_SELECT, message);
  }

  /**
   * Publish message that a tab has been removed. This does not mean that the tab is closed.
   * But it might have been moved from one tabview to another.
   * A tab is first closed, then removed.
   *
   * @param message
   */
  publishTabviewSplitMessage(message: ITabServiceChannelTabMessage): void {
    this.channel.publish(TabService.TOPIC_TABVIEW_SPLIT, message);
  }

  publishFirstSelectedAppTab(message: ITabServiceTabSelectionMessage): void {
    this.channelTabSelection.publish(TabService.TOPIC_FIRST_TAB_SELECTION_WITH_APP, message);
  }

  /**
   * Publish that tabview has been unregistered
   * @param message
   */
  publishTabviewUnregisterMessage(message: ITabServiceChannelTabMessage): void {
    this.channel.publish(TabService.TOPIC_TABVIEW_UNREGISTER, message);
  }

  /**
   * Publish that a tab has been closed. A tab is first closed, then removed.
   * @param message
   */
  publishTabCloseMessage(message: ITabServiceChannelTabMessage): void {
    this.channel.publish(TabService.TOPIC_TAB_CLOSE, message);
  }

  /**
   * Publish that a tab has been refreshed.
   * @param message
   */
  publishTabRefreshMessage(message: ITabServiceChannelTabMessage): void {
    this.channel.publish(TabService.TOPIC_TAB_REFRESH, message);
  }

  /**
   * Destroy the tab service
   */
  destroy(): void {
    this.channel = null;
  }

  /**
   * Save settings.
   */
  saveSettings(): void {
    this._userSettingsStorageService.saveSettings(this, this.getTabManagers());
    this.tabsHistory.forEach(tabHistory => tabHistory.saveToStorage());
  }

  /**
   * Get settings.
   *
   * There is no loadSettings method, as settings are restored externally by the SessionData service.
   */
  getSettings(): { [index: string]: TabManager } {
    this.tabsHistory.forEach(th => th.loadFromStorage());
    return this._userSettingsStorageService.loadSettings(this);
  }

  private getTabHistory(tabManagerId: string): TabHistoryStorage {
    return this.tabsHistory.find(th => th.tabManagerId === tabManagerId);
  }
}

/**
 * Message for the tab service tab channel
 */
export interface ITabServiceTabSelectionMessage {
  appName: string;
}

/**
 * Message for the tab service tab channel
 */
export interface ITabServiceChannelTabMessage {
  // the id of the tab view
  tabviewId: string;
  // tab that has been changed
  tabId: string;
  // only set for tabview split messages
  split?: {
    newTabViewId: string;
    // direction of the split (row or column)
    direction: string;
    // index of the new tabview area (0 or 1)
    index: number;
  };
}

/**
 * Message for showing app support
 */
export interface ITabServiceAppSupportMessage {
  // the tab to show the support message
  tabId: string;
  // support config information
  supportConfig: ISupportConfig;
  // after a number of seconds the support message will be hidden
  timeout: number;
}

export interface ISplitTabOptions {
  /** the id of the source tabview */
  tabviewId: string;
  /** id of the tab being transfered */
  tabId: string;
  /** direction of the movement */
  direction: string;
  /** insert position in the new tabmanager */
  index: number;
  /** should the tab not automatically be selected */
  skipSelection?: boolean;
}
