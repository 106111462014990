import { Tabview } from './tabview';
import { Component } from '../component';
import tabviewTemplate from './tabview.html';

/**
 * Wraps {@link Tabview} component into a directive.
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 *
 * @ngInject
 */
export function tabviewDirective(): ng.IDirective {
  'use strict';
  return Component.createDirective(Tabview, {
    restrict: 'E',
    scope: {
      id: '=tabviewId',
      maxTabs: '=',
      leftTitlebarVisible: '=',
      rightTitlebarVisible: '=',
    },
    transclude: true,
    templateUrl: tabviewTemplate,
  });
}
