import angular from 'angular';
import 'angular-translate';
import 'angular-ui-bootstrap';
import 'angular-cookies';
import 'angular-resource';
import 'angular-sanitize';
import 'angular-ui-router';
import 'ui-router-extras';

import 'angular-animate';
import 'ng-device-detector';
import 'angular-touch';
import 'ng-debounce/dist/ng-debounce';
import 'js-data';
import 'js-data-angular';
import 'js-data-localstorage';
import $ from 'jquery';
import postal from 'postal';
import toastr from 'toastr';
import './polyfills';
import './vendor';
import 'angular-feature-flags';

import { RouterConfig } from './app.route';
import { Config } from './app.config';
import { RunBlock } from './app.run';
import { LayoutController } from './layout/layout.controller';
import { sidebarDirective } from './components/sidebar/sidebar.directive';
import { appsmenuDirective } from './components/appsmenu/appsmenu.directive';
import { appsmenuItemDirective } from './components/appsmenu/appsmenu.item.directive';
import { tabviewDirective } from './components/tabview/tabview.directive';
import { tabviewContentDirective } from './components/tabview/tabview.content.directive';
import { appLayoutResponsiveSpyDirective } from './layout/layout.responsive.spy.directive';
import { appTabLayoutDirective } from './tab/tab.layout.directive';
import { dashboardDirective } from './dashboard/dashboard.directive';
import { userinfoDirective } from './components/userinfo/userinfo.directive';
import { optiscrollDirective } from '../util/optiscroll.directive';
import { usertasksDirective } from './components/task/tasks.directive';
import { appsFrameComponentDirective } from './apps/apps.frame.component.directive';
import { sortableDirective } from '../util/interact/sortable.directive';
import { sortableDraggableDirective } from '../util/interact/sortable.draggable.directive';
import { dropzoneDirective } from '../util/interact/dropzone.directive';
import { loaderDirective } from './components/loader/loader.directive';
import { TabService } from './tab/tab.service';
import { ErrorService } from './errors/error.service';
import { TabManager } from './tab/tab.manager';
import { appsStoreFactory } from './apps/apps.store.factory';
import { userStoreFactory } from './user/user.store.factory';
import { settingStoreFactory } from './setting/setting.store.factory';
import { localStoreFactory } from './stores/local.store.factory';
import { tasksStoreFactory } from './task/tasks.store.factory';
import { widgetDefinitionStoreFactory } from './widget/widget.definition.store.factory';
import { DeviceService } from '../util/device.service';
import { AppsService } from './apps/apps.service';
import { DashboardService } from './dashboard/dashboard.service';
import { DashboardActionsService } from './dashboard/dashboard-actions/dashboard-actions.service';
import { UserService } from './user/user.service';
import { SettingService } from './setting/setting.service';
import { TasksPolling } from './task/tasks.provider';
import { WidgetService } from './widget/widget.service';
import { WorkplaceContextService } from './workplace/workplace.context.service';
import { WorkplaceApiService } from './workplace/workplace.api.service';
import { GraphQLService } from './workplace/graphql.service';
import { roleContextDirective } from './components/roleContext/roleContext.directive';
import { NotificationProvider } from './notification/notification.provider';
import { tileWidgetDirective } from './widget/tile/tile.directive';
import { popoverDirective } from '../util/bootstrap/popover.directive';
import { holdDirective } from '../util/interact/hold.directive';
import { draggableDirective } from '../util/interact/draggable.directive';
import { throttleclickDirective } from '../util/throttleclick.directive';
import { PopupService } from './notification/popup.service';
import { popupCenterDirective } from './notification/popup.center.directive';
import { httpInterceptorFactory } from './app.http.interceptor.factory';
import { dashboardStoreFactory } from './dashboard/dashboard.store.factory';
import { layoutSplitDirective } from './layout/layout.split.directive';
import { menuItemDirective } from './components/menu/menu.item.directive';
import { titlebarSplitDirective } from './components/titlebar/titlebar.split.directive';
import { navbarmenuDirective } from './components/navbarmenu/navbarmenu.directive';
import { menuOverlayDirective } from './menu/menu.overlay.directive';
import { utilTemplateDirective } from '../util/template.directive';
import { MenuService } from './menu/menu.service';
import { ViewToggleService } from './viewToggle/view.toggle.service';
import { taskInfoItemDirective } from './components/task/task.info.item.directive';
import { userInfoItemDirective } from './components/userinfo/user.info.item.directive';
import { addFavoriteDirective } from './components/dialogs/add-favorite.directive';
import { isuri } from './form/url.validator';
import { confirmationDialog } from './components/dialogs/confirm.directive';
import { actionItemDirective } from './components/userinfo/action.item.directive';
import { dialogHeaderDirective } from './components/dialogs/dialog-header.directive';
import { EmployeeService } from './employee/employee.service';
import { employeeStoreFactory } from './employee/employee.store.factory';
import { fileInputChangeDirective } from './components/fileHandling/file-input-change.directive';
import { cropperDirective } from './components/cropper/cropper.directive';
import { ImageService } from './fileHandling/image.service';
import { isNoLocalUriWindows } from './form/local-url.validator';
import { ActionLogService } from './actionLog/action-log.service';
import { dropdownMenuDirective } from './components/dropdownMenu/dropdown-menu.directive';
import { dropdownMenuItemDirective } from './components/dropdownMenu/dropdown-menu-item.directive';
import { logoutConfirmationDialogDirective } from './components/dialogs/logout-confirmation-dialog.directive';
import { SessionDataService } from './sessionData/session-data.service';
import { addDashboardDirective } from './components/dialogs/add-dashboard-dialog.directive';
import { shareDialogDirective } from './components/dialogs/share-dialog.directive';
import { chipDirective } from './components/chip/chip.directive';
import { searchFieldDirective } from './components/searchfield/search-field.directive';
import { groupStoreFactory } from './groups/group.store.factory';
import { GroupService } from './groups/group.service';
import { tableDirective } from './components/table/table.directive';
import { SharingService } from './sharing/sharing.service';
import { acceptFavoriteDirective } from './components/dialogs/accept-favorite-dialog.directive';
import { paginationDirective } from './components/pagination/pagination.directive';
import { plainTextFilter } from '../util/plain-text.filter.factory';
import { cordovaDialogDirective } from './components/dialogs/cordova-dialog.directive';
import { WorkplaceNativeDeviceService } from './workplace/workplace-native-device.service';
import { appBehaviorFactory } from './appBehavior/app-behavior.factory';
import { InterframeDragService } from './appBehavior/interframe-drag-manager.service';
import { userSettingStoreFactory } from './userSetting/user-setting.store.factory';
import { UserSettingService } from './userSetting/user-setting.service';
import { widgetSizeGridDirective } from './components/sizeGrid/size-grid.directive';
import { dashboardMenuItemDirective } from './components/appsmenu/dashboardmenu.item.directive';
import { inlineEditDirective } from './components/inlineEdit/inline-edit.directive';
import { frameLayoutMenuItemDirective } from './frameLayout/frame-layout-menu-item.directive';
import { FrameLayoutService } from './frameLayout/frame-layout.service';
import { frameLayoutStoreFactory } from './frameLayout/frame-layout.store.factory';
import { frameLayoutDirective } from './frameLayout/frame-layout.directive';
import { RestrictionsService } from './restrictions/restrictions.service';
import { lockedAppDirective } from './apps/app-blocked.directive';
import { sessionExpiredDialogDirective } from './components/dialogs/session-expired-dialog.directive';
import { localizedNumberFilter } from '../util/localized-number.filter';
import { localizedDateFilter } from '../util/localized-date.filter';
import { appDialogDirective } from './components/dialogs/app-dialog.directive';
import { LanguageProvider } from '../util/language.provider';
import { bootstrapApp } from './app.bootstrap';
import { shareDashboardDialogDirective } from './components/dialogs/share-dashboard-dialog.directive';
import { restrictedTextDirective } from './components/restrictedText/restrictedText.directive';
import { LayoutProvider } from './layout/layout.provider';
import { localStorageFactory } from './sessionData/local-storage-wrapper.factory';
import { susWidgetDirective } from './widget/sus/sus.directive';
import { downloadDialogDirective } from './components/dialogs/download-dialog.directive';
import { fileUploadDirective } from '../util/file-upload/file-upload.directive';
import { catalogStoreFactory } from './catalog/catalog.store.factory';
import { CatalogService } from './catalog/catalog.service';
import { IframeDragToWorkplaceService } from './appBehavior/iframe-drag-to-workplace.service';
import { autoFocusDirective } from './components/searchfield/auto-focus.directive';
import { addTaskMobileDirective } from './components/dialogs/add-task-mobile.directive';
import { MenuOverlayService } from './menu/menu.overlay.service';
import { aboutDialogDirective } from './components/dialogs/about-dialog.directive';
import { AuthService } from './auth/AuthService';
import { authFlowProgressDialogDirective } from './components/dialogs/auth-flow-progress-dialog.directive';
import { taskActivityFactory } from './task-activity/task-activity.factory';
import { ActivitiesPolling } from './task-activity/task-activity.provider';
import { clickOutsideDirective } from './components/clickOutside/click-outside.directive';
import { typeaheadDropdownDirective } from './components/typeaheadDropdown/typeahead-dropdown.directive';
import { OfficeUriService } from './officeUri/office-uri.service';
import { LocalUserSettingsStorageService } from './workplace/local-user-settings-storage.service';
import { saveFavoriteDirective } from './components/dialogs/save-favorite-dialog.directive';
import { appSupportOverlayDirective } from './support/support-overlay.directive';
import { authConfig } from './app.auth';

import 'tether/dist/css/tether.min.css';
import 'optiscroll/dist/optiscroll.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/iwp.css';
import '../styles/theme.scss';
import { WebComponentSettingsService } from './web-components/web-component-settings.service';
import { AuthTokenService } from './auth/auth-token.service';
import { AnnouncementService } from './announcements/announcements.service';
import { IdCardService } from './web-components/id-card/id-card.service';
import { MwpStoreService } from './web-components/mwp-store/mwp-store.service';
import { shareStoreItemDialogDirective } from './components/dialogs/share-store-item-dialog/share-store-item-dialog.directive';

import { MobileService } from './mobile/mobile.service';
import { BannersActionsService } from './apps/apps-actions/banners-actions.service';
import { JiraAuthService } from './jira/jira-auth.service';
import { WidgetSettingsService } from './widget/settings/core/widget-settings/widget-settings.service';
import { MicrosoftService } from './widget/services/microsoft.service';
import { AppAdminUploadService } from './appAdminUpload/appAdminUpload.service';
import { TrackingService } from './feature-tracking/tracking.service';
import { OnboardingFlowService } from './web-components/onboarding-flow/onboarding-flow.service';
import { EventListenerManager } from './web-components/event-listener-manager';
import { NavigationComponentsService } from './web-components/navigation/navigation.service';
import { WebComponentSharedHandlersService } from './web-components/web-component-shared-handlers.service';

window['Locale'] = 'en-US';

/**
 * Application module. Add controllers, services, directives to the module here.
 * Code will be executed on script load.
 * For coding conventions please refer to the Angular Styleguide:
 * https://github.com/johnpapa/angular-styleguide
 *
 * For configuring routes please see {@link RouterConfig}.
 * For other configuration please see {@link Config}.
 * For code that needs to be run on startup please see {@link RunBlock}.
 *
 * @module app
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export module app {
  'use strict';
  angular
    .module('app-dependencies', [
      'ngAnimate',
      'ngSanitize',
      'ui.router',
      'ct.ui.router.extras',
      'pascalprecht.translate',
      'ui.bootstrap',
      'ng.deviceDetector',
      'ngCookies',
      'debounce',
      'ngTouch',
      'js-data',
      'feature-flags',
    ])
    .constant('postal', postal)
    .constant('toastr', toastr)
    .constant('jQuery', $)
    .constant('authTokenService', AuthTokenService.getInstance())
    .provider('notificationService', NotificationProvider)
    .provider('tasksService', TasksPolling)
    .provider('taskActivityService', ActivitiesPolling)
    .provider('language', LanguageProvider)
    .provider('layoutService', LayoutProvider)
    .service('menuService', MenuService)
    .service('tabService', TabService)
    .service('errorService', ErrorService)
    .service('deviceService', DeviceService)
    .service('dashboardActionsService', DashboardActionsService)
    .service('mobileService', MobileService)
    .service('appsService', AppsService)
    .service('dashboardService', DashboardService)
    .service('userService', UserService)
    .service('settingService', SettingService)
    .service('userSettingService', UserSettingService)
    .service('widgetService', WidgetService)
    .service('workplaceContextService', WorkplaceContextService)
    .service('workplaceApiService', WorkplaceApiService)
    .service('graphqlService', GraphQLService)
    .service('popupService', PopupService)
    .service('employeeService', EmployeeService)
    .service('catalogService', CatalogService)
    .service('groupService', GroupService)
    .service('imageService', ImageService)
    .service('sessionDataService', SessionDataService)
    .service('actionLogService', ActionLogService)
    .service('sharingService', SharingService)
    .service('workplaceNativeDeviceService', WorkplaceNativeDeviceService)
    .service('interframeDragService', InterframeDragService)
    .service('iframeDragToWorkplaceService', IframeDragToWorkplaceService)
    .service('frameLayoutService', FrameLayoutService)
    .service('restrictionsService', RestrictionsService)
    .service('userSettingsStorageService', LocalUserSettingsStorageService)
    .service('webComponentSettingsService', WebComponentSettingsService)
    .service('microsoftService', MicrosoftService)
    .service('viewToggleService', ViewToggleService)
    .factory('appsStore', appsStoreFactory)
    .factory('catalogStore', catalogStoreFactory)
    .factory('taskActivity', taskActivityFactory)
    .factory('userStore', userStoreFactory)
    .factory('settingsStore', settingStoreFactory)
    .factory('userSettingsStore', userSettingStoreFactory)
    .factory('tasksStore', tasksStoreFactory)
    .factory('employeeStore', employeeStoreFactory)
    .factory('groupStore', groupStoreFactory)
    .factory('$exceptionHandler', ErrorService.exceptionHandlerFactory)
    .factory('tabManagerFactory', TabManager.factory)
    .factory('localStore', localStoreFactory)
    .factory('widgetDefinitionStore', widgetDefinitionStoreFactory)
    .factory('dashboardStore', dashboardStoreFactory)
    .factory('httpInterceptor', httpInterceptorFactory)
    .factory('appBehavior', appBehaviorFactory)
    .factory('frameLayoutsStore', frameLayoutStoreFactory)
    .factory('localStorageFactory', localStorageFactory)
    .filter('plainText', plainTextFilter)
    .filter('localizedNumber', localizedNumberFilter)
    .filter('localizedDate', localizedDateFilter)
    .controller('LayoutController', LayoutController)
    .directive('appComponentsSidebar', sidebarDirective)
    .directive('appComponentsTitlebarSplit', titlebarSplitDirective)
    .directive('appComponentsAppsMenu', appsmenuDirective)
    .directive('appComponentsAppsMenuItem', appsmenuItemDirective)
    .directive('appComponentsTabview', tabviewDirective)
    .directive('appComponentsTabviewContent', tabviewContentDirective)
    .directive('appLayoutResponsiveSpy', appLayoutResponsiveSpyDirective)
    .directive('appTabLayout', appTabLayoutDirective)
    .directive('appDashboard', dashboardDirective)
    .directive('appComponentsUserinfo', userinfoDirective)
    .directive('usertasksDirective', usertasksDirective)
    .directive('utilOptiscroll', optiscrollDirective)
    .directive('appAppsFrameComponent', appsFrameComponentDirective)
    .directive('utilInteractSortable', sortableDirective)
    .directive('utilInteractSortableDraggable', sortableDraggableDirective)
    .directive('utilInteractDropzone', dropzoneDirective)
    .directive('appComponentsLoader', loaderDirective)
    .directive('appComponentsRoleContext', roleContextDirective)
    .directive('appWidgetTile', tileWidgetDirective)
    .directive('utilBootstrapPopover', popoverDirective)
    .directive('utilInteractHold', holdDirective)
    .directive('utilInteractDraggable', draggableDirective)
    .directive('utilThrottleClick', throttleclickDirective)
    .directive('utilTemplate', utilTemplateDirective)
    .directive('popupCenterDirective', popupCenterDirective)
    .directive('appLayoutSplit', layoutSplitDirective)
    .directive('appComponentsMenuItem', menuItemDirective)
    .directive('appComponentsNavbarMenu', navbarmenuDirective)
    .directive('appMenuMenuOverlay', menuOverlayDirective)
    .directive('appComponentsTaskTaskInfoItem', taskInfoItemDirective)
    .directive('appComponentsUserinfoUserInfoItem', userInfoItemDirective)
    .directive('appModalsAddFavorite', addFavoriteDirective)
    .directive('appModalsCreateTask', addTaskMobileDirective)
    .directive('confirmationDialog', confirmationDialog)
    .directive('isuri', isuri)
    .directive('isnolocaluriwindows', isNoLocalUriWindows)
    .directive('appComponentsActionItem', actionItemDirective)
    .directive('appComponentDialogHeader', dialogHeaderDirective)
    .directive('fileInputChange', fileInputChangeDirective)
    .directive('appComponentImageCropper', cropperDirective)
    .directive('appWidgetSus', susWidgetDirective)
    .directive('appComponentsDropdownMenu', dropdownMenuDirective)
    .directive('appComponentsDropdownMenuItem', dropdownMenuItemDirective)
    .directive('logoutConfirmationDialog', logoutConfirmationDialogDirective)
    .directive('sessionExpiredDialog', sessionExpiredDialogDirective)
    .directive('addDashboardDialog', addDashboardDirective)
    .directive('shareDialog', shareDialogDirective)
    .directive('shareDashboardDialog', shareDashboardDialogDirective)
    .directive('shareStoreItemDialog', shareStoreItemDialogDirective)
    .directive('appChip', chipDirective)
    .directive('appSearchField', searchFieldDirective)
    .directive('appTypeaheadDropdown', typeaheadDropdownDirective)
    .directive('autoFocus', autoFocusDirective)
    .directive('appTable', tableDirective)
    .directive('acceptFavoriteDialog', acceptFavoriteDirective)
    .directive('saveFavoriteDialog', saveFavoriteDirective)
    .directive('appPaginationComponent', paginationDirective)
    .directive('appRestrictedTextComponent', restrictedTextDirective)
    .directive('cordovaDialog', cordovaDialogDirective)
    .directive('appWidgetSizeComponent', widgetSizeGridDirective)
    .directive('appComponentsDashboardMenuItem', dashboardMenuItemDirective)
    .directive('inlineEdit', inlineEditDirective)
    .directive('frameLayoutMenuItem', frameLayoutMenuItemDirective)
    .directive('frameLayout', frameLayoutDirective)
    .directive('appBlocked', lockedAppDirective)
    .directive('appDialog', appDialogDirective)
    .directive('downloadDialog', downloadDialogDirective)
    .directive('uploadFile', fileUploadDirective)
    .directive('appComponentsAboutDialog', aboutDialogDirective)
    .directive('authFlowProgressDialog', authFlowProgressDialogDirective)
    .directive('clickOutside', clickOutsideDirective)
    .directive('appSupportOverlay', appSupportOverlayDirective)
    .service('menuOverlayService', MenuOverlayService)
    .service('authService', AuthService)
    .service('officeUriService', OfficeUriService)
    .service('announcementsService', AnnouncementService)
    .service('idCardService', IdCardService)
    .service('bannersActionsService', BannersActionsService)
    .service('mwpStoreService', MwpStoreService)
    .service('jiraAuthService', JiraAuthService)
    .service('widgetSettingsService', WidgetSettingsService)
    .service('appAdminUploadService', AppAdminUploadService)
    .service('trackingService', TrackingService)
    .service('onboardingFlowService', OnboardingFlowService)
    .service('navigationComponentsService', NavigationComponentsService)
    .service('webComponentSharedHandlersService', WebComponentSharedHandlersService)
    .constant('eventListenerManager', EventListenerManager.getInstance());

  angular.module('app', ['app-dependencies']).config(RouterConfig).config(Config).run(RunBlock);

  authConfig().then(() => angular.element(document).ready(bootstrapApp));
}
