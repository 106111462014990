import { IWorkplaceContext } from './workplace.context.interface';
import { IUserServiceAuthUpdatedMessage, UserService } from '../user/user.service';
import { DeviceService } from '../../util/device.service';
import { UrlHelper } from '../../util/url.helper';
import { IWorkplaceProperty } from './workplace-property.interface';
import { User } from '../user/user.model';
import { Role } from '../user/role.model';
import { IDashboard } from '../dashboard/dashboard.model.interface';
import { IDiffValue, IRole } from '../user/user.model.interface';
import { PopupService } from '../notification/popup.service';
import { ILanguage } from '../../util/language.model.interface';
import { IHttpPromiseCallbackArg } from 'angular';
import _ from 'lodash';

/**
 * Service provides the current workplace context. The context depends on the currently selected roles,
 * therefore a new context object is created every time.
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class WorkplaceContextService {
  static CHANNEL: string = 'WORKPLACE_CONTEXT_SERVICE_CHANNEL';
  static TOPIC_SESSION_EXPIRED: string = 'WORKPLACE_CONTEXT_SERVICE_TOPIC_SESSION_EXPIRED';
  private static CONVERSATION_ID_LENGTH: number = 20;

  channel: IChannelDefinition<WorkplaceContextService>;
  authLevelUpdated: IChannelDefinition<IUserServiceAuthUpdatedMessage>;
  languageChanged: IChannelDefinition<string>;
  static UPDATED_AUTH_LEVEL: string = 'UPDATED_AUTH_LEVEL';
  static UPDATED_LANGUAGE: string = 'UPDATED_LANGUAGE';

  private _demoMode: string;
  private _sessionExpired: boolean = false;
  private _confirmSessionExpiredActive: boolean = false;

  private _userService: UserService;
  private _deviceService: DeviceService;
  private _httpService: ng.IHttpService;
  private _qService: ng.IQService;
  private _popupService: PopupService;
  private _language: ILanguage;
  private _workplacePropsPromise: ng.IPromise<IWorkplaceProperty[]>;
  private _workplaceFeatureFlagsDefer: ng.IDeferred<any>;

  /**
   * @ngInject
   */
  constructor(
    userService: UserService,
    deviceService: DeviceService,
    $location: ng.ILocationService,
    $http: ng.IHttpService,
    $q: ng.IQService,
    postal: IPostal,
    popupService: PopupService,
    language: ILanguage
  ) {
    this._userService = userService;
    this._deviceService = deviceService;
    this._httpService = $http;
    this._qService = $q;
    this._popupService = popupService;
    this._language = language;
    const routeParams = $location.search();
    const locationParams = UrlHelper.fromQueryString(window.location.search);
    this._demoMode = routeParams.demoMode ? routeParams.demoMode : <string>locationParams['demoMode'];
    this.channel = postal.channel(WorkplaceContextService.CHANNEL);
    this.authLevelUpdated = postal.channel(WorkplaceContextService.UPDATED_AUTH_LEVEL);
    this.languageChanged = postal.channel(WorkplaceContextService.UPDATED_LANGUAGE);
    this._userService.setWorkplaceContextService(this);
    this.channel.subscribe(WorkplaceContextService.TOPIC_SESSION_EXPIRED, this.handleSessionExpired.bind(this));
    this._workplacePropsPromise = this._httpService
      .get<IWorkplaceProperty[]>('./rest/workplace/properties')
      .then((response: IHttpPromiseCallbackArg<IWorkplaceProperty[]>) => response.data)
      .catch(() => {
        console.warn('Could not fetch workplace properties!');
        return [];
      });
    this._workplaceFeatureFlagsDefer = this._qService.defer();
  }

  /**
   * Returns whether the workplace is demo mode
   * @returns {string}
   */
  get demoMode(): string {
    return this._demoMode;
  }

  /**
   *
   * @returns {boolean}
   */
  get sessionExpired(): boolean {
    return this._sessionExpired;
  }

  /**
   * Returns a property by the given name
   * @param name
   */
  getProperty(name: string): ng.IPromise<IWorkplaceProperty> {
    return this._workplacePropsPromise.then((data: IWorkplaceProperty[]) =>
      data.find((property: IWorkplaceProperty) => property.name === name)
    );
  }

  /**
   * Sets and resolves feature flags
   * @param flags
   */
  setFeatureFlags(flags: any) {
    this._workplaceFeatureFlagsDefer.resolve(flags);
  }

  /**
   * Returns defined feature flags
   */
  getFeatureFlags(): ng.IPromise<any> {
    return this._workplaceFeatureFlagsDefer.promise;
  }

  /**
   * Returns the current application context
   *
   * @param params only return the properties specified
   */
  getAppContext(...params: string[]): ng.IPromise<IWorkplaceContext> {
    return this._userService.getUser().then((user: User) => {
      const selectedRole = user.getSelectedRole();
      const context: IWorkplaceContext = {
        cacheBuster: '' + new Date().getTime(),
        lang: this._language.lang,
        env: 'workplace',
        strongAuth: user.authLevel,
        locale: this._language.locale,
        deviceType: this._deviceService.device,
        demoMode: this.demoMode,
        mwpOrigin: window.location.origin,
        businessRoles: user.roles.map((r: IRole) => r.roleId),
      };
      if (selectedRole && selectedRole !== null) {
        context.role = selectedRole.roleId;
        if (selectedRole.substitutedUser) {
          context.persondeputized = selectedRole.substitutedUser.userId;
        }
        context.moduleOrg =
          selectedRole.diffValues.length > 50
            ? []
            : _.uniq(
              selectedRole
                .getDiffValues(Role.DIFF_VALUE_MODULEORG)
                .map((mOrg: string) => (mOrg.length < 4 ? mOrg : mOrg.substr(0, 2)))
            );
        context.derivative =
          selectedRole.diffValues.length > 50 ? [] : selectedRole.getDiffValues(Role.DIFF_VALUE_DERIVATIVE);
        context.plOrg = selectedRole.diffValues.length > 50 ? [] : selectedRole.getDiffValues(Role.DIFF_VALUE_PLORG);
      }
      return params.length > 0 ? _.pick(context, params) : context;
    });
  }

  /**
   * get the dashboard context, overwrites the app and the role context
   * (extraParams>dashboardContext>RoleContext)
   * @param dashboard
   * @param extraParams
   * @param params
   */
  getDashboardContext(dashboard: IDashboard, extraParams: any, ...params: string[]): ng.IPromise<IWorkplaceContext> {
    return this.getAppContext(...params).then((appContext: IWorkplaceContext) => {
      const dashboardContext: any = {};
      if (dashboard.diffValues) {
        dashboard.diffValues.forEach((diffValue: IDiffValue) => {
          if (!dashboardContext[diffValue.type]) {
            dashboardContext[diffValue.type] = [];
          }
          dashboardContext[diffValue.type].push(diffValue.value);
        });
      }
      const mergedContext = Object.assign(appContext, dashboardContext, extraParams);
      return params.length > 0 ? _.pick(mergedContext, params) : mergedContext;
    });
  }

  /**
   * Merges dashboard context with role context and app context into a single object.
   * Dashboard context takes precedence over role context and the result of merging the two is then
   * stripped from the keys not contained by the given widgets's dataUrlContextParams
   */
  getMergedContext(
    roleContext: IDiffValue[],
    dashboardContext: IDiffValue[],
    widgetContext: string[]
  ): { [key: string]: IDiffValue[] } {
    const roleContextDiffs: { [key: string]: IDiffValue[] } = this.convertDiffArray(roleContext);
    const dashboardContextDiffs: { [key: string]: IDiffValue[] } = this.convertDiffArray(dashboardContext);
    /**
     * Merge the two sets of context params role / dashboard context.
     * Dashboard context takes precedence.
     */
    const result: { [key: string]: IDiffValue[] } = <{ [key: string]: IDiffValue[] }>(
      _.assign({}, roleContextDiffs, dashboardContextDiffs)
    );
    return <{ [key: string]: IDiffValue[] }>_.pick(result, widgetContext);
  }

  /**
   * Get dashboardContext only without merging any roleContext value
   * Takes into consideration only widgetContext
   */
  getDashboardContextOnly(dashboardContext: IDiffValue[], widgetContext: string[]): { [key: string]: IDiffValue[] } {
    const dashboardContextDiffs: { [key: string]: IDiffValue[] } = this.convertDiffArray(dashboardContext);
    const result: { [key: string]: IDiffValue[] } = <{ [key: string]: IDiffValue[] }>(
      _.assign({}, dashboardContextDiffs)
    );
    return <{ [key: string]: IDiffValue[] }>_.pick(result, widgetContext);
  }

  /**
   * Generates a random alphanumeric string of length 20.
   * Used for conversationId query parameter.
   *
   * @returns {string}
   */
  generateConversationId(): string {
    return _.sampleSize(
      '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz',
      WorkplaceContextService.CONVERSATION_ID_LENGTH
    ).join('');
  }

  /**
   * Handle expired sessions.
   * Use the logout confirmation which will redirect the user.
   */
  handleSessionExpired(): void {
    this._sessionExpired = true;
    if (!this._confirmSessionExpiredActive) {
      this._confirmSessionExpiredActive = true;
      this._popupService
        .showConfirm(
          {
            titleKey: 'error.sessionExpired.title',
            messageKey: 'error.sessionExpired',
            okButtonKey: 'dialogs.common.buttons.reload',
            cancelButtonKey: 'dialogs.common.buttons.cancel',
          },
          'sessionExpiredDialog'
        )
        .finally(() => (this._confirmSessionExpiredActive = false));
    }
  }

  /**
   * Publish authLevel updated
   */
  publishUpdatedAuthLevel(authLevel: number, strongAuthService: number): void {
    this.authLevelUpdated.publish(WorkplaceContextService.UPDATED_AUTH_LEVEL, {
      authLevel,
      strongAuthService: `strongAuth${strongAuthService}Service`,
    });
  }

  private convertDiffArray(arr: IDiffValue[]): { [key: string]: IDiffValue[] } {
    return arr && arr.length > 0 ? _.groupBy(arr, (item: IDiffValue) => item.type) : {};
  }
}
