import { IAnalytics, PlatformAnalyticsFactory } from '@myworkplace/api';

import { ILanguage } from '../../util/language.model.interface';
import { createWebComponentHandlers, removeWebComponentHandlers } from '../../util/web-components/event-handlers';
import { ActionLogService } from '../actionLog/action-log.service';
import { AppsService } from '../apps/apps.service';
import { Component } from '../components/component';
import { FrameLayoutService } from '../frameLayout/frame-layout.service';
import { LayoutService } from '../layout/layout.service';
import { NotificationService } from '../notification/notification.service';
import { ITabServiceChannelTabMessage, TabService } from '../tab/tab.service';
import { DashboardActionsService } from './dashboard-actions/dashboard-actions.service';
import { IDashboard } from './dashboard.model.interface';
import { DashboardService } from './dashboard.service';
import { UrlHelper } from '../../util/url.helper';
import { IApplication } from '../apps/application.model.interface';
import { PopupService } from '../notification/popup.service';

export interface CustomSettings {
  app: string;
  url: string;
  queryParams?: { [key: string]: string | boolean | number | string[] | number[] | boolean[] };
}
/**
 * Dashboard component
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class Dashboard extends Component {
  static DD_GROUP_DASHBOARD: string = 'ddGroupDashboard';
  private static readonly CREATE_ACTION_LOG_FUNC = 'createActionLog';
  /**
   * Set by attribute
   */
  dashboardName: string;
  isMobile: boolean = false;
  openFilesModal = {
    fileTitle: '',
    fileUrl: '',
    isModalOpen: false,
  };
  private _layoutService: LayoutService;
  private _dashboardService: DashboardService;
  private _subscriptions: ISubscriptionDefinition<any>[];
  private _scope: ng.IScope;
  private appsService: AppsService;
  private _notificationService: NotificationService;
  private _popupService: PopupService;
  private dashboardEventHandlers = {
    openWidgetStore: () => this.dashboardActionsService.openWidgetStoreHandler(),
    addApp: () => this.dashboardActionsService.addAppHandler(),
    addWidgetToDashboard: () => this.dashboardActionsService.openWidgetStoreHandler(),
    openDashboardMenu: () => this.dashboardActionsService.openDashboardMenu(),
    copyDashboardLink: () => this.copyDashboardDeepLink(this.dashboardName),
    openDashboard: (event: MouseEvent) =>
      this.dashboardActionsService.openDashboardHandler(event.detail as unknown as string),
    closeCurrentTab: () => this.dashboardActionsService.closeCurrentTab(this.dashboardName),
    clientToastNotification: (customEvent: CustomEvent) => this.dashboardActionsService.handleNotification(customEvent),
    openAuthFlow: event => this.appsService.openAppAuthFlow(event.detail),
    // Quick Actions
    shareDashboard: (event: MouseEvent) =>
      this.dashboardActionsService.openShareDialog(this.dashboardName, event.detail as unknown as string),
    resetClonedDashboard: (event: MouseEvent) => {
      const payload = event.detail as unknown as {
        parentDashboardId: string;
        deletedDashboard: { id: string; title: string };
      };
      this.dashboardActionsService.resetDashboard(
        payload.parentDashboardId,
        payload.deletedDashboard.id,
        payload.deletedDashboard.title
      );
    },
    refreshDashboard: (event: MouseEvent) => {
      const payload = event.detail as unknown as {
        parentDashboard: { name: string };
        clonedDashboard: { name: string; title: string; parentName: string; sharedBy: string };
      };
      this.dashboardActionsService.refreshDashboard(payload.parentDashboard, payload.clonedDashboard);
    },
    shareTile: (event: MouseEvent) => this.shareTile(event),
    openApp: async (event: MouseEvent) => {
      const customSetting = event.detail as unknown as CustomSettings;

      let app: IApplication;
      try {
        app = { ...(await this.appsService.getApp(customSetting.app)) };
      } catch (error) {
        this._notificationService.showError('error.userInput.openApp', { name: customSetting.app });
        return;
      }

      if (customSetting.url) {
        app.url = this.updateAppUrl(app.url, customSetting.url);
      }

      this.appsService.openApp(app, {
        queryParams: customSetting.queryParams ? customSetting.queryParams : null,
      });
    },
    openLayout: (event: MouseEvent) => {
      this.frameLayoutService.openLayoutById(event.detail as unknown as string).then(layout => {
        const layoutMapper = {
          ...layout,
          name: layout.id,
          isAppLayout: true,
        };
        this.appsService.storeRecentlyOpenedApps(layoutMapper);
      });
    },
    updateDashboardTitle: (event: MouseEvent) => this.updateDashboardTitle(event.detail as unknown as string),
    openFileModal: (event: MouseEvent) => this.openFileModal(event),
  };
  private dashboardEl: Element & { [key: string]: any };
  private linkModalEl: Element & { [key: string]: any };

  /**
   * @ngInject
   */
  constructor(
    layoutService: LayoutService,
    appsService: AppsService,
    dashboardService: DashboardService,
    private readonly dashboardActionsService: DashboardActionsService,
    private readonly notificationService: NotificationService,
    readonly language: ILanguage,
    private readonly actionLogService: ActionLogService,
    private readonly frameLayoutService: FrameLayoutService,
    private readonly tabService: TabService,
    popupService: PopupService
  ) {
    super();
    this._layoutService = layoutService;
    this._dashboardService = dashboardService;
    this._notificationService = notificationService;
    this._subscriptions = [];
    this.appsService = appsService;
    this.isMobile = this._layoutService.mobileLayout;
    this._popupService = popupService;

    setTimeout(() => {
      this.addDashboardEvents(this.dashboardName);
    });
  }

  get gutter(): number {
    return this.isMobile ? 8 : 14;
  }

  /**
   * Updates the current stacking context of the dashboards, the selected one
   * will be above all the others in the background. This is used to fix the behaviour
   * where some widgets from the dashboards were being displayed above the currently
   * opened dashboard (MWP-16348).
   * @param ids dashboard ids for the triggered event.
   */
  updatedDashboardIndex(ids: string[]): void {
    const elements = document.querySelectorAll(`mwp-dashboards`);

    elements.forEach(element => {
      const elementId = element.getAttribute('wc-id');
      // Sometimes the dashboardName is not set yet, if that's the case we do nothing.
      if (elementId.includes('vm.dashboardName')) {
        return;
      }

      const isDashboardSelected = ids.some(id => element.getAttribute('wc-id') === `mwp-dashboard-${id}`);
      if (isDashboardSelected) {
        element.classList.add('z-index-layer-dashboard-container');
        element.classList.remove('z-index-layer-hidden-dashboard-container');
      } else {
        element.classList.add('z-index-layer-hidden-dashboard-container');
        element.classList.remove('z-index-layer-dashboard-container');
      }
    });
  }

  shareTile(event: MouseEvent) {
    const { detail: item } = event;
    this.dashboardActionsService.shareTile(item);
  }

  updateDashboardTitle(dashboardTitle: string): void {
    const dashboard = { name: this.dashboardName, title: dashboardTitle, description: dashboardTitle } as IDashboard;
    this._dashboardService.channel.publish(DashboardService.DASHBOARD_RENAMED, { dashboard });
    this._dashboardService.updateDashboardTitle(this.dashboardName, dashboardTitle);
    this._scope.$apply();
  }

  async openFileModal(event: MouseEvent) {
    const { detail: item } = event;
    const { title, url } = item as any;
    this.openFilesModal.fileTitle = title;
    this.openFilesModal.fileUrl = url;

    if (!this.linkModalEl) {
      this.linkModalEl = await this._popupService.initFileModal(this.dashboardName);
    }

    if (this.linkModalEl) {
      this.linkModalEl.isModalOpen = true;
    }

    this._scope.$apply();
  }

  copyDashboardDeepLink(dashboardId: string) {
    const url = `${window.location.origin}${window.location.pathname}#/dashboard/${dashboardId}`;
    navigator.clipboard.writeText(url);
    this._notificationService.showSuccess('copyUrl.success');
  }

  async addDashboardEvents(dashboardId: string) {
    this.dashboardEl = document.querySelector(`mwp-dashboards[wc-id="mwp-dashboard-${dashboardId}"]`);

    if (this.dashboardEl) {
      createWebComponentHandlers(this.dashboardEl, this.dashboardEventHandlers);
      this.dashboardEl.dashboardId = this.dashboardName;
      this.dashboardEl.locale = this.language.locale;
      this.dashboardEl.analytics = this.getPlatformAnalytics();
    }
  }

  /**
   * Component has been rendered
   */
  onRenderComponent(el: JQuery, scope: ng.IScope): void {
    this._scope = scope;

    this._subscriptions.push(
      this.tabService.channel.subscribe(TabService.TOPIC_TAB_SELECT, message => {
        if (message.tabId === this.dashboardName) {
          this.updatedDashboardIndex([this.dashboardName]);
        }
      })
    );

    // Updating the current stacking context of the dashboard
    this.updatedDashboardIndex(this.tabService.getDashboardTabIds());
  }

  /**
   * Destroy the component
   */
  destroy(): void {
    this._subscriptions.forEach((sub: ISubscriptionDefinition<any>) => sub.unsubscribe());
    this.appsService = null;
    removeWebComponentHandlers(this.dashboardEl, this.dashboardEventHandlers);
    this.dashboardEl = null;
  }

  // We should move this to its own file like a service as is used in tabview also
  private getPlatformAnalytics(): IAnalytics {
    return PlatformAnalyticsFactory({
      callApi: (name: string, ...params: any[]): Promise<any> => {
        if (name !== Dashboard.CREATE_ACTION_LOG_FUNC) {
          return Promise.reject('Wrong API function');
        }
        const logEntry = params[0];
        this.actionLogService.logAction(logEntry);
      },
    });
  }

  private updateAppUrl(appUrl: string, customSettingsUrl: string): string {
    const appOriginUrl = UrlHelper.getOriginFromUrl(appUrl);
    const settingsOriginUrl = UrlHelper.getOriginFromUrl(customSettingsUrl);

    if (settingsOriginUrl !== null) {
      return customSettingsUrl;
    }

    if (appOriginUrl !== null) {
      return appOriginUrl + '/' + customSettingsUrl;
    }

    return appUrl + customSettingsUrl;
  }
}
