import { Connection, Host, Messaging } from '@myworkplace/api';
import * as uiRouter from 'angular-ui-router';
import _ from 'lodash';
import { DeviceService } from '../../util/device.service';
import { ILanguage } from '../../util/language.model.interface';
import { getClientOs, OS_TYPE } from '../../util/link-utils/os.client';
import { UrlHelper } from '../../util/url.helper';
import { ActionConstants } from '../actionLog/action-constants';
import { IActionLogEntry } from '../actionLog/action-log-entry.interface';
import { ActionLogService } from '../actionLog/action-log.service';
import { getToken, saveToken } from '../app.auth';
import { Icons } from '../app.icons';
import { IframeDragToWorkplaceService } from '../appBehavior/iframe-drag-to-workplace.service';
import { IDndCoordinates, IDragObject } from '../appBehavior/interframe-drag-manager.interface';
import { InterframeDragService } from '../appBehavior/interframe-drag-manager.service';
import { APP_TASKS_MANAGER, IApplication } from '../apps/application.model.interface';
import { AppsFrameComponent } from '../apps/apps.frame.component';
import { AppsService, IOpenAppConfig, IOpenAppConfigOptions } from '../apps/apps.service';
import { IIFrameComponent } from '../components/iframe-component';
import { IDashboard } from '../dashboard/dashboard.model.interface';
import { DashboardService } from '../dashboard/dashboard.service';
import { WorkplaceApiError } from '../errors/workplace-api.error';
import { IFrameLayout, ILayoutFrameDescriptor } from '../frameLayout/frame-layout.model.interface';
import { FrameLayoutService } from '../frameLayout/frame-layout.service';
import { LayoutService } from '../layout/layout.service';
import { NotificationService } from '../notification/notification.service';
import { PopupService } from '../notification/popup.service';
import { SessionDataService } from '../sessionData/session-data.service';
import { ISupportConfig } from '../support/support-config.interface';
import {
  ITabContentAppsLayout,
  ITabContentIFrame,
  ITabContentTemplate,
  TabContentType,
} from '../tab/tab.content.interface';
import { ITabServiceChannelTabMessage, TabService } from '../tab/tab.service';
import { ITaskSessionUpgrade, IWorkplaceTask } from '../task/tasks.model.interface';
import { TasksService } from '../task/tasks.service';
import { Role } from '../user/role.model';
import { User } from '../user/user.model';
import { IUser } from '../user/user.model.interface';
import { IUserServiceMessage, UserService } from '../user/user.service';
import { UserSettingService } from '../userSetting/user-setting.service';
import { WidgetDefinitions } from '../widget/widget.data.interface';
import { WidgetService } from '../widget/widget.service';
import { GraphQLService } from './graphql.service';
import { IUserSettingsStorage } from './user-settings-storage.interface';
import { IUserSettingsStoreable } from './user-settings-storeable.interface';
import { IWorkplaceApiSource } from './workplace-api-source.interface';
import { WorkplaceNativeDeviceService } from './workplace-native-device.service';
import { IWorkplaceProperty } from './workplace-property.interface';
import { WorkplaceContextService } from './workplace.context.service';
import { IWidgetDescriptor } from '../widget/widget.descriptor.model';
import { AppsFrameType } from '../apps/apps.frame.interface';
import { FetchPolicy } from '@apollo/client';
import { IdCardService } from '../web-components/id-card/id-card.service';
import { JiraAuthService } from '../jira/jira-auth.service';
import { WidgetSettingsService } from '../widget/settings/core/widget-settings/widget-settings.service';
import { MicrosoftService } from '../widget/services/microsoft.service';
import { MwpStoreService } from '../web-components/mwp-store/mwp-store.service';
import { IPromise } from 'angular';
import { NavigationComponentsService } from '../web-components/navigation/navigation.service';

declare var cordova: any;

const CONNECTION_GROUP_DEFAULT = 'defaultGroup';

/**
 * Provides functionality in the workplace that can be used from widgets or applications
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class WorkplaceApiService {
  private _appsService: AppsService;
  private _stateService: uiRouter.IStateService;
  private _locationService: ng.ILocationService;
  private _host: Host;
  private _connections: { [group: string]: { [key: string]: Connection } };
  private _log: ng.ILogService;
  private _tabService: TabService;
  private _qService: ng.IQService;
  private _layoutService: LayoutService;
  private _notificationService: NotificationService;
  private _windowService: ng.IWindowService;
  private _actionLogService: ActionLogService;
  private _workplaceContextService: WorkplaceContextService;
  private _widgetService: WidgetService;

  private _dashboardService: DashboardService;
  private _popupService: PopupService;
  private _workplaceNativeDeviceService: WorkplaceNativeDeviceService;
  private _interframeDragService: InterframeDragService;
  private _iframeDragToWorkplaceService: IframeDragToWorkplaceService;
  private _userSettingService: UserSettingService;
  private _userInfoService: UserService;
  private _frameLayoutService: FrameLayoutService;
  private _httpService: ng.IHttpService;
  private _deviceService: DeviceService;
  private _tasksService: TasksService;
  private _userSettingsStorageService: IUserSettingsStorage;
  private _language: ILanguage;
  private _translateService: angular.translate.ITranslateService;
  private _timeoutService: ng.ITimeoutService;
  private _graphqlService: GraphQLService;
  private _user: User;
  private _sessionDataService: SessionDataService;
  private _taskService: TasksService;
  private _idCardService: IdCardService;
  private _widgetSettingsService: WidgetSettingsService;
  private _microsoftService: MicrosoftService;
  private strongAuthService: number;

  /**
   * @ngInject
   */
  constructor(
    appsService: AppsService,
    $state: uiRouter.IStateService,
    $location: ng.ILocationService,
    $log: ng.ILogService,
    tabService: TabService,
    $q: ng.IQService,
    layoutService: LayoutService,
    notificationService: NotificationService,
    $window: ng.IWindowService,
    actionLogService: ActionLogService,
    workplaceContextService: WorkplaceContextService,
    widgetService: WidgetService,
    dashboardService: DashboardService,
    popupService: PopupService,
    workplaceNativeDeviceService: WorkplaceNativeDeviceService,
    interframeDragService: InterframeDragService,
    iframeDragToWorkplaceService: IframeDragToWorkplaceService,
    userSettingService: UserSettingService,
    userService: UserService,
    frameLayoutService: FrameLayoutService,
    $http: ng.IHttpService,
    deviceService: DeviceService,
    tasksService: TasksService,
    userSettingsStorageService: IUserSettingsStorage,
    language: ILanguage,
    $translate: angular.translate.ITranslateService,
    $timeout: ng.ITimeoutService,
    graphqlService: GraphQLService,
    sessionDataService: SessionDataService,
    private $interval: ng.IIntervalService,
    idCardService: IdCardService,
    private readonly jiraAuthService: JiraAuthService,
    widgetSettingsService: WidgetSettingsService,
    private readonly mwpStoreService: MwpStoreService,
    microsoftService: MicrosoftService,
    private readonly navigationComponentsService: NavigationComponentsService
  ) {
    this._appsService = appsService;
    this._tabService = tabService;
    this._stateService = $state;
    this._userSettingService = userSettingService;
    this._userInfoService = userService;
    this._locationService = $location;
    this._host = Messaging.createHost();
    this._connections = {};
    this._connections[CONNECTION_GROUP_DEFAULT] = {};
    this._log = $log;
    this._qService = $q;
    this._layoutService = layoutService;
    this._notificationService = notificationService;
    this._windowService = $window;
    this._actionLogService = actionLogService;
    this._workplaceContextService = workplaceContextService;
    this._dashboardService = dashboardService;
    this._sessionDataService = sessionDataService;
    this._widgetService = widgetService;
    this._popupService = popupService;
    this._workplaceNativeDeviceService = workplaceNativeDeviceService;
    this._interframeDragService = interframeDragService;
    this._iframeDragToWorkplaceService = iframeDragToWorkplaceService;
    this._frameLayoutService = frameLayoutService;
    this._httpService = $http;
    this._deviceService = deviceService;
    this._tasksService = tasksService;
    this._userSettingsStorageService = userSettingsStorageService;
    this._language = language;
    this._userInfoService.channel.subscribe(
      UserService.TOPIC_SELECTED_ROLE_CHANGED,
      this.notifyExternalApps.bind(this)
    );
    this._translateService = $translate;
    this._timeoutService = $timeout;
    this._appsService.setApiService(this);
    this._frameLayoutService.setApiService(this);
    this._dashboardService.setApiService(this);
    this._sessionDataService.setApiService(this);
    this._graphqlService = graphqlService;
    this._taskService = tasksService;
    this._userInfoService.getUser().then((user: User) => {
      this._user = user;
    });
    this._idCardService = idCardService;
    this._widgetSettingsService = widgetSettingsService;
    this._microsoftService = microsoftService;

    // setInterval fixes MWP-1247, but introduces a memory leak in IE11, hence the browser detection...
    if (!this._deviceService.isInternetExplorer()) {
      setInterval(() => new MessageChannel().port1.postMessage(''), 50);
    }

    this._userInfoService.getUser().then((user: User) => {
      // Set initial strong auth service used as 1000 as it is the default first time logging in
      if (user.authLevel > 1000) {
        this.strongAuthService = 1000;
      }
    });
  }

  openAuthFlow(app: IApplication): IPromise<boolean> {
    return this._appsService.openAppAuthFlow(app);
  }

  /**
   * Open browser/workplace tab by providing url and startMode
   * @param config
   */
  openHelpUrl(config: any, frame: IIFrameComponent): void {
    const app = frame ? frame.getApp() : null;

    if (!app) {
      return;
    }

    const urlOrigin = UrlHelper.getOriginFromUrl(config.url);
    const configuredAppOrigin = UrlHelper.getOriginFromUrl(app.url);
    // if an absolute path is given in the config.url, than use that path
    // otherwise, try to extract the app domain origin out of app.url and
    // concatenate that with the given config.url parameter.
    // if the app origin could not be extracted, just concatenate the configured app.url with the
    // given params
    let helpUrl =
      urlOrigin && urlOrigin !== null
        ? config.url
        : configuredAppOrigin && configuredAppOrigin !== null
        ? `${configuredAppOrigin}${config.url}`
        : `${config.url}`;
    this._appsService.openHelp({
      name: app.name,
      iconCls: Icons.HELP,
      description: app.description,
      helpUrl: helpUrl,
      startMode: config.startMode,
    });
  }

  /**
   * Open app by providing a workplace url
   *
   * @param url
   */
  openAppByUrl(url: string): void {
    setTimeout(() => (window.location.hash = url));
  }

  /**
   * Open app by providing a name and pass additional parameters to the application
   * The result of the api call contains the tabId of the opened application.
   * According to the configuration of the app, consecutive calls to openApp might open several tabs.
   *
   * @param name
   * @param params
   * @param options
   * @param opener id of the opening instance (another app)
   * @param frame
   * @returns {ng.IPromise<string>} the tabId of the opened application.
   */
  openApp(
    config: string | IOpenAppConfig,
    params?: any,
    options?: IOpenAppConfigOptions,
    opener?: string,
    frame?: IIFrameComponent
  ): ng.IPromise<string> {
    if (typeof config === 'string') {
      config = {
        name: <string>config,
        queryParams: params,
      };
    }

    if (config.options && !options) {
      options = config.options;
    }

    if (options && options.modal) {
      config.queryParams = { ...config.queryParams, isMaximized: true };
    }

    let actionLogEntry = <IActionLogEntry>{
      category: ActionConstants.CATEGORY_API,
      action: ActionConstants.ACTION_API_OPEN_APP,
      actionInfo: (<IOpenAppConfig>config).name,
    };

    if (opener) {
      let tab = this._tabService.getTabById(opener);
      if (tab) {
        if (tab.content && tab.content.type === TabContentType.IFRAME) {
          actionLogEntry.source = (<ITabContentIFrame>tab.content).app && tab.content.app.name;
        } else if (tab.content && tab.content.type === TabContentType.TEMPLATE) {
          actionLogEntry.source =
            (<ITabContentTemplate>tab.content).dashboardParentName || (<ITabContentTemplate>tab.content).dashboardName;
        } else if (tab.content && tab.content.type === TabContentType.FRAME_LAYOUT_TEMPLATE) {
          actionLogEntry.source = (<ITabContentAppsLayout>tab.content).frameLayout.id;
        }
      }
    }

    this._actionLogService.logAction(actionLogEntry);

    const layout = this._frameLayoutService.getLayoutById(config.name);
    if (layout) {
      const queryParams = config.queryParams;
      return this.openLayout(frame, {
        id: config.name,
        context: undefined,
        widgetContext: {
          widgetId: layout.frames[0].widget.id,
          path: '',
          params: queryParams,
          hash: config.hash,
        },
        connectIds: [layout.frames[0].widget.id],
      }).then(() => {
        return layout.id + '.' + layout.frames[0].widget.id;
      });
    } else {
      if (options && (options.connect || options.refresh)) {
        const queryParams = (<IOpenAppConfig>config).queryParams;
        (<IOpenAppConfig>config).queryParams = null;
        delete (<IOpenAppConfig>config).queryParams;
        return this._appsService
          .openAppByName(config)
          .then((id: string) => {
            this.refreshApp(id, queryParams);
            return id;
          })
          .then((id: string) => {
            if (opener && options && options.splitView) {
              this.splitView(id, opener);
            }
            return id;
          });
      }
      return this._appsService.openAppByName(config).then((id: string) => {
        if (opener && options && options.splitView) {
          this.splitView(id, opener);
        }
        return id;
      });
    }
  }

  /**
   * Selects a tab and therefore makes the tab content visible.
   * The tabId is provided by the api call openApp.
   * @param tabId
   */
  selectTab(tabId: string): void {
    this._tabService.selectTab(tabId);
  }

  /**
   * Close a tab.
   * The tabId is provided by the api call openApp.
   *
   * First we select the tab that is about to be closed, as it might require some user input for unsaved changes.
   * After the tab has been closed, we select the previous tab.
   *
   * @param {string} tabId
   * @param frame frame that called closeTab
   */
  closeTab(tabId: string, frame?: IIFrameComponent | IWorkplaceApiSource): void {
    if (this._tabService.getTabById(tabId)) {
      this.selectTab(tabId);
      if (frame && this.isFrame(frame)) {
        const subscription = this._tabService.channel.subscribe(
          TabService.TOPIC_TAB_REMOVE,
          (message: ITabServiceChannelTabMessage) => {
            if (message.tabId === tabId) {
              subscription.unsubscribe();
              this.selectTab(frame.getId());
            }
          }
        );
      }
      this._tabService.closeTab(tabId);
    } else {
      this._appsService.closeModalApp(tabId);
    }
  }

  /**
   * Sets the name of the current tab
   * @param tabName
   */
  setTabTitle(frame: IIFrameComponent | IWorkplaceApiSource, tabTitle: string): void {
    this.logApiCall(`setTabTitle('${tabTitle}')`, frame);
    if (frame) {
      if (!tabTitle.trim().length) {
        throw new Error('Tab title cannot be empty string');
      }

      const tabId = frame.getConnectionGroupId() ? frame.getConnectionGroupId() : frame.getId();
      const tabManager = this._tabService.findTabManagerForTabId(tabId);
      const tab = tabManager.getTabById(tabId);

      // run during digest cycle
      this._timeoutService(() => {
        tab.providedTitle = tabTitle;
        tab.title = tabTitle;
        this.navigationComponentsService.updateHeaderTabsInput(tabManager.id, tab);
      });
    }
  }

  /**
   * Show the app support overlay
   */
  showAppSupport(frame: IIFrameComponent, supportCfg: ISupportConfig): void {
    this._workplaceContextService.getProperty('workplace.origin').then((origin: IWorkplaceProperty) => {
      if (origin.value !== 'intranet') {
        return;
      }
      supportCfg.appName = frame.getDescription();
      this._tabService.showAppSupportOverlay(frame.getId(), supportCfg, 30);
    });
  }

  /**
   * Check whether a given app is available
   * @param appName name of the app
   */
  isAppAvailable(appName: string): ng.IPromise<boolean> {
    const hasLayout: ng.IPromise<boolean> = new Promise(resolve => {
      const layout = this._frameLayoutService.getLayoutById(appName);
      resolve(!!layout);
    });
    const hasApp = this._appsService
      .getApp(appName)
      .then(() => true)
      .catch(() => false);
    return Promise.all([hasApp, hasLayout])
      .then(([hasApp, hasLayout]) => hasApp || hasLayout)
      .catch(() => false);
  }

  /**
   *
   * @param comp
   * @param win
   * @param group - optional
   */
  createConnection(comp: AppsFrameComponent, win: Window, group?: string): void {
    const url = comp.getUrl();
    const id = comp.getId();
    const name = comp.getName();
    let origin = [UrlHelper.getOriginFromUrl(url) || window.location.origin];
    if (comp.getApp && comp.getApp().apiOrigin) {
      origin = comp.getApp().apiOrigin.split(',');
    }
    this._host
      .createConnection(id, origin, win, group)
      .then((connection: Connection) => {
        connection.onClose(() => {
          const tab =
            this._tabService.getTabById(id) ||
            (comp.frame && comp.frame.connectionGroupId
              ? this._tabService.getTabById(comp.frame.connectionGroupId)
              : null);
          if (tab) {
            comp.createConnection();
          }
        });
        this._log.info(
          `WorkplaceApiService: Created connection with app "${name}" (${id}) restricted to origin ${origin}`
        );
        this.registerApiFunctions(connection, comp, comp);
        this.addConnection(connection, id, group);
      })
      .catch((reason: any) =>
        this._log.info(`WorkplaceApiService: Failed to create connection with app "${name}" (${id}). Reason: ${reason}`)
      );
  }

  createConnectionToWindow(app: IApplication, win: Window, id: string): void {
    const url = app.url;
    const name = app.name;
    const origin = UrlHelper.getOriginFromUrl(url) || window.location.origin;
    this._host
      .createConnection(id, origin, win)
      .then((connection: Connection) => {
        connection.onClose(() => {
          if (!win.closed) {
            this.createConnectionToWindow(app, win, id);
          }
        });
        this._log.info(
          `WorkplaceApiService: Created connection with app "${name}" (${id}) restricted to origin ${origin}`
        );
        this.registerApiFunctions(connection, <IWorkplaceApiSource>{
          getId: (): string => id,
          getName: (): string => name,
          getDescription: (): string => app.description,
          getConnectionGroupId: (): string => null,
        });
        this.addConnection(connection, id);
      })
      .catch((reason: any) =>
        this._log.info(`WorkplaceApiService: Failed to create connection with app "${name}" (${id}). Reason: ${reason}`)
      );
  }

  createLocalConnection(
    id: string,
    group: string,
    source: IWorkplaceApiSource,
    storeable: IUserSettingsStoreable
  ): Promise<Connection> {
    let local = this._host.createLocalConnection(id, group);
    return this._host.getConnection(id, group).then((connection: Connection) => {
      this._log.info(`WorkplaceApiService: Created local connection for ${id}.`);
      this.registerApiFunctions(connection, source, storeable);
      this.addConnection(connection, id, group);
      return local;
    });
  }

  /**
   * Return a promise of a connection, based on the connection id.
   *
   * @param connectionId
   * @returns {Promise<Connection>}
   */
  getConnection(connectionId: string, group?: string): Promise<Connection> {
    return this._host.getConnection(connectionId, group);
  }

  /**
   * Check to see if a connectins exists
   */
  hasConnection(connectionId: string, group?: string): boolean {
    return this._host.hasConnection(connectionId, group);
  }

  /** OIDC Session Upgrade */
  checkStrongAuthPermissions(app: IApplication | IFrameLayout | IDashboard): ng.IPromise<boolean> {
    let deferred = this._qService.defer<boolean>();
    this._userInfoService.getUser().then((user: User) => {
      const userStrongAuth: number = user.authLevel;
      const appStrongAuth: number = app?.strongAuth ?? null;
      /** no app or user, return */
      if (userStrongAuth === null || appStrongAuth === null) {
        deferred.reject(false);
      }
      /** if strong auth is required, we always do a session upgrade */
      if (appStrongAuth <= 1000) {
        deferred.resolve(true);
      } else {
        /** session upgrade */
        let checkClose: ng.IPromise<void>;
        let listener: EventListenerOrEventListenerObject;
        let sessionUpgradeWindow: Window;
        let inAppBrowserWin: Window;

        const url = `./rest/auth/start?acrValue=${appStrongAuth}&isSessionUpgrade=true`;

        let cleanupWindow = () => {
          this.$interval.cancel(checkClose);
          if (listener) {
            window.removeEventListener('message', listener);
          }
          if (sessionUpgradeWindow && !sessionUpgradeWindow.closed) {
            sessionUpgradeWindow.close();
          }
        };

        let cleanupInAppBrowser = () => {
          if (listener) {
            inAppBrowserWin.removeEventListener('message', listener);
          }
          inAppBrowserWin.close();
        };

        /** add listener */
        listener = (event: MessageEvent) => {
          if (!event || !event.data) {
            deferred.reject(false);
          }

          const response = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
          if (response?.message === 'session-upgraded' && response?.tokens) {
            this._workplaceNativeDeviceService.isPlatformIOS() ? cleanupInAppBrowser() : cleanupWindow();
            this.strongAuthService = appStrongAuth;
            /** only update token if we got a higher level */
            if (userStrongAuth < appStrongAuth) {
              /** save received token */
              saveToken(response.tokens);
            }
            /** update user's strongAuth value in store */
            return this._userInfoService.updateUserStrongAuth(appStrongAuth).then((authLevel: number) => {
              deferred.resolve(authLevel && authLevel >= appStrongAuth);
            });
          }
        };

        this._log.log(`Using session upgrade for opening ${app.name}`);

        if (this._workplaceNativeDeviceService.isPlatformIOS()) {
          inAppBrowserWin = this._workplaceNativeDeviceService.openInAppBrowser(url);
        } else {
          /** open session upgrade window */
          const params = `width=800,height=500,left=100,top=100`;
          sessionUpgradeWindow = window.open(url, 'login', params);
          /** if popup was blocked inform the user and close the flow */
          if (!sessionUpgradeWindow || sessionUpgradeWindow.closed) {
            this._notificationService.showWarn('error.popup.blocked');
            deferred.reject(false);
          }
          /** focus the new window and check for user closing the window before actually logging in */
          sessionUpgradeWindow.focus();
          checkClose = this.$interval(() => {
            if (sessionUpgradeWindow?.closed) {
              cleanupWindow();
              this._notificationService.showError('oidc.permissions.denied');
              deferred.reject(false);
            }
          }, 200);
        }

        this._workplaceNativeDeviceService.isPlatformIOS()
          ? inAppBrowserWin.addEventListener('message', listener)
          : window.addEventListener('message', listener);
      }
    });
    return deferred.promise;
  }

  /** trigger Session Upgrade */
  async triggerSessionUpgrade(config: ITaskSessionUpgrade): Promise<boolean> {
    if (!config || !config.authLevel || !config.appName) {
      return false;
    }

    /** check for app in store */
    const requestedApp = await this._appsService.getApp(config.appName);
    if (!requestedApp) {
      this._notificationService.showError('session.upgraded.error.app_not_found');
      return false;
    }

    const fakeApp: IApplication = {
      name: config.appName,
      strongAuth: config.authLevel,
      description: requestedApp.description,
      iconCls: requestedApp.iconCls,
    };

    /** trigger the upgrade */
    const sessionUpgraded = await this.checkStrongAuthPermissions(fakeApp);
    if (!sessionUpgraded) {
      this._notificationService.showError('session.upgraded.error.session_not_upgraded');
      return false;
    }

    /** refresh app */
    if (config.refresh) {
      this._appsService.openAppByName({
        name: config.appName,
        refreshUrl: true,
        path: config.path || '',
      });
    }

    return true;
  }

  /**
   * Register a set of api functions to a connection
   * @param connection
   * @param frame
   */
  registerApiFunctions(
    connection: Connection,
    frame?: IWorkplaceApiSource | IIFrameComponent,
    storeable?: IUserSettingsStoreable
  ): void {
    connection.registerApi('openApp', (name: string | IOpenAppConfig, params: any, options?: IOpenAppConfigOptions) => {
      this.logApiCall(`openApp(${JSON.stringify(name)})`, frame);
      let opener = frame && (frame.getConnectionGroupId() ? frame.getConnectionGroupId() : frame.getId());
      return this.openApp(name, params, options, opener, frame as IIFrameComponent).then((id: string) => {
        const deferred = this._qService.defer<string>();
        this._host
          .getConnection(id)
          .then(() => deferred.resolve(id))
          .catch((reason: any) => deferred.reject(reason));
        return deferred.promise;
      });
    });
    connection.registerApi('openUserSettings', (option?: string) => this.openUserSettings(option));
    connection.registerApi('openContactDetails', (qNumber: string) => this._idCardService.openIdCard(qNumber));
    connection.registerApi('getMicrosoftToken', () => {
      return this._microsoftService.getMicrosoftToken();
    });
    connection.registerApi('isUserLoggedInMs', () => {
      return this._microsoftService.isUserLoggedIn();
    });
    connection.registerApi('selectTab', (tabId: string) => {
      this.logApiCall(`selectTab(${tabId})`, frame);
      return this.selectTab(tabId);
    });
    connection.registerApi('closeTab', (tabId: string) => {
      this.logApiCall(`closeTab(${tabId})`, frame);
      return this.closeTab(tabId, frame);
    });
    connection.registerApi('getTabId', () => {
      this.logApiCall(`getTabId()`, frame);
      return frame.getId();
    });
    connection.registerApi('refreshUserSettings', () => {
      this.logApiCall(`refreshUserSettings()`, frame);
      this._userSettingService.refreshSettings();
    });
    connection.registerApi('refreshUserInfo', (roleId: string) => {
      this.logApiCall(`refreshUserInfo()`, frame);
      this._userInfoService.refreshUserInfo(roleId);
    });

    connection.registerApi('isAppAvailable', (appName: string) => {
      this.logApiCall(`isAppAvailable(${appName})`, frame);
      return this.isAppAvailable(appName);
    });
    connection.registerApi('showNotification', this.showNotification.bind(this, frame));
    connection.registerApi('preconditionFailed', this.preconditionError.bind(this, frame));

    connection.registerApi('saveDashboardFavoriteLink', (widgetDesc: any) => {
      this.logApiCall(`saveDashboardFavoriteLink(${widgetDesc.title})`, frame);
      return this.saveDashboardFavoriteLink(widgetDesc);
    });

    connection.registerApi('globalDeputyChanged', this.globalDeputyChanged.bind(this, frame));

    connection.registerApi('triggerSessionUpgrade', (config: ITaskSessionUpgrade) => {
      this.logApiCall(`triggerSessionUpgrade for ${config.appName}`, frame);
      return this.triggerSessionUpgrade(config);
    });

    connection.registerApi('setTabState', this.setTabState.bind(this, frame));

    connection.registerApi('frameFullScreenToggle', (toggle: boolean) =>
      this._frameLayoutService.frameFullScreenToggle(frame.getId(), toggle)
    );
    connection.registerApi('getAppLinksForBO', (boType: string, boId: string) => {
      this.logApiCall(`getAppLinksForBO(${boType}, ${boId})`);
      return this.getAppLinksForBo(boType, boId);
    });
    connection.registerApi(
      'createTask',
      (title: string, boType?: string, boNumber?: string, splitView: boolean = false) => {
        this.logApiCall(`createTask(${boType}, ${boNumber}, ${splitView})`);
        return this.handleNextOneDeepLink({
          action: 'create',
          title,
          openerId: frame.getId(),
          boType,
          boNumber,
          splitView,
        });
      }
    );

    connection.registerApi('openMwpStoreAppDetails', (appId: string) => {
      this.logApiCall(`openMwpStoreAppDetails(${appId})`);
      return this.mwpStoreService.openStoreItemDetails(appId, 'STORE-ITEM-APPLICATION');
    });

    connection.registerApi('filterTasksByBO', (boType: string, boNumber: string, splitView: boolean = false) => {
      this.logApiCall(`filterTasksByBO(${boType}, ${boNumber})`);
      return this.handleNextOneDeepLink({
        action: 'filter',
        openerId: frame.getId(),
        boType,
        boNumber,
        splitView,
      });
    });
    connection.registerApi('getTaskDeepLink', (task: IWorkplaceTask, justPath: boolean) => {
      this.logApiCall(`getTaskDeepLink(${task})`);
      return this.getTaskDeepLink(task, justPath);
    });
    connection.registerApi('updateTasks', this.updateTasks.bind(this, frame));
    connection.registerApi('getTabInfo', (tabId: string) => {
      this.logApiCall(`getTabInfo(${tabId}})`);
      const tab = this._tabService.getTabById(tabId);
      if (tab) {
        return _.merge(
          _.pick(this._tabService.getTabById(tabId), [
            'id',
            'iconCls',
            'title',
            'active',
            'visible',
            'closeable',
            'refreshable',
          ]),
          _.pick((<ITabContentIFrame>tab.content).app, ['url', 'description'])
        );
      }
      return null;
    });
    connection.registerApi('getDashboardDeepLink', (name: string) => {
      this.logApiCall(`getDashboardDeepLink(${name})`);
      return this.getDashboardDeepLink(name);
    });
    connection.registerApi(
      'addAppToDashboard',
      (
        app: IApplication,
        dashboardName: string,
        dashboardParentName?: string,
        categoryId?: string,
        definitionId?: string
      ) => {
        this.logApiCall(`addAppToDashboard(${app.name},${dashboardName})`);
        return this.addAppToDashboard(app, dashboardName, dashboardParentName, categoryId, definitionId);
      }
    );

    connection.registerApi(
      'addWidgetToDashboard',
      (widget: IWidgetDescriptor<any>, dashboardName: string, categoryId: string, definitionId: string) => {
        this.logApiCall(`addWidgetToDashboard(${widget.id},${dashboardName})`);
        return this.addWidgetToDashboard(widget, dashboardName, categoryId, definitionId);
      }
    );

    connection.registerApi('sessionExpired', () => {
      this.logApiCall(`sessionExpired`, frame);
      this._workplaceContextService.handleSessionExpired();
    });

    connection.registerApi('openLayout', this.openLayout.bind(this, frame));
    connection.registerApi('setLayoutIFrameSize', this.setLayoutIFrameSize.bind(this, frame));
    connection.registerApi('openDashboard', this.openDashboard.bind(this, frame));
    connection.registerApi('getLang', this.getLang.bind(this, frame));
    connection.registerApi('getLocale', this.getLocale.bind(this, frame));
    connection.registerApi('getUserQNumber', this.getUserQNumber.bind(this, frame));
    connection.registerApi('updateDynamicTile', this.updateDynamicTile.bind(this, frame));
    connection.registerApi('openMailClient', (params: { recipients: string[]; subject?: string }) => {
      this.logApiCall(`openMailClient(${JSON.stringify(params)})`, frame);
      this.openMailClient(params);
    });
    connection.registerApi('createActionLog', this.createActionLog.bind(this, frame));

    connection.registerApi('saveSettings', async (settings: string) => {
      if (storeable.settingsStorageKey === 'widget') {
        await this._widgetSettingsService.saveSettings(storeable, settings);
      } else {
        this._userSettingsStorageService.saveSettings(storeable, settings);
      }
      return true;
    });

    connection.registerApi('loadSettings', () => {
      if (storeable.settingsStorageKey === 'widget') {
        return this._widgetSettingsService.loadSettings(storeable);
      } else {
        return this._userSettingsStorageService.loadSettings(storeable);
      }
    });

    connection.registerApi('closeMaximizedWidget', () => {
      this._widgetService.channel.publish(WidgetService.CLOSE_MAXIMIZED_WIDGET);
    });

    connection.registerApi('getAccessToken', () => {
      return getToken();
    });

    connection.registerApi('openTask', (workplaceTask: IWorkplaceTask, deputizedFor?: string) => {
      this._tasksService.openTask(workplaceTask, deputizedFor);
    });

    connection.registerApi('getAppParameters', (connectionId: string, apiFn: string, group?: string) => {
      return this.getAppParameters(connectionId, apiFn, group);
    });

    // only available for apps
    if (this.isFrame(frame)) {
      connection.registerApi('showAppSupport', (supportCfg: ISupportConfig) => {
        this.logApiCall(`showAppSupport(${supportCfg ? 'cfg' : supportCfg})`, frame);
        this.showAppSupport(frame, supportCfg);
      });
      connection.registerApi('openHelpUrl', (config: any) => {
        this.logApiCall(`openHelpUrl(${config.url}, ${config.urlMode})`, frame);
        return this.openHelpUrl(config, frame);
      });
      connection.registerApi('createFavorite', (config: any) => {
        this.logApiCall(`createFavorite(${config.title}, ${config.url})`, frame);
        return this.createFavorite(frame, config);
      });
      connection.registerApi('setDragObject', (dragObject: IDragObject) =>
        this._interframeDragService.setDragObject(frame, dragObject)
      );
      connection.registerApi('setDragCoordinates', (position: IDndCoordinates) =>
        this._interframeDragService.setDragCoordinates(frame, position)
      );
      connection.registerApi('acceptDrop', this._interframeDragService.acceptDrop.bind(this._interframeDragService));
      connection.registerApi('rejectDrop', this._interframeDragService.rejectDrop.bind(this._interframeDragService));
      connection.registerApi('endDrag', this._interframeDragService.endDrag.bind(this._interframeDragService));
      connection.registerApi('setDragToWorkplaceObject', (dragObject: IDragObject) =>
        this._iframeDragToWorkplaceService.setDragObject(frame, dragObject)
      );
      connection.registerApi('setDragToWorkplaceCoordinates', (position: IDndCoordinates) =>
        this._iframeDragToWorkplaceService.setDragCoordinates(frame, position)
      );
      connection.registerApi('setTabTitle', this.setTabTitle.bind(this, frame));
    }

    // for mobile app
    connection.registerApi('nativeDeviceCameraGetPicture', this._workplaceNativeDeviceService.cameraGetPicture);
    connection.registerApi('nativeDeviceBarcodeScannerScan', this._workplaceNativeDeviceService.barcodeScannerScan);
    connection.registerApi(
      'nativeDeviceAddEventListener',
      this.nativeDeviceAddEventListener.bind(this, frame, connection)
    );
    connection.registerApi('nativeDeviceOpenWindow', this.openWindow.bind(this, frame));
    connection.registerApi('nativeDeviceOpenInAppBrowser', this.openInAppBrowser.bind(this, frame));
    connection.registerApi('nativeDeviceWriteFile', this._workplaceNativeDeviceService.writeFile);
    connection.registerApi('nativeDeviceReadFile', this._workplaceNativeDeviceService.readFile);
    connection.registerApi('nativeDeviceReadFileByNativeURL', this._workplaceNativeDeviceService.readFileByNativeURL);
    connection.registerApi('nativeDeviceListEntries', this._workplaceNativeDeviceService.listEntries);
    connection.registerApi('nativeDeviceCreateDirectory', this._workplaceNativeDeviceService.createDirectory);
    connection.registerApi('nativeDeviceRemoveEntry', this._workplaceNativeDeviceService.removeEntry);
    connection.registerApi('nativeDeviceCosysScannerScan', (cfg: any) => {
      return this._workplaceNativeDeviceService.cosysScannerScan(cfg, frame ? frame.getId() : null);
    });
    connection.registerApi(
      'nativeDeviceAnylineScan',
      this._workplaceNativeDeviceService.anylineScan.bind(this._workplaceNativeDeviceService)
    );
    connection.registerApi(
      'nativeDeviceAnylineScanMX',
      this._workplaceNativeDeviceService.anylineScanMX.bind(this._workplaceNativeDeviceService)
    );
    connection.registerApi(
      'nativeDeviceInfo',
      this._workplaceNativeDeviceService.deviceInfo.bind(this._workplaceNativeDeviceService)
    );

    connection.registerApi(
      'nativeDeviceOpenFile',
      this._workplaceNativeDeviceService.nativeDeviceOpenFile.bind(this._workplaceNativeDeviceService)
    );
    connection.registerApi(
      'nativeDeviceFileDownloader',
      this._workplaceNativeDeviceService.nativeDeviceFileDownloader.bind(this._workplaceNativeDeviceService)
    );

    connection.registerApi(
      'nativeDeviceAppInfo',
      this._workplaceNativeDeviceService.appInfo.bind(this._workplaceNativeDeviceService)
    );

    connection.registerApi('showConfirm', this._popupService.showConfirm.bind(this._popupService));

    connection.registerApi('startJiraAuthFlow', this.jiraAuthService.startAuthFlow.bind(this.jiraAuthService));

    //Register auth level update. This will perform callApi for all components that registers authLevelChanged api
    this._workplaceContextService.authLevelUpdated.subscribe(
      WorkplaceContextService.UPDATED_AUTH_LEVEL,
      ({ authLevel, strongAuthService }) => {
        connection.callApi('authLevelChanged', authLevel, strongAuthService).catch(() => {
          //do nothing. Avoid not implemented error logs.
        });
      }
    );

    this._workplaceContextService.languageChanged.subscribe(
      WorkplaceContextService.UPDATED_LANGUAGE,
      (lang: string) => {
        connection.callApi('languageChanged', lang).catch(() => {
          //do nothing. Avoid not implemented error logs.
        });
      }
    );

    // GraphQL
    const getGraphQLOperationHandler = (operationName: 'query' | 'mutation') => {
      return async (queryOrMutation: string, variables?: any, fetchPolicy?: FetchPolicy, context?: any) => {
        let result: any;
        try {
          result = await this._graphqlService[operationName as string](queryOrMutation, {
            variables,
            fetchPolicy,
            context,
          });
        } catch (e) {
          e.message = { message: e.message, graphQLErrors: e.graphQLErrors };
          throw e;
        }
        return result;
      };
    };

    const graphQLRemoveQueryHandler = () => {
      return async (queryName: string) => {
        return this._graphqlService.removeQueryFromCache(queryName);
      };
    };

    connection.registerApi('gqlQuery', getGraphQLOperationHandler('query'));
    connection.registerApi('gqlMutation', getGraphQLOperationHandler('mutation'));
    connection.registerApi('gqlRemoveQueryFromCache', graphQLRemoveQueryHandler());
  }

  /**
   * Close the connection to an app
   * @param frame
   */
  closeConnection(frame: IIFrameComponent, group?: string): void {
    this.removeConnection(frame.getId(), group);
    this._host.closeConnection(frame.getId(), group);
    this._log.info(`WorkplaceApiService: Closed connection with app "${frame.getName()}" (${frame.getId()})`);
  }

  /**
   * Refreshes an app over message channel
   * @param id
   */
  refreshApp(id: string, params: any, hash?: string): void {
    this._host.getConnection(id).then((connection: Connection) => connection.callApi('refresh', params, hash));
  }

  /**
   * Reload the workplace
   */
  reloadWorkplace(): void {
    const timestamp = new Date().getTime();
    let href = '/';
    if (this._workplaceNativeDeviceService.isPlatformIOS()) {
      href = '/iwp/ios.html';
    }
    if (this._workplaceNativeDeviceService.isPlatformWindows()) {
      href = '/iwp/windows.html';
    }
    this._windowService.location.href = UrlHelper.appendQueryString(href, { r: timestamp });
  }

  createFavorite(comp: IIFrameComponent, config: any): ng.IPromise<boolean> {
    const app = (comp as IIFrameComponent).getApp();
    // Type must be 'favorite' or 'search-favorite'
    let widgetDefinitionId = '';
    let type = '';
    if (config.type === WidgetDefinitions.TILE) {
      type = WidgetDefinitions.TILE;
      widgetDefinitionId = WidgetDefinitions.TILE;
    } else {
      widgetDefinitionId =
        config.type === WidgetDefinitions.SEARCH_FAVORITE || config.type === WidgetDefinitions.SEARCH_FAVORITE_NS
          ? config.type
          : WidgetDefinitions.SEARCH_FAVORITE;
      type = WidgetDefinitions.FAVORITE;
    }

    const widgetDesc = {
      widgetDefinitionId,
      colspan: app.colspan || config.colSpan || 1,
      rowspan: app.rowspan || config.rowSpan || 1,
      hidden: false,
      title: config.title ? config.title : app.name,
      type,
      iconCls: app.iconCls,
      iconUrl: app.iconUrl,
      customSettings: {
        app: app.name,
        title: config.title ? config.title : app.name,
        url: config.url || '',
      },
    };
    if (config.backgroundImageUrl && config.backgroundImageUrl.trim()) {
      widgetDesc.customSettings['backgroundImageUrl'] = config.backgroundImageUrl;
    }
    if (config.iconCls && config.iconCls.trim()) {
      widgetDesc['iconCls'] = config.iconCls;
    }

    const shell = document.querySelector('mwp-shell');
    if (!shell) return;

    shell['openCreateTileModal'](widgetDesc);
    return Promise.resolve(true);
  }

  openUserSettings(option: string): void {
    const shell = document.querySelector('mwp-shell');
    if (!shell) return;
    shell['openUserSettingsModal'](option);
  }

  setTabState(frame: IIFrameComponent | IWorkplaceApiSource, logEntry: any): void {
    if (!logEntry && !logEntry.title && !logEntry.url) {
      return;
    }

    if (!frame) {
      this._log.error('WorkplaceApiService -> setTabState: No source found.');
      return;
    }

    let tabId: string;
    if (this.isFrame(frame)) {
      tabId = frame.getConnectionGroupId() ? frame.getConnectionGroupId() : frame.getApp().name;
    } else {
      tabId = frame.getName();
    }

    const tabManager = this._tabService.findTabManagerForTabId(tabId);
    const selectTab = tabManager.getTabById(tabId);
    selectTab.providedTitle = logEntry.title ? logEntry.title : selectTab.providedTitle;
    selectTab.title = logEntry.title ? logEntry.title : selectTab.title;
    selectTab.path = logEntry.url;

    this.navigationComponentsService.updateHeaderTabsInput(tabManager.id, selectTab);

    this._tabService.saveSettings();
  }

  saveDashboardFavoriteLink(widgetDesc: any): ng.IPromise<boolean> {
    const deferred = this._qService.defer<boolean>();
    if (!widgetDesc) {
      deferred.reject();
    } else {
      const shell = document.querySelector('mwp-shell');
      if (!shell) return;

      shell['openCreateTileModal']({
        ...widgetDesc,
        type: WidgetDefinitions.FAVORITE,
        customSettings: JSON.parse(widgetDesc.customSettings),
      });
      deferred.resolve(true);
    }
    return deferred.promise;
  }

  /** Global deputy changed - called by external apps - eg. deputy app */

  async addAppToDashboard(
    app: IApplication,
    dashboardName: string,
    dashboardParentName?: string,
    categoryId?: string,
    definitionId?: string
  ): Promise<IDashboard> {
    const widgetDesc = {
      widgetDefinitionId: definitionId ?? WidgetDefinitions.FAVORITE,
      colspan: app.colspan || 1,
      rowspan: app.rowspan || 1,
      hidden: false,
      customSettings: {
        app: app.id,
        url: app?.url ?? '',
      },
      widgetContextMenuOpen: false,
    };

    if (app.iconUrl && app.iconUrl.trim()) {
      widgetDesc.customSettings['backgroundImageUrl'] = app.iconUrl;
    }

    if (app.iconCls && app.iconCls.trim()) {
      widgetDesc.customSettings['iconCls'] = app.iconCls;
    }

    /** check for dashboard and parentDashboard, if no matches throw error */
    let dash = dashboardName ? await this._dashboardService.getDashboard(dashboardName) : null;
    if (!dash) {
      const parentDashboard = dashboardParentName
        ? await this._dashboardService.getDashboard(dashboardParentName)
        : null;
      if (!parentDashboard) {
        throw {
          type: 'dashboards',
          message: 'No matching dashboard found',
        };
      }
      dash = parentDashboard;
    }

    try {
      /** Create favorite link to last category */
      return this._widgetService.createFavoriteLink(widgetDesc, dash.name, categoryId);
    } catch (reason) {
      throw {
        type: 'server',
        message: reason?.message ?? 'Unknown error.',
      };
    }
  }

  async addWidgetToDashboard(
    widget: IWidgetDescriptor<any>,
    dashboardName: string,
    categoryId: string,
    definitionId: string
  ): Promise<IDashboard> {
    const widgetDesc = {
      widgetDefinitionId: definitionId,
      colspan: widget.colspan || 1,
      rowspan: widget.rowspan || 1,
      hidden: false,
      customSettings: widget.customSettings,
      widgetContextMenuOpen: false,
    };

    try {
      await this._widgetService.createFavoriteLink(widgetDesc, dashboardName, categoryId);
      return this._dashboardService.getDashboard(dashboardName);
    } catch (reason) {
      throw {
        type: 'server',
        message: reason?.message ?? 'Unknown error.',
      };
    }
  }

  /**
   * If an app has registered the API function beforeClose, wait for the app's response before closing it.
   * @param id
   * @returns {IPromise<void>}
   */
  waitForAppClose(id: string, group?: string): ng.IPromise<void> {
    const deferred = this._qService.defer<void>();
    if (this._host.hasConnection(id, group)) {
      this._host
        .getConnection(id, group)
        .then((connection: Connection) => {
          return connection.callApi('beforeClose', id);
        })
        .then((value: boolean) => {
          if (value !== false) {
            deferred.resolve();
          } else {
            deferred.reject();
          }
        })
        .catch((reason: any) => {
          if (reason.code !== 0) {
            this._log.debug(
              `WorkplaceApiService -> waitForAppClose: Error in beforeClose for app id ${id}: ${reason.message}`
            );
          }
          deferred.resolve();
        });
    } else {
      deferred.resolve();
    }
    return deferred.promise;
  }

  /**
   * If an app has registered the API function logout, wait for the app's response before closing it.
   * @param id
   * @param group
   * @returns
   */
  waitForLogoutConnection(connection: Connection, id: string, group?: string): ng.IPromise<void> {
    const deferred = this._qService.defer<void>();

    const timer = this._timeoutService(() => {
      console.log(`WorkplaceApiService -> waitForLogout: Waited 2s for ${id} Moving on with logout.`);
      deferred.resolve();
    }, 2000);
    connection
      .callApi('logout')
      .then(() => {
        this._timeoutService.cancel(timer);
        deferred.resolve();
      })
      .catch((reason: any) => {
        if (reason.code !== 0) {
          console.log(`WorkplaceApiService -> waitForLogout: Error in logout for app id ${id}: ${reason.message}`);
        }
        this._timeoutService.cancel(timer);
        deferred.resolve();
      });
    return deferred.promise;
  }

  waitForLogout(): Promise<any> {
    const promises = _.flatten(
      Object.keys(this._connections).map((group: string) => {
        let connections = this._connections[group];
        return Object.keys(connections).map((id: string) => {
          let connection = connections[id];
          return this.waitForLogoutConnection(connection, id, group);
        });
      })
    );
    return Promise.all(promises);
  }

  /**
   * Notify an app that it is being closed. Wait for the app to perform cleanup.
   * @param id
   */
  notifyClose(id: string, group?: string): ng.IPromise<void> {
    const deferred = this._qService.defer<void>();
    if (this._host.hasConnection(id, group)) {
      this._host
        .getConnection(id, group)
        .then((connection: Connection) => {
          if (connection.port) {
            return connection.callApi('close', id).then(() => deferred.resolve());
          } else {
            deferred.resolve();
          }
          this._log.debug(
            `WorkplaceApiService -> waitForAppClose: Sorry, the frame for the app ${id} has already been destroyed.`
          );
        })
        .catch((reason: any) => {
          if (reason.code !== 0) {
            this._log.debug(
              `WorkplaceApiService -> waitForAppClose: Error in close for app id ${id}: ${reason.message}`
            );
          }
          deferred.resolve();
        });
    } else {
      deferred.resolve();
    }
    return deferred.promise;
  }

  /**
   * angular 1 is not ES5, so we need to wrap regular Promises from the workplace API
   * into angular's ng.IPromis
   *
   * @param serviceMethod - a function of the workplace.api.service. If the funtion does not exist
   *          wrapPromise will reject
   *
   * @param  ...args - the list of arguments the function will be called with
   *
   * @return a ng.IPromise
   */
  wrapPromise<T>(scope: any, method: (...args: any[]) => Promise<T>, ...args: any[]): ng.IPromise<T> {
    const deferred = this._qService.defer<T>();
    if (!method) {
      deferred.reject('No such method');
    }
    method
      .apply(scope, args)
      .then((result: T) => deferred.resolve(result))
      .catch((reason: any) => deferred.reject(reason));
    return deferred.promise;
  }

  /**
   * Make a workplace-api call on connectionId to fetch extra parameters used
   * by multilink widgets (also possible for just about every other interested part.)
   * @return {angular.IPromise<any>}
   */
  getAppParameters(connectionId: string, apiFn: string = 'getAppParameters', group?: string): ng.IPromise<any> {
    const deferred = this._qService.defer<any>();
    if (this._host.hasConnection(connectionId, group)) {
      this.getConnection(connectionId, group).then((connection: Connection) =>
        connection
          .callApi(apiFn)
          .then((result: any) => deferred.resolve(result))
          .catch((reason: any) => deferred.reject(reason))
      );
    } else {
      deferred.reject({
        message: `No connection found on ${connectionId} and group ${group}`,
      });
    }

    return deferred.promise;
  }

  /**
   * Opens a layout and waits for incoming connections
   * @param {string} id
   * @param context
   * @param widgetContext
   * @returns {angular.IPromise<string[]>}
   */
  openLayout(
    frame: IIFrameComponent,
    options: { id: string; context: any; widgetContext: any; connectIds: string[] }
  ): ng.IPromise<string[]> {
    this.logApiCall(`openLayout(${JSON.stringify(options)})`, frame);
    return this._frameLayoutService
      .openLayoutById(
        options.id,
        options.context,
        options.widgetContext,
        frame && (frame.getConnectionGroupId() ? frame.getConnectionGroupId() : frame.getId())
      )
      .then((layout: IFrameLayout) => {
        if (!layout) {
          const err = new WorkplaceApiError(new Error(`Layout with id "${options.id}" not found`), 1);
          throw err;
        }
        return this._qService.all(
          layout.frames
            .filter((frame: ILayoutFrameDescriptor) =>
              options.connectIds && options.connectIds.length ? options.connectIds.includes(frame.widget.id) : false
            )
            .map((frame: ILayoutFrameDescriptor) => {
              const deferred = this._qService.defer<string>();
              this.getConnection(frame.widget.id, options.id)
                .then(c => {
                  console.log(c);
                  deferred.resolve(frame.widget.id);
                })
                .catch((reason: any) => deferred.reject(reason));
              return deferred.promise;
            })
        );
      });
  }

  setLayoutIFrameSize(frame: IIFrameComponent, options: { width?: number | string; height?: number | string }): void {
    this.logApiCall(`setLayoutScrollPosition(${JSON.stringify(options)})`, frame);
    let opt: any = {};
    if (_.isNumber(options.width) || _.isString(options.width)) {
      opt.width = +options.width;
    }
    if (_.isNumber(options.height) || _.isString(options.height)) {
      opt.height = +options.height;
    }
    this._frameLayoutService.setLayoutIFrameSize(frame.getId(), opt);
  }

  /**
   * Opens a dashboard and waits for incoming connections
   * @param {string} id
   * @param context
   * @param widgetContext
   * @returns {angular.IPromise<string[]>}
   */
  openDashboard(
    frame: IIFrameComponent,
    options: { id: string; context: any; connectIds: string[] }
  ): ng.IPromise<string> {
    this.logApiCall(`openDashboard(${JSON.stringify(options)})`, frame);
    let connectIds = options.connectIds || [];
    return this._dashboardService
      .openDeepLinkDashboard(options.id, options.context)
      .then((dashboard: IDashboard) => {
        return this._qService
          .all(
            connectIds.map((widgetId: string) => {
              const deferred = this._qService.defer<string>();
              this.getConnection(widgetId, dashboard.name)
                .then(() => deferred.resolve(widgetId))
                .catch((reason: any) => deferred.reject(reason));
              return deferred.promise;
            })
          )
          .then(() => dashboard.name);
      })
      .catch(() => {
        throw new WorkplaceApiError(new Error(`Dashboard with id "${options.id}" not found`), 1);
      });
  }

  /**
   * Returns the current language
   * @returns {angular.IPromise<string>}
   */
  getLang(frame: IIFrameComponent): string {
    this.logApiCall(`getLang()`);
    return this._language.lang;
  }

  /**
   * Returns the current locale
   * @param {IIFrameComponent} frame
   * @returns {angular.IPromise<string>}
   */
  getLocale(frame: IIFrameComponent): string {
    this.logApiCall(`getLocale()`);
    return this._language.locale;
  }

  /**
   * ! This should have never existed, as we cannot guarantee the q-number being passed has not been tampered with.
   *   Never trust the client about identity. Be extremely careful when using this !
   *
   *  Currently used in
   *  - My Teams Widget
   *  - Smart Maintenance apps (to detect session expiration)
   *
   * Returns the current user QNumber
   * @param {IIFrameComponent} frame
   * @returns {angular.IPromise<string>}
   */
  getUserQNumber(frame: IIFrameComponent): string {
    this.logApiCall(`getUserQNumber()`);
    return this._user.userId;
  }

  /**
   * Opens the mail client for the specific platform
   */
  openMailClient(params: { recipients: string[]; subject?: string } = { recipients: [] }): void {
    const os = getClientOs();
    const separator = os === OS_TYPE.IOS || os === OS_TYPE.WINDOWS ? ';' : ',';
    let concatenatedEmails = params.recipients.reduce(
      (accumulator: string, email: string) => (email ? `${accumulator}${separator}${email}` : accumulator),
      ''
    );
    // at least one of the received participants has an email
    if (concatenatedEmails) {
      // strip of the ';' sign at the beginning
      // BEWARE: this solution with ; only works with Windows and Outlook. A complete solution
      // would require the backed to send the email. The frontend would post the message body.
      concatenatedEmails = concatenatedEmails.substring(1, concatenatedEmails.length);
      let uri = 'mailTo:' + concatenatedEmails;
      if (params.subject) {
        uri = UrlHelper.appendQueryString(uri, { subject: params.subject });
      }
      if (
        this._workplaceNativeDeviceService.isPlatformIOS() ||
        this._workplaceNativeDeviceService.isPlatformWindows()
      ) {
        this._workplaceNativeDeviceService.openWindow(uri);
      } else {
        // do not open a new browser window for mailto:
        let ifrm = document.createElement('iframe');
        ifrm.style.position = 'absolute';
        ifrm.style.visibility = 'hidden';
        ifrm.onload = () => ifrm.remove();
        // using iframe workaround as window.location.href will cancel http requests and close message channels
        ifrm.src = uri;
        document.body.appendChild(ifrm);
      }
    }
  }

  /**
   *
   * @param logEntry
   */
  createActionLog(frame: IIFrameComponent | IWorkplaceApiSource, logEntry: any): void {
    if (!frame) {
      this._log.error('WorkplaceApiService -> createActionLog: No source found.');
      return;
    }
    let { category, action, actionInfo } = logEntry;
    let source;
    if (this.isFrame(frame)) {
      let type = frame?.getFrame()?.type;
      switch (type) {
        case AppsFrameType.LAYOUT:
          source = frame.getConnectionGroupId();
          break;
        case AppsFrameType.APP:
        case AppsFrameType.DASHBOARD:
        default:
          source = frame.getName();
          break;
      }
    }
    if (!category) {
      throw new Error('Field "category" is required for action log entry');
    }
    if (!action) {
      throw new Error('Field "action" is required for action log entry');
    }
    this._actionLogService.logAction({
      source,
      category,
      action,
      actionInfo,
    });
  }

  globalDeputyChanged(frame: IIFrameComponent | IWorkplaceApiSource, deputy: IUser, enabled: boolean) {
    if (!frame) {
      this._log.error('WorkplaceApiService -> globalDeputyChanged: No source found.');
      return;
    }
    if (!this.isFrame(frame) || frame.getApp().name !== 'app-deputy') {
      return;
    }
    this._userInfoService.updateDeputy(deputy, enabled);
  }

  updateDeputy(connectionId: string, group: string, deputy: IUser, enabled: boolean) {
    if (!this.hasConnection(connectionId, group)) {
      return Promise.resolve();
    }
    return this.getConnection(connectionId, group)
      .then(connection => connection.callApi('globalDeputyChanged', deputy, enabled))
      .catch(({ message }) =>
        this._log.warn(`'globalDeputyChanged failed for connection ${connectionId} in group ${group}:`, message)
      );
  }

  public getStrongAuthServiceParam(): string | undefined {
    if (this.strongAuthService) {
      return `strongAuth${this.strongAuthService}Service`;
    }
  }

  private isFrame(frame: IWorkplaceApiSource | IIFrameComponent): frame is IIFrameComponent {
    return frame && !!(frame as IIFrameComponent).getFrame;
  }

  /**
   * Uses notification service to show the message.
   * @param type
   * @param message
   */
  private showNotification(
    frame: IIFrameComponent | IWorkplaceApiSource,
    type: string,
    message: string,
    duration?: number
  ): void {
    this.logApiCall(`showMessage('${type}', '${message}'${duration ? ', ' + duration : ''})`, frame);
    let tab = this._tabService.getTabById(frame.getId());
    let title = tab && tab.title ? tab.title : frame.getDescription();
    this._notificationService.showMessage(type, message, null, title, null, { timeOut: duration });
  }

  /**
   *
   * @param tabId
   */
  private splitView(tabId: string, openerTab: string): void {
    if (this._appsService.isExternalWindow(tabId) || this._appsService.isExternalWindow(openerTab)) {
      return;
    }
    const openerTabviewId = this._tabService.findTabManagerForTabId(openerTab).id;
    if (this._layoutService.isSplitLayout()) {
      const layout = this._layoutService.findOppositeLayout(openerTabviewId);
      if (layout) {
        this._tabService.moveTabToIndex(tabId, 0, <string>layout.id);
      }
    } else {
      this._tabService.splitTabview({
        tabviewId: openerTabviewId,
        tabId,
        direction: 'row',
        index: 1,
      });
    }
  }

  private getAppLinksForBo(boType: string, boId: string = ''): ng.IPromise<any> {
    const deferred = this._qService.defer();
    const CHAR_LIMIT = 512;
    if (!boId.length || boId.length > CHAR_LIMIT) {
      deferred.reject({
        message: `Parameter boId must be between 0 and ${CHAR_LIMIT} characters`,
      });
    }
    this._userInfoService.getUser().then((user: IUser) => {
      this._httpService
        .get('rest/workplace/businessObjectsApps', {
          params: {
            boType,
            boId,
            deviceType: this._deviceService.device,
            lang: this._language.lang,
            demoMode: this._workplaceContextService.demoMode,
            businessRoles:
              user.getSelectedRole() && user.getSelectedRole() !== null ? [user.getSelectedRole().roleId] : [],
          },
        })
        .then((result: any) => deferred.resolve(result))
        .catch((reason: any) => deferred.reject(reason));
    });
    return deferred.promise;
  }

  private handleNextOneDeepLink(config: {
    action: string;
    title?: string;
    openerId: string;
    boType?: string;
    boNumber?: string;
    splitView: boolean;
  }): ng.IPromise<string> {
    return this._appsService
      .openAppByName({
        name: APP_TASKS_MANAGER,
        queryParams: {
          action: config.action,
          boType: config.boType,
          boNumber: config.boNumber,
          title: config.title,
        },
      })
      .then((id: string) => {
        if (config.splitView) {
          this.splitView(id, config.openerId);
        }
        return id;
      });
  }

  private getTaskDeepLink(task: IWorkplaceTask, justPath: boolean = false): ng.IPromise<string> {
    let deferred = this._qService.defer<string>();
    deferred.resolve(this._tasksService.getWorkplaceTaskDeepLink(task, justPath));
    return deferred.promise;
  }

  private getDashboardDeepLink(name: string): ng.IPromise<string> {
    let deferred = this._qService.defer<string>();
    deferred.resolve(`${window.location.origin}${window.location.pathname}#/dashboard/${name}`);
    return deferred.promise;
  }

  /**
   * Log api calls
   * @param fnWithArgs
   * @param frame
   */
  private logApiCall(fnWithArgs: string, frame?: IWorkplaceApiSource): void {
    if (frame) {
      this._log.log(`WorkplaceApiService: "${frame.getName()}" (${frame.getId()}) called API function: ${fnWithArgs}`);
    }
  }

  /**
   * Calls 'roleChanges' through workplace-api on apps opened in external windows
   */
  private notifyExternalApps(message: IUserServiceMessage): void {
    const selectedRole: Role = message.selectedRole;
    const ids = this._appsService.getExternalWindowIds();
    if (!ids || ids === null) {
      return;
    }
    ids.forEach((id: string) => {
      this.getConnection(id).then((connection: Connection) => {
        if (selectedRole && selectedRole !== null) {
          connection
            .callApi(
              'roleChanged',
              selectedRole.roleId,
              selectedRole.substitutedUser && selectedRole.substitutedUser !== null
                ? selectedRole.substitutedUser.userId
                : ''
            )
            .catch((reason: any) =>
              this._log.info('roleChanged through workplace api failed on: ' + id + ' because: ' + reason.message)
            );
        }
      });
    });
  }

  /**
   * Adds a native device event. Possible events are deviceready, pause, resume
   * @param eventName
   * @param callbackName
   */
  private nativeDeviceAddEventListener(
    frame: IIFrameComponent,
    connection: Connection,
    eventName: string,
    callbackName: string
  ): void {
    this.logApiCall(`nativeDeviceAddEventListener(${eventName})`, frame);
    const handler = () => {
      connection.callApi(callbackName, eventName).catch((reason: any) => {
        this._log.log('workplace.api.service -> : ' + reason.message);
      });
    };
    connection.registerApi('nativeDeviceRemoveEventListener', () => {
      this._workplaceNativeDeviceService.removeNativeEventListener(eventName, handler);
    });
    this._workplaceNativeDeviceService.addNativeEventListener(eventName, handler);
  }

  private addConnection(connection: Connection, id: string, group?: string): void {
    if (!group) {
      group = CONNECTION_GROUP_DEFAULT;
    }
    if (!this._connections[group]) {
      this._connections[group] = {};
    }
    this._connections[group][id] = connection;
  }

  private removeConnection(id: string, group?: string): void {
    if (!group) {
      group = CONNECTION_GROUP_DEFAULT;
    }
    this._connections[group][id] = null;
    delete this._connections[group][id];
  }

  /**
   * Open window on native devices
   * @param {IIFrameComponent} frame
   * @param {string} url
   * @param {string} target
   * @param {string} options
   */
  private openWindow(frame: IIFrameComponent, url: string, target: string, options: string, browserType: string): void {
    this.logApiCall(`openWindow(${url},${target},${options}, ${browserType})`, frame);
    this._workplaceNativeDeviceService.openWindow(url, target, options, browserType);
  }

  /**
   * Open InAppBrowser on native devices
   * @param {IIFrameComponent} frame
   * @param {string} url
   * @param {string} target
   * @param {string} options
   */
  private openInAppBrowser(frame: IIFrameComponent, url: string): void {
    this.logApiCall(`openInAppBrowser(${url})`, frame);
    this._workplaceNativeDeviceService.openInAppBrowser(url);
  }

  /**
   * Broadcast updateTasks event
   * @param frame
   */
  private updateTasks(frame: IIFrameComponent): void {
    this.logApiCall(`updateTasks()`, frame);
    this._tasksService.updateTasks();
  }

  private preconditionError(frame: IIFrameComponent): void {
    const message = this._translateService.instant('error.task.precondition.failed');
    this.logApiCall(`showMessage(error, '${message}')`, frame);
    this._notificationService.showMessage('error', message, null, frame.getDescription(), null);
  }

  /**
   *
   * @param frame
   */
  private updateDynamicTile(frame: IIFrameComponent): void {
    this.logApiCall(`updateDynamicTile()`, frame);
    if (frame && frame.getApp()) {
      this._widgetService.publishDynamicTileUpdate(frame.getApp());
    }
  }
}
