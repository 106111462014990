import { Component } from '../components/component';
import { IFrameLayout, ILayoutFrameDescriptor } from './frame-layout.model.interface';
import { FrameLayoutService } from './frame-layout.service';
import { ITabServiceChannelTabMessage, TabService } from '../tab/tab.service';
import { AppsFrameType, IAppsFrame } from '../apps/apps.frame.interface';
import { WorkplaceApiService } from '../workplace/workplace.api.service';
import { UrlHelper } from '../../util/url.helper';
import { WorkplaceContextService } from '../workplace/workplace.context.service';
import { LayoutService } from '../layout/layout.service';
import { IFrameLayoutAdapter } from './frame-layout.adapters';
import { UserService } from '../user/user.service';
import { IUser } from '../user/user.model.interface';
import { SimpleXAxisAdapter } from './frame-layout-xaxis.adapter';
import { SimpleYAxisAdapter } from './frame-layout-yaxis.adapter';
import { IWorkplaceContext } from '../workplace/workplace.context.interface';
import { CrossSplitAdapter } from './frame-layout-cross-split.adapter';
import { TabManager } from '../tab/tab.manager';
import { AppsService } from '../apps/apps.service';
import { NotificationService } from '../notification/notification.service';
import _ from 'lodash';
import { AnnouncementService } from '../announcements/announcements.service';

export class FrameLayout extends Component {
  static VERTICAL_SPLITTER_CLASS: string = 'verticalSplitter';
  static HORIZONTAL_SPLITTER_CLASS: string = 'horizontalSplitter';

  layout: IFrameLayout;
  contextParams: { [key: string]: string | string[] };
  widgetContext: { widgetId: string; path: string; params: any; hash: string };
  frames: IAppsFrame[];
  restricted: boolean;

  private frameLayoutService: FrameLayoutService;
  private tabService: TabService;
  private subscriptions: ISubscriptionDefinition<any>[];
  private parentEl: JQuery;
  private workplaceApiService: WorkplaceApiService;
  private workplaceContextService: WorkplaceContextService;
  private qService: ng.IQService;
  private layoutService: LayoutService;
  private timeoutService: ng.ITimeoutService;
  private userService: UserService;
  private scope: ng.IScope;
  private fullScreenFrame: IAppsFrame;
  private isSplitterDragging: boolean;
  private adapter: IFrameLayoutAdapter;
  private throttleResize: () => void;

  /**
   * @ngInject
   */
  constructor(
    frameLayoutService: FrameLayoutService,
    tabService: TabService,
    workplaceApiService: WorkplaceApiService,
    workplaceContextService: WorkplaceContextService,
    layoutService: LayoutService,
    userService: UserService,
    $timeout: ng.ITimeoutService,
    $q: ng.IQService,
    private appsService: AppsService,
    private notificationService: NotificationService,
    private announcementsService: AnnouncementService,
    private $log: ng.ILogService
  ) {
    super();
    this.frameLayoutService = frameLayoutService;
    this.tabService = tabService;
    this.workplaceApiService = workplaceApiService;
    this.workplaceContextService = workplaceContextService;
    this.layoutService = layoutService;
    this.timeoutService = $timeout;
    this.userService = userService;
    this.qService = $q;
    this.frames = [];
    this.isSplitterDragging = false;
    this.restricted = false;
    this.fullScreenFrame = null;
    this.throttleResize = _.throttle(this._throttleResize.bind(this), 5, {
      trailing: true,
      leading: false,
    }).bind(this);
  }

  onRenderComponent(el: JQuery, scope: ng.IScope): void {
    this.parentEl = el;
    this.scope = scope;

    this.announcementsService.setupAnnouncementsBanners(this.layout.id, this.layout.id);

    this.subscriptions = [];
    this.subscriptions.push(
      this.tabService.channel.subscribe(TabService.TOPIC_TAB_SELECT, this.updateFramesVisibility.bind(this))
    );
    this.subscriptions.push(
      this.tabService.channel.subscribe(TabService.TOPIC_TAB_REFRESH, (message: ITabServiceChannelTabMessage) => {
        if (message.tabId === this.layout.id) {
          // reset state
          this.fullScreenFrame = null;
          this.adapter.reset();
          this.buildFrames(scope);
        }
      })
    );
    this.subscriptions.push(
      this.frameLayoutService.channel.subscribe(
        FrameLayoutService.SHOW_FULLSCREEN_FRAME,
        this.showFullScreenFrame.bind(this)
      )
    );
    this.subscriptions.push(
      this.frameLayoutService.channel.subscribe(FrameLayoutService.SET_IFRAME_SIZE, this.setIFrameSize.bind(this))
    );
    this.subscriptions.push(
      this.userService.channel.subscribe(UserService.TOPIC_SELECTED_ROLE_CHANGED, () => (this.fullScreenFrame = null))
    );
    this.subscriptions.push(
      this.frameLayoutService.channel.subscribe(
        FrameLayoutService.TOPIC_FRAME_LAYOUT_CONTEXT_UPDATE,
        (message: any) => {
          if (message.layout.id !== this.layout.id) {
            return;
          }
          this.frames.forEach((frame: IAppsFrame) => {
            let f = this.layout.frames.find(
              (frameDescriptor: ILayoutFrameDescriptor) => frameDescriptor.widget.id === frame.id
            );
            // not the widget we want to update the context for
            if (
              !f ||
              !f.widget ||
              (message.widgetContext &&
                message.widgetContext.widgetId &&
                f.widget.id !== message.widgetContext.widgetId)
            ) {
              return;
            }
            let widgetSettings = f && f.widget && f.widget.customSettings ? JSON.parse(f.widget.customSettings) : null;
            if (!widgetSettings || widgetSettings === null) {
              return;
            }
            this.workplaceContextService
              .getAppContext(...f.widget.dataUrlContextParams)
              .then(
                (context: any) =>
                  (frame.app.url = this.createURLForIFrameWidget(
                    f.widget.id,
                    f.widget.dataUrl,
                    context,
                    message.context,
                    message.widgetContext
                  ))
              );
          });
        }
      )
    );
    this.subscriptions.push(
      this.layoutService.channelUpdate.subscribe(
        LayoutService.TOPIC_UPDATE,
        this.timeoutService.bind(this.timeoutService, () =>
          this.adapter.resizeHandler(this.frames, this.fullScreenFrame)
        )
      )
    );

    switch (this.layout.type) {
      case 0:
        this.adapter = new SimpleXAxisAdapter(el, this.frameLayoutService, this.layout, this.qService);
        break;
      case 1:
        this.adapter = new SimpleYAxisAdapter(el, this.frameLayoutService, this.layout, this.qService);
        break;
      case 2:
        this.adapter = new CrossSplitAdapter(el, this.frameLayoutService, this.layout, this.qService);
        break;
      default:
        this.adapter = new SimpleXAxisAdapter(el, this.frameLayoutService, this.layout, this.qService);
        break;
    }
    this.buildFrames(scope);

    window.addEventListener('resize', this.throttleResize);
  }

  destroy(): void {
    this.subscriptions.forEach((sub: ISubscriptionDefinition<any>) => sub.unsubscribe());
    this.frameLayoutService = null;
    this.tabService = null;
    this.workplaceApiService = null;
    this.workplaceContextService = null;
    this.userService = null;
    this.timeoutService = null;
    this.qService = null;
    this.announcementsService.clearAnnouncementsSubscriptions(this.layout.id);
    window.removeEventListener('resize', this.throttleResize);
  }

  /**
   * Build the layout frames, taking possible cached layouts into consideration
   */
  private buildFrames(scope: ng.IScope): void {
    this.userService
      .getUser()
      .then(
        (user: IUser) =>
          (this.restricted =
            this.layout.strongAuth && (!user.authLevel || (user.authLevel && this.layout.strongAuth > user.authLevel)))
      );

    this.layout = this.frameLayoutService.getSyncedLayout(this.layout);
    if (!this.layout || !this.layout.frames || this.layout.frames === null) {
      return;
    }

    /**
     * Build the frames.
     * Do the styling preparation first
     */
    const promises: ng.IPromise<IAppsFrame>[] = [];
    const layoutTab = this.tabService.getTabById(this.layout.id);
    const layoutTabActive = layoutTab ? layoutTab.active : false;
    this.layout.frames.sort((f1: ILayoutFrameDescriptor, f2: ILayoutFrameDescriptor) => f1.sortPos - f2.sortPos);
    this.adapter.buildFrameStyles();
    this.layout.frames.forEach((f: ILayoutFrameDescriptor, idx: number) =>
      promises.push(this.createFrame(f, idx, layoutTabActive))
    );
    Promise.all(promises)
      .then((frames: IAppsFrame[]) => scope.$apply(() => (this.frames = frames)))
      .catch((e: any) => {
        this.$log.error(e.message);
      });
  }

  /**
   * Creates an IAppsFrame object on top of which the actual iframes are built
   * @param {ILayoutFrameDescriptor} f
   * @param idx - the index of the current frame
   * @param visible - should the frame be visible
   * @return {angular.IPromise<IAppsFrame>}
   */
  private createFrame(f: ILayoutFrameDescriptor, idx: number, visible: boolean): ng.IPromise<IAppsFrame> {
    if (!f.widget || f.widget === null) {
      return;
    }

    let widgetSettings: any = f.widget.customSettings ? JSON.parse(f.widget.customSettings) : null;
    if (!widgetSettings || widgetSettings === null) {
      return;
    }

    if (f.widget.defaultSettings) {
      let defSettings = _.isString(f.widget.defaultSettings)
        ? JSON.parse(f.widget.defaultSettings)
        : f.widget.defaultSettings;
      widgetSettings = _.merge({}, defSettings, widgetSettings);
    }

    return this.workplaceContextService.getAppContext(...f.widget.dataUrlContextParams).then((context: any) => {
      let authFlowUrl = widgetSettings.authFlowUrl ? widgetSettings.authFlowUrl : null;
      return this.appsService
        .openAppAuthFlow({
          name: f.widget.id,
          description: f.widget.title,
          iconCls: f.widget.iconCls,
          authFlowUrl,
          startMode: authFlowUrl ? 'A' : null,
        })
        .then(() => {
          const url = this.createURLForIFrameWidget(
            f.widget.id,
            f.widget.dataUrl,
            context,
            this.contextParams,
            this.widgetContext
          );
          return {
            id: f.widget.id,
            connectionGroupId: this.layout.id,
            app: {
              name: widgetSettings.title,
              iconCls: null,
              description: null,
              url,
            },
            visible,
            relativePosition: true,
            boundingBox:
              this.adapter && this.adapter.boundingBoxes && this.adapter.boundingBoxes.length >= idx
                ? this.adapter.boundingBoxes[idx]
                : {},
            type: AppsFrameType.LAYOUT,
          };
        })
        .catch((e: any) => {
          this.notificationService.showError(e, null, f.widget.title);
          throw new Error('Auth flow failed for ' + f.widget.id);
        });
    });
  }

  /**
   * Creates an URL for the given widget descriptor.
   * Either the layout context or the widget context are being applied.
   *
   * @param layoutContext
   * @param widgetContext
   * @returns {string}
   */
  private createURLForIFrameWidget(
    widgetId: string,
    dataUrl: string,
    workplaceContext: IWorkplaceContext,
    layoutContext?: { [key: string]: string | string[] },
    widgetContext?: { widgetId: string; path: string; params: any; hash: string }
  ): string {
    if (widgetContext && widgetId === widgetContext.widgetId) {
      let url = dataUrl;
      console.log('frame-layout -> createURLForIFrameWidget: ', widgetContext);
      if (widgetContext.path) {
        url = (UrlHelper.getOriginFromUrl(url) || '/') + widgetContext.path;
      }
      url = UrlHelper.appendQueryString(url, Object.assign({}, workplaceContext, layoutContext, widgetContext.params));
      if (widgetContext.hash) {
        url = UrlHelper.replaceHash(url, widgetContext.hash);
      }
      return url;
    }
    return UrlHelper.appendQueryString(dataUrl, Object.assign({}, workplaceContext, layoutContext));
  }

  /**
   * Toggles / untoggles full screen dimensions for given frames.
   * @param message
   */
  private showFullScreenFrame(message: any): void {
    if (this.layout.id !== message.layoutId) {
      return;
    }

    if (message.toggle) {
      this.frames.forEach((f: IAppsFrame) => {
        /**
         * Maximize the requesting frame sizes
         */
        if (f.id === message.widgetId) {
          f.boundingBox = this.adapter.fullScreenStyle;
          this.fullScreenFrame = f;
          return;
        }
        /**
         * Hide the other frames
         */
        f.visible = false;
      });
    } else {
      let frm: ILayoutFrameDescriptor;
      this.fullScreenFrame = null;
      this.frames.forEach((f: IAppsFrame, idx: number) => {
        frm = this.layout.frames.find((desc: ILayoutFrameDescriptor) => desc.widget.id === f.id);
        if (frm) {
          f.boundingBox =
            this.adapter && this.adapter.boundingBoxes && this.adapter.boundingBoxes.length >= idx
              ? this.adapter.boundingBoxes[idx]
              : {};
        }
        f.visible = true;
      });
    }
    this.scope.$apply();
  }

  private setIFrameSize(message: {
    layoutId: string;
    widgetId: string;
    options: { width?: number; height?: number };
  }): void {
    if (this.layout.id !== message.layoutId) {
      return;
    }
    if (!message.options) {
      console.log(`Invalid message.options`);
      return;
    }
    // make sure the splitter is visible
    this.fullScreenFrame = null;
    const sourceFrameIdx: number = this.layout.frames.findIndex(
      (f: ILayoutFrameDescriptor) => f.widget.id === message.widgetId
    );
    if (sourceFrameIdx === -1) {
      console.log(`There is no iFrame with id ${message.widgetId} on this layout`);
      return;
    }
    this.frames.forEach((f: IAppsFrame) => (f.visible = false));
    this.scope.$apply();
    this.adapter
      .resizeIFrames(this.frames, message.options, sourceFrameIdx)
      .then(() => this.frames.forEach((f: IAppsFrame) => (f.visible = true)));
  }

  private updateFramesVisibility(message: ITabServiceChannelTabMessage): void {
    const tM: TabManager = this.tabService.findTabManagerForTabId(this.layout.id);
    // we're in split view and the selected tab is not on the current view. So we gracefully do nothing.
    if (tM && tM.id !== message.tabviewId) {
      return;
    }
    if (message.tabId !== this.layout.id) {
      this.frames.forEach((f: IAppsFrame) => (f.visible = false));
      return;
    }

    if (this.fullScreenFrame !== null) {
      this.fullScreenFrame.visible = true;
      return;
    }

    this.frames.forEach((f: IAppsFrame) => (f.visible = true));
    return;
  }

  private _throttleResize(): void {
    this.adapter.resizeHandler(this.frames, this.fullScreenFrame);
  }
}
