import { Node } from '../../util/tree/node';
import _ from 'lodash';

/**
 * Model class for layout
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class Layout extends Node {
  index: number;
  direction: string;
  dimensions: { top: number; left: number; width: number; height: number };
  aspectRatio: number[];

  /**
   * @ngInject
   * @param config
   */
  constructor(config?: {
    id: string | number;
    index?: number;
    direction?: string;
    dimensions?: { top: number; left: number; width: number; height: number };
  }) {
    super(
      Object.assign(
        {},
        {
          dimensions: {
            top: 0,
            left: 0,
            width: 1,
            height: 1,
          },
          aspectRatio: [1, 1],
        },
        config
      )
    );
  }

  /**
   * Splits a layout according to its aspect ratio
   * @param newId
   * @param direction
   * @param index
   * @param aspectRatio - @optional
   */
  split(newId: string, direction: string, index: number, aspectRatio?: number[]): void {
    if (!this.parent) {
      throw new Error('Node -> split: Cannot call split on root node.');
    }
    const layout = new Layout({
      id: _.uniqueId('layout-'),
      index,
      direction,
      dimensions: Object.assign({}, this.dimensions),
    });
    const split = new Layout({
      id: newId,
      dimensions: Object.assign({}, layout.dimensions),
    });
    this.parent.replaceChild(this, layout);
    layout.addChild(this);
    layout.addChild(split, index);
    this.splitDimensionsInDirection(direction, +!index, aspectRatio || (<Layout>this.parent).aspectRatio);
    split.splitDimensionsInDirection(direction, index, aspectRatio || (<Layout>this.parent).aspectRatio);
  }

  /**
   * Remove the layout
   */
  remove(): void {
    if (!this.parent) {
      throw new Error('Node -> remove: Cannot call remove on root node.');
    }
    var parent = <Layout>this.parent;
    parent.removeChild(this);
    if (parent.children.length === 1) {
      var child = <Layout>parent.children[0];
      if (parent.parent) {
        parent.parent.replaceChild(parent, parent.children[0]);
      }
      child.walkDepthFirstPre((layout: Layout) => {
        layout.updateDimensions();
      });
    } else if (parent.parent) {
      parent.remove();
    }
    this.destroy();
  }

  /**
   * Split dimensions into given direction.
   *
   * @param direction
   * @param index
   * @param aspectRatio
   */
  splitDimensionsInDirection(direction: string, index: number, aspectRatio?: number[]): void {
    if (direction === 'row') {
      this.splitDimensionsVertically(index, aspectRatio);
    } else {
      this.splitDimensionsHorizontally(index, aspectRatio);
    }
  }

  /**
   * Split the current dimensions vertically according to the aspect ratio and index
   * @param aspectRatio
   * @param index
   */
  splitDimensionsVertically(index: number, aspectRatio: number[] = [1, 1]): void {
    const width = +((this.dimensions.width * aspectRatio[index]) / _.sum(aspectRatio)).toFixed(2);
    this.dimensions = {
      top: this.dimensions.top,
      height: this.dimensions.height,
      width: +width.toFixed(2),
      left: index === 0 ? this.dimensions.left : +(this.dimensions.left + (this.dimensions.width - width)).toFixed(2),
    };
  }

  /**
   * Split the current dimensions horizontally according to the aspect ratio and index
   * @param aspectRatio
   * @param index
   */
  splitDimensionsHorizontally(index: number, aspectRatio: number[] = [1, 1]): void {
    const height = (this.dimensions.height * aspectRatio[index]) / _.sum(aspectRatio);
    this.dimensions = {
      top: index === 0 ? this.dimensions.top : +(this.dimensions.top + (this.dimensions.height - height)).toFixed(2),
      height: +height.toFixed(2),
      width: this.dimensions.width,
      left: this.dimensions.left,
    };
  }

  /**
   * Update dimensions according to parent
   */
  updateDimensions(aspectRatio?: number[]): void {
    const parent = <Layout>this.parent;
    this.dimensions = _.clone(parent.dimensions);
    if (parent.direction) {
      this.splitDimensionsInDirection(
        parent.direction,
        parent.children.indexOf(this),
        aspectRatio || parent.aspectRatio
      );
    }
  }

  /**
   * Clone the layout node
   */
  clone(): Layout {
    const clone = <Layout>super.clone(Layout);
    clone.dimensions = _.clone(this.dimensions);
    clone.direction = this.direction;
    clone.index = this.index;
    clone.aspectRatio = _.clone(this.aspectRatio);
    return clone;
  }

  /**
   * Returns whether the layout contains a split layout
   */
  containsSplit(): boolean {
    let split = false;
    this.walkDepthFirstPre((node: Layout) => (split = split || typeof node.direction !== 'undefined'), true);
    return split;
  }

  /**
   * Checks for the first inner layout that is in split screen and returns his direction, if none is in split screen returns 'full' canvas value.
   */
  firstSplitChildDirection(): string {
    let direction: string;
    this.walkDepthFirstPre((node: Layout) => (direction = node.direction), true);

    if (!direction) return 'full';

    return direction;
  }
}
