import { Component } from '../components/component';
import { AppsFrameType, IAppsFrame } from './apps.frame.interface';
import { WorkplaceApiService } from '../workplace/workplace.api.service';
import { IIFrameComponent } from '../components/iframe-component';
import { IApplication } from './application.model.interface';
import { ITabServiceChannelTabMessage, TabService } from '../tab/tab.service';
import { AppsService } from './apps.service';
import { Role } from '../user/role.model';
import { User } from '../user/user.model';
import { IUserServiceDeputyUpdatedMessage, UserService } from '../user/user.service';
import { IDndCoordinates } from '../appBehavior/interframe-drag-manager.interface';
import {
  IInterframeDragManagerServiceMessage,
  InterframeDragService,
} from '../appBehavior/interframe-drag-manager.service';
import { IframeDragToWorkplaceService } from '../appBehavior/iframe-drag-to-workplace.service';
import { AuthService } from '../auth/AuthService';
import { Connection } from '@myworkplace/api';
import angular from 'angular';
import _ from 'lodash';
import { IUserSettingsStoreable } from '../workplace/user-settings-storeable.interface';
import { AnnouncementService } from '../announcements/announcements.service';

/**
 * Component to render a frame for an application.
 * The frames are created in a separate layer to avoid iframe reloading, therefore the frames should not be moved within the dom.
 * The components are positioned absolute and only their bounding boxes and visibility is being updated.
 */
export class AppsFrameComponent extends Component implements IIFrameComponent, IUserSettingsStoreable {
  /**
   * Set by attribute
   */
  frame: IAppsFrame;

  /**
   * Trigger loading state
   */
  loading: boolean = false;

  settingsStorageKey = 'app';

  private el: JQuery;

  private ifrm: JQuery;
  private _workplaceApiService: WorkplaceApiService;
  private _tabService: TabService;
  private _appsService: AppsService;
  private _userService: UserService;
  private _subscriptions: ISubscriptionDefinition<any>[];
  private _logService: ng.ILogService;
  private _interframeDragService: InterframeDragService;
  private _iframeDragToWorkplaceService: IframeDragToWorkplaceService;
  private _dndMask: boolean = false;
  private _scope: ng.IScope;
  private _hiddenStyle: any;
  private headerTabsEl: Element & { [key: string]: any };

  /**
   * @ngInject
   */
  constructor(
    workplaceApiService: WorkplaceApiService,
    tabService: TabService,
    appsService: AppsService,
    userService: UserService,
    $log: ng.ILogService,
    interframeDragService: InterframeDragService,
    iframeDragToWorkplaceService: IframeDragToWorkplaceService,
    private authService: AuthService,
    private announcementsService: AnnouncementService
  ) {
    super();
    this._workplaceApiService = workplaceApiService;
    this._tabService = tabService;
    this._appsService = appsService;
    this._userService = userService;
    this.createConnection();
    this._subscriptions = [];
    this._logService = $log;
    this._interframeDragService = interframeDragService;
    this._iframeDragToWorkplaceService = iframeDragToWorkplaceService;
  }

  $onInit() {
    this._subscriptions.push(
      this._userService.channel.subscribe(
        UserService.TOPIC_SELECTED_ROLE_CHANGED,
        this.notifyAppsOnRoleChange.bind(this)
      )
    );
    this._subscriptions.push(
      this._interframeDragService.channel.subscribe(
        InterframeDragService.IFRAME_DND_START,
        (message: IInterframeDragManagerServiceMessage) => {
          if (this.getId() !== message.targetFrameId) {
            if (this._workplaceApiService.hasConnection(this.getId())) {
              this._workplaceApiService.getConnection(this.getId()).then((connection: Connection) => {
                connection.callApi('setDragObject', message.dragObject).catch((reason: any) => {
                  if (reason.code === 0) {
                    console.log(
                      `apps.frame.component -> subscription IFRAME_DND_START: frame "${this.getId()}" does not implement setDragObject.`
                    );
                  } else {
                    console.log(
                      `apps.frame.component -> subscription IFRAME_DND_START: [error from frame "${this.getId()}"] ${
                        reason.message
                      }`
                    );
                  }
                  this._dndMask = true;
                  this._scope.$apply();
                });
              });
            } else {
              this._dndMask = true;
              this._scope.$apply();
            }
          }
        }
      )
    );

    this._subscriptions.push(
      this._interframeDragService.channel.subscribe(InterframeDragService.IFRAME_DND_END, () => {
        this._workplaceApiService
          .getConnection(this.getId())
          .then((connection: Connection) =>
            connection
              .callApi('dragEnded')
              .catch((reason: any) => console.log('apps.frame.component -> dragEnd error', reason.message))
          );
        this._dndMask = false;
        this._scope.$apply();
      })
    );

    this._subscriptions.push(
      this._iframeDragToWorkplaceService.channel.subscribe(
        IframeDragToWorkplaceService.IFRAME_DND_TO_WORKPLACE_START,
        (message: IInterframeDragManagerServiceMessage) => {
          this._dndMask = true;
          this._scope.$apply();
        }
      )
    );
    this._subscriptions.push(
      this._iframeDragToWorkplaceService.channel.subscribe(
        IframeDragToWorkplaceService.IFRAME_DND_TO_WORKPLACE_END,
        (message: IInterframeDragManagerServiceMessage) => {
          this._dndMask = false;
          this._scope.$apply();
        }
      )
    );
    this._subscriptions.push(
      this._userService.channelDeputyUpdated.subscribe(UserService.DEPUTY_UPDATED, this._updateDeputy.bind(this))
    );
  }

  getSettingsStoragePath(): string[] {
    return [this.getName()];
  }

  getUrl(): string {
    return this.frame ? this.frame.app.url : null;
  }

  getId(): string {
    return this.frame ? this.frame.id : null;
  }

  getName(): string {
    return this.frame ? this.frame.app.name : null;
  }

  getDescription(): string {
    return this.frame ? this.frame.app.description : null;
  }

  getApp(): IApplication {
    return this.frame ? this.frame.app : null;
  }

  getIframePosition(): IDndCoordinates {
    if (this.frame.relativePosition) {
      return {
        x: +this.frame.boundingBox.left - 10,
        y: +this.frame.boundingBox.top - 40, // consider the widgets header bar
      };
    }
    return {
      x: _.isString(this.frame.boundingBox.left)
        ? Math.floor((this.el.parent()[0].offsetWidth * _.parseInt(this.frame.boundingBox.left)) / 100)
        : 0,
      y: _.isString(this.frame.boundingBox.top)
        ? Math.floor((this.el.parent()[0].offsetHeight * _.parseInt(this.frame.boundingBox.top)) / 100)
        : 0,
    };
  }

  getConnectionGroupId(): string {
    return this.frame.connectionGroupId;
  }

  getFrame(): IAppsFrame {
    return this.frame;
  }

  /**
   * Returns the style attributes for the current frame
   */
  get style(): any {
    if (!this.frame.visible && this._hiddenStyle) {
      // return cached style so we have no calculations and updates for hidden frames
      return this._hiddenStyle;
    }

    const parentBB = this.el.offsetParent().get(0).getBoundingClientRect();
    const frameBB = this.frame.boundingBox;

    // Some layouts do some calculations in the hidden frame so the iframe needs to stay in the DOM, because of that we can't use display: none
    this._hiddenStyle = {
      position: 'absolute',
      visibility: 'hidden',
      left: -100000,
      top: -100000,
      right: 'auto',
      bottom: 'auto',
      width: this.valueZeroOrAuto(frameBB.width) ? parentBB.width : frameBB.width,
      height: this.valueZeroOrAuto(frameBB.height) ? parentBB.height : frameBB.height,
    };

    if (this.frame.visible) {
      const offset = this.frame.relativePosition
        ? {
            left: 0,
            top: Math.ceil(+this.frame.boundingBox.top - parentBB.top),
          }
        : {};

      return {
        ...frameBB,
        visibility: 'visible',
        height: this.valueZeroOrAuto(frameBB.height)
          ? 'auto'
          : frameBB.height.toString().indexOf('%') !== -1
          ? frameBB.height
          : Math.ceil(+frameBB.height),
        width: this.valueZeroOrAuto(frameBB.width)
          ? 'auto'
          : frameBB.width.toString().indexOf('%') !== -1
          ? frameBB.width
          : Math.ceil(+frameBB.width),
        ...offset,
      };
    }
    return this._hiddenStyle;
  }

  get iframeStyle(): any {
    /**
     * workaround a bug in masonry, that wrongly layers unloaded iframes.
     * if you remove the -10000 / -10000 part belonging to the hidden state,
     * you will get surprises regarding the iframes loaded content position in the page.
     */
    if (this.loading) {
      return {
        visibility: 'hidden',
        left: -100000,
        top: -100000,
        right: 'auto',
        bottom: 'auto',
      };
    }

    return {
      visibility: 'visible',
    };
  }

  get dndMask(): boolean {
    return this._dndMask;
  }

  /**
   * IFrame content has been loaded.
   */
  handleFrameContentLoaded(): void {
    this.loading = false;
    this._scope.$apply();
  }

  /**
   * Shows a loading mask and hides it after the content of the iframe is loaded.
   * @param el
   * @param $scope
   */
  onRenderComponent(el: JQuery, $scope: ng.IScope): void {
    this.el = el;
    this.ifrm = el.find('iframe');
    this._scope = $scope;
    this.createConnection();
    this.loading = true;

    if (angular.isUndefined(this.ifrm)) {
      return;
    }

    if (this.frame.type === AppsFrameType.APP) {
      this.announcementsService.setupAnnouncementsBanners(this.getId(), this.getName());
    }

    (<HTMLIFrameElement>this.ifrm.get(0)).contentWindow.name = `myworkplace-${this.getId()}`;

    // for now we only check whether we have already retrieved a wen cookie. Maybe in future we check if we have a valid session
    this.authService.checkWenCookie().then(() => {
      // this is actually setting the iframe#src and loading the application/widget
      $scope.$watch('vm.frame.app.url', (src: string) => {
        // ._. is used to trigger a reload, see
        if (src !== '._.') {
          this.ifrm.attr({ src });
        }
        // IE11: set window name after location is set
        (<HTMLIFrameElement>this.ifrm.get(0)).contentWindow.name = `myworkplace-${this.getId()}`;
      });
    });

    this.ifrm.on('load', this.handleFrameContentLoaded.bind(this));
    this._subscriptions.push(
      this._tabService.channel.subscribe(TabService.TOPIC_TAB_CLOSE, (message: ITabServiceChannelTabMessage) => {
        if (message.tabId === this.getId()) {
          const tab = this._tabService.getTabById(message.tabId);
          tab.waitForClose = true;
          this._workplaceApiService
            .waitForAppClose(this.getId())
            .then(() => this._workplaceApiService.notifyClose(this.getId()))
            .then(() => {
              this._appsService.removeFrame(this.getId());
              this._tabService.removeTab(this.getId());
            })
            .finally(() => (tab.waitForClose = false));
        }
      })
    );
    this._subscriptions.push(
      this._tabService.channel.subscribe(TabService.TOPIC_TAB_REFRESH, this.refreshFrame.bind(this))
    );
  }

  createConnection(): void {
    if (this.ifrm) {
      this._workplaceApiService.createConnection(
        this,
        (<HTMLFrameElement>this.ifrm.get(0)).contentWindow,
        this.frame.connectionGroupId
      );
    }
  }

  refreshFrame(message: ITabServiceChannelTabMessage): void {
    if (!(message.tabId === this.frame.id || message.tabId === this.frame.connectionGroupId)) {
      return;
    }
    if (!this._workplaceApiService.hasConnection(this.getId())) {
      (<HTMLFrameElement>this.ifrm.get(0)).src = this.touchReloadUrl((<HTMLFrameElement>this.ifrm.get(0)).src);
      return;
    }
    this._workplaceApiService.getConnection(this.getId()).then((connection: Connection) => {
      connection
        .callApi('refresh')
        .catch(
          () =>
            ((<HTMLFrameElement>this.ifrm.get(0)).src = this.touchReloadUrl((<HTMLFrameElement>this.ifrm.get(0)).src))
        );
    });
  }

  /**
   * Destroy the component
   */
  destroy(): void {
    this._workplaceApiService.closeConnection(this, this.frame.connectionGroupId);
    this._workplaceApiService = null;
    this._appsService = null;
    this._tabService = null;
    this._interframeDragService = null;
    this.ifrm.off('load', this.handleFrameContentLoaded);
    this.ifrm = null;
    if (this._subscriptions) {
      this._subscriptions.forEach((subscr: ISubscriptionDefinition<any>) => subscr.unsubscribe());
      this._subscriptions = null;
    }
    this.el = null;
    this.announcementsService.clearAnnouncementsSubscriptions(this.getId());
  }

  /**
   * inserts a randomly generated url param in order to try an force a refresh on iframes that don't register
   * themselves to workplaceApi.callApi('refresh');
   *
   * @input original url string
   * @return altered url string, with random param
   */
  private touchReloadUrl(orinigalUrl: string): string {
    const split: string[] = orinigalUrl.split('?');
    if (!split || split.length < 2) {
      return orinigalUrl + '?wpReload=' + Date.now();
    }

    if (split[1].indexOf('wpReload') !== -1) {
      /** frame has already been reloaded once, so the wpReload param is already present */
      const doubleSplit: string[] = split[1].split('&');
      if (doubleSplit.length > 1) {
        doubleSplit[0] = 'wpReload=' + Date.now();
        split[1] = doubleSplit.join('&');
      } else {
        split[1] = 'wpReload=' + Date.now();
      }
    } else {
      /** first reload. */
      split[1] = 'wpReload=' + Date.now() + '&' + split[1];
    }

    return split.join('?');
  }

  /**
   * Call roleChanged through workplace API for every app.
   */
  private notifyAppsOnRoleChange(): void {
    this._userService.getUser().then((user: User) => {
      const selectedRole: Role = user.getSelectedRole();

      this._workplaceApiService
        .getConnection(this.frame.id, this.frame.connectionGroupId)
        .then((connection: Connection) => {
          if (selectedRole && selectedRole !== null) {
            connection
              .callApi(
                'roleChanged',
                selectedRole.roleId,
                selectedRole.substitutedUser && selectedRole.substitutedUser !== null
                  ? selectedRole.substitutedUser.userId
                  : ''
              )
              .catch((reason: any) =>
                this._logService.info(
                  `roleChanged through workplace api failed on: ${this.getId()} because: ${reason.message}`
                )
              );
          }
        });
    });
  }

  /**
   * Check whether a supplied css value would result in a zero dimension
   * @param {string | number} dim
   * @returns {boolean}
   */
  private valueZeroOrAuto(dim: string | number): boolean {
    return !dim || dim === '0%' || dim === 'auto';
  }

  private _updateDeputy({ deputy, enabled }: IUserServiceDeputyUpdatedMessage) {
    return this._workplaceApiService.updateDeputy(this.getId(), this.getConnectionGroupId(), deputy, enabled);
  }
}
