import { ITabServiceChannelTabMessage, TabService } from '../tab/tab.service';
import { LayoutService } from '../layout/layout.service';
import { TrackingService } from '../feature-tracking/tracking.service';
import {
  EXIT_SPLIT_CANVAS,
  SPLIT_HORIZONTAL,
  SPLIT_VERTICAL,
} from '../components/analytics/feature-analytics-conf-layout';

/**
 * Component for view toggle
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class ViewToggleService {
  private _tabService: TabService;
  private _layoutService: LayoutService;

  private currentSplitDirection: string;

  private subscriptions: ISubscriptionDefinition<ITabServiceChannelTabMessage>[] = [];

  /**
   * @ngInject
   */
  constructor(tabService: TabService, layoutService: LayoutService, private trackingService: TrackingService) {
    this._tabService = tabService;
    this._layoutService = layoutService;

    const splitSubscription = this._tabService.channel.subscribe(
      TabService.TOPIC_TABVIEW_SPLIT,
      (message: ITabServiceChannelTabMessage) => {
        this.currentSplitDirection = message.split.direction;
      }
    );

    const unregisterSubscription = this._tabService.channel.subscribe(
      TabService.TOPIC_TABVIEW_UNREGISTER,
      (message: ITabServiceChannelTabMessage) => {
        if (this.currentSplitDirection !== 'full') {
          this.currentSplitDirection = this._layoutService.splitLayoutDirection();
        }
      }
    );

    this.subscriptions.push(splitSubscription, unregisterSubscription);
  }

  /**
   * Split screen is possible when
   * <ul>
   *     <li>more than one tab</li>
   *     <li>not already in split mode</li>
   *     <li>not in mobile layout</li>
   * </ul>
   * @returns {boolean}
   */
  canSplit(): boolean {
    return (
      this._tabService.getTabManagerDefault().tabs.length > 1 &&
      !this._layoutService.mobileLayout &&
      this._layoutService.layout.leaves().length <= 1
    );
  }

  /**
   * Whether fullscreen is possible (only when currently in split).
   * @returns {boolean}
   */
  canJoin(): boolean {
    return this._layoutService.isSplitLayout();
  }

  /**
   * Splits the layout vertically and moves the currently selected tab to the left view
   */
  splitScreen(direction: string): void {
    if (direction === 'full') {
      this.join();
      return;
    }

    this._tabService.splitTabview({
      tabviewId: this._tabService.tabManagerDefault,
      tabId: this._tabService.getTabManagerDefault().selectedTab.id,
      direction: direction,
      index: 1,
    });

    if (direction === 'row') {
      this.trackingService.getTracker().trackFeature(SPLIT_VERTICAL, 1);
    } else {
      this.trackingService.getTracker().trackFeature(SPLIT_HORIZONTAL, 1);
    }
  }

  /**
   * Joins 2 tabviews into one tabview
   */
  join(): void {
    const tabviews = this._layoutService.layout.leaves();
    if (tabviews.length > 1) {
      this._tabService.moveAllTabsFromTo(<string>tabviews[1].id, <string>tabviews[0].id);
      this._tabService.unregisterTabview(<string>tabviews[1].id);
      this._tabService.getTabManagerDefault().selectDefault();
    }

    this.trackingService.getTracker().trackFeature(EXIT_SPLIT_CANVAS, 1);
  }

  getCurrentSplitDirection(): string {
    if (!this.currentSplitDirection) {
      this.currentSplitDirection = this._layoutService.splitLayoutDirection();
    }

    return this.currentSplitDirection;
  }
}
