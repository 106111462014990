import { Component } from '../component';
import { IMenuItemModel } from '../../menu/menu.item.model';
import { MenuService } from '../../menu/menu.service';

import { LayoutService } from '../../layout/layout.service';
import { ILanguage } from '../../../util/language.model.interface';
import { User } from '../../user/user.model';
import { IUserServiceAuthUpdatedMessage, UserService } from '../../user/user.service';
import { APP_CATALOG, IApplication } from '../../apps/application.model.interface';
import { AppsService } from '../../apps/apps.service';
import { NotificationService } from '../../notification/notification.service';
import { DeviceService } from '../../../util/device.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import { WorkplaceApiService } from '../../workplace/workplace.api.service';
import { WorkplaceContextService } from '../../workplace/workplace.context.service';
import { isInternet } from '../../../util/host.utils';

/**
 * This is the component displayed exactly at the top of the navigation bar, above dashboards and apps.
 */

export class Navbarmenu extends Component {
  private authLevelUpdated: IChannelDefinition<IUserServiceAuthUpdatedMessage>;
  private menuId: string;
  private appsMenuEl: Element;
  private items: IMenuItemModel[];
  private userId: string;

  private _menuService: MenuService;
  private _layoutService: LayoutService;
  private _appsService: AppsService;
  private _dashboardsService: DashboardService;
  private _notificationService: NotificationService;
  private _user: User;

  private readonly isMobile: boolean;
  private authLevel: number;

  private tabViewId: string;

  private openAppsCatalogHandler = (event: MouseEvent) =>
    this._appsService.openAppByName({
      name: APP_CATALOG,
      queryParams: { appId: event.detail },
    });
  private openAppHandler = (event: MouseEvent) =>
    this._appsService.openAppByName({
      name: event.detail as unknown as string,
    });
  private createDashboard = (event: MouseEvent) => {
    this._dashboardsService.createDashboard(event as unknown as string);
  };
  private switchAuth = () => {
    this.openLoginModal();
  };

  /** @ngInject */
  constructor(
    readonly language: ILanguage,
    readonly layoutService: LayoutService,
    readonly menuService: MenuService,
    readonly notificationService: NotificationService,
    private readonly workplaceApiService: WorkplaceApiService,
    readonly userService: UserService,
    readonly deviceService: DeviceService,
    private readonly postal: IPostal,
    private readonly workplaceContextService: WorkplaceContextService
  ) {
    super();
    this.items = [];
    this._layoutService = layoutService;
    this._menuService = menuService;
    this._notificationService = notificationService;
    this.userService.getUser().then((user: User) => {
      this.userId = isInternet() ? user.cnumber : user.userId;
      this._user = user;
      this.authLevel = this._user.authLevel;
    });
    this.isMobile = this._layoutService.mobileLayout;
    this.setupAuthLevelChannel();
    this.tabViewId = this._layoutService.layout.id as string;
  }

  $onInit() {
    this._menuService.getMenuItemsById(this.menuId).then((items: IMenuItemModel[]) => {
      this.items = items;
    });
  }

  get visibleItems(): IMenuItemModel[] {
    return this._menuService.filterVisibleMenuItems(this.menuId, this.items, this._layoutService.responsiveInfo);
  }

  setupAuthLevelChannel() {
    this.authLevelUpdated = this.postal.channel(WorkplaceContextService.UPDATED_AUTH_LEVEL);
    this.authLevelUpdated.subscribe(
      WorkplaceContextService.UPDATED_AUTH_LEVEL,
      ({ authLevel }) => (this.authLevel = authLevel)
    );
  }

  copyToClipboard(text) {
    const messageKey = 'copyUrl.success';

    var dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    this._notificationService.showSuccess(messageKey);
  }

  private openLoginModal() {
    const strongAuth = this.authLevel === 1000 ? 4000 : 7000;
    this.workplaceApiService.checkStrongAuthPermissions({
      strongAuth,
    } as IApplication);
  }

  destroy(): void {
    this._menuService = null;
  }
}
