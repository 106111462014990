import { IAnalyticsFeatureConfig } from '@myworkplace/api';

export const LAYOUT_SETTINGS = 'layoutSettings';
export const SPLIT_HORIZONTAL = 'splitHorizontal';
export const SPLIT_VERTICAL = 'splitVertical';
export const EXIT_SPLIT_CANVAS = 'exitSplitCanvas';

export const LAYOUT_SETTINGS_FEATURES = new Map<string, IAnalyticsFeatureConfig>([
  [
    SPLIT_HORIZONTAL,
    {
      category: [LAYOUT_SETTINGS],
      name: 'Horizontal Split',
      steps: ['Horizontal Split'],
    },
  ],
  [
    SPLIT_VERTICAL,
    {
      category: [LAYOUT_SETTINGS],
      name: 'Vertical Split',
      steps: ['Vertical Split'],
    },
  ],
  [
    EXIT_SPLIT_CANVAS,
    {
      category: [LAYOUT_SETTINGS],
      name: 'Exiting Split Canvas',
      steps: ['Exit Split Canvas'],
    },
  ],
]);
