import { LayoutService } from '../../layout/layout.service';
import { getToken } from '../../app.auth';
import { IAnalytics, PlatformAnalyticsFactory } from '@myworkplace/api';
import { ActionLogService } from '../../actionLog/action-log.service';
import { EventListenerManager } from '../event-listener-manager';
import { UserService } from '../../user/user.service';
import { IUserSetting, IUserSettingOption } from '../../userSetting/user-setting.model.interface';
import { UserSettingService } from '../../userSetting/user-setting.service';
import { MwpStoreService } from '../mwp-store/mwp-store.service';
import { ITab } from '../../tab/tab';
import { ITabContent } from '../../tab/tab.content.interface';

export type MicroFrontend = HTMLElement & { getToken: () => string; analytics: IAnalytics; demoMode: string };
type NavigationComponentHandlers = { [key: string]: (event: any) => void };
type NavigationComponentsHandlers = { [key: string]: NavigationComponentHandlers };

export class NavigationComponentsService {
  private static readonly CREATE_ACTION_LOG_FUNC = 'createActionLog';
  private navigationHandlers: NavigationComponentsHandlers;
  protected searchEl: MicroFrontend;
  protected navbarMenusEl: MicroFrontend;

  /**
   * @ngInject
   */
  constructor(
    private readonly layoutService: LayoutService,
    private readonly actionLogService: ActionLogService,
    private readonly eventListenerManager: EventListenerManager,
    private readonly userService: UserService,
    private readonly userSettingService: UserSettingService,
    private readonly mwpStoreService: MwpStoreService
  ) {}

  /**
   * Import all navigation components and set their handlers
   */
  importAll(handlers: NavigationComponentsHandlers): void {
    this.navigationHandlers = handlers;
    this.importFinder();
    this.importNavbarMenus();
    // this.importStore();
    this.importIdCards();
    this.importDashboards();
    this.importSearch();
  }

  async importSearch(): Promise<void> {
    await import('mwpSearch');
    this.searchEl = this.getElement('mwp-search-desktop', 'mwp-search-mobile');
    if (!this.searchEl) return;
    this.addHandlersToWebComponent(this.searchEl, 'mwpSearch');
  }

  async importFinder(): Promise<void> {
    await import('finder');
    const finder = this.getElement('mwp-finder-component');
    this.mwpStoreService.setMwpStoreEl(finder);
    this.addHandlersToWebComponent(finder, 'finder');
  }

  async importNavbarMenus(): Promise<void> {
    await import('navbarRightSideMenus');
    this.navbarMenusEl = this.getElement('navbar-right-menus-desktop', 'navbar-right-menus-mobile');
    if (!this.navbarMenusEl) return;
    this.addHandlersToWebComponent(this.navbarMenusEl, 'navbarRightSideMenus');
    const user = await this.userService.getUser();
    if (user) {
      this.navbarMenusEl['user'] = user;
      const userImage = await this.getProfileImage();
      if (userImage) {
        this.navbarMenusEl['userImage'] = `./rest/user/settings/profileImage/${userImage}`;
      }
    }
  }

  //STORE IMPORTS - TO BE DELETED BEFORE THE FINDER RELEASE
  // async importStore(): Promise<void> {
  //   await import('mwpStore');
  //   const storeEl = this.getElement('mwp-store-desktop');
  //   if (!storeEl) return;
  //   this.mwpStoreService.setMwpStoreEl(storeEl);
  //   this.addHandlersToWebComponent(storeEl, 'mwpStore');
  // }

  async importHeaderTabs(tabViewId: string): Promise<MicroFrontend> {
    await import('headerTabs');
    const headerTabsEl = this.getElement(`header-tabs-${tabViewId}`);
    this.addHandlersToWebComponent(headerTabsEl, 'headerTabs');
    return headerTabsEl;
  }

  updateHeaderTabsInput(tabViewId: string, updatedTab: ITab<ITabContent>): void {
    const headerTabsEl = document.getElementById(`header-tabs-${tabViewId}`) as HTMLElement & {
      tabs: ITab<ITabContent>[];
    };
    if (headerTabsEl?.tabs) {
      headerTabsEl.tabs = headerTabsEl.tabs.map((tab: ITab<ITabContent>) =>
        tab.id === updatedTab.id ? updatedTab : tab
      );
    }
  }

  async importIdCards(): Promise<void> {
    await import('idCard');
    const idCards = document.querySelectorAll('id-card');
    if (idCards) {
      idCards.forEach((element: MicroFrontend) => (element.getToken = getToken));
    }
  }

  async importDashboards(): Promise<void> {
    await import('dashboards');
  }

  closeOverlays(): void {
    this.navbarMenusEl?.['closePopover']();
    this.navbarMenusEl?.['closeSidePanel']();
  }

  private addHandlersToWebComponent(element: MicroFrontend, name: string): void {
    const handlers = this.navigationHandlers[name];
    if (!handlers || !element) return;
    Object.keys(handlers).forEach((key: string) => {
      this.eventListenerManager.addEventListener(element, key, handlers[key]);
    });
  }

  private getElement(desktopId: string, mobileId?: string): MicroFrontend {
    if (this.layoutService.mobileLayout && mobileId) {
      return document.getElementById(mobileId) as MicroFrontend;
    } else {
      return document.getElementById(desktopId) as MicroFrontend;
    }
  }

  public getPlatformAnalytics(): IAnalytics {
    return PlatformAnalyticsFactory({
      callApi: (name: string, ...params: any[]): Promise<any> => {
        if (name !== NavigationComponentsService.CREATE_ACTION_LOG_FUNC) {
          return Promise.reject('Wrong API function');
        }
        const logEntry = params[0];
        this.actionLogService.logAction(logEntry);
      },
    });
  }

  private async getProfileImage(): Promise<any> {
    const settings: IUserSetting = await this.userSettingService.getSettingByDescription('profileImage');
    if (!settings) return;
    return settings.optionsList.find((option: IUserSettingOption) => option.description === 'profileImageSet')
      .values[0];
  }
}
