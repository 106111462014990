'use strict';
import { ActionLogService } from './actionLog/action-log.service';
import { IAppBehavior } from './appBehavior/app-behavior.model.interface';
import { WorkplaceContextService } from './workplace/workplace.context.service';
import { IWorkplaceProperty } from './workplace/workplace-property.interface';
import { UserSettingService } from './userSetting/user-setting.service';
import { PopupService } from './notification/popup.service';
import { SessionDataService } from './sessionData/session-data.service';
import { ILanguage } from '../util/language.model.interface';
import { AuthService } from './auth/AuthService';
import * as Interact from 'interactjs';
import { TasksService } from './task/tasks.service';
import { AuthTokenService } from './auth/auth-token.service';
import { getToken } from './app.auth';
import { OnboardingFlowService } from './web-components/onboarding-flow/onboarding-flow.service';
import { UserService } from './user/user.service';
import { DeviceService } from '../util/device.service';
import { LayoutService } from './layout/layout.service';
import { WorkplaceApiService } from './workplace/workplace.api.service';
import { NavigationComponentsService } from './web-components/navigation/navigation.service';

declare var DSHttpAdapter: any;

type TShellInputs = { lang: string };
export type TShell = Element & TShellInputs & { [key: string]: any };

/**
 * Run blocks (registered with module.run()) get executed after the injector has all the providers.
 * Now, all instances and constants can be injected. This is typically where you would configure services,
 * $rootScope, events and so on.
 *
 * Any code that needs to run when an application starts should be declared in a factory, exposed via a function,
 * and injected into the run block.
 *
 * Code directly in a run block can be difficult to test. Placing in a factory makes it easier to abstract and mock.
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class RunBlock {
  /** @ngInject */
  constructor(
    tasksService: TasksService,
    DSHttpAdapter: any,
    /*keep!*/ actionLogService: ActionLogService,
    sessionDataService: SessionDataService,
    $translate: angular.translate.ITranslateService,
    appBehavior: IAppBehavior,
    workplaceContextService: WorkplaceContextService,
    userSettingService: UserSettingService,
    popupService: PopupService,
    $location: ng.ILocationService,
    language: ILanguage,
    onboardingFlowService: OnboardingFlowService,
    authService: AuthService,
    private readonly authTokenService: AuthTokenService,
    readonly userService: UserService,
    readonly deviceService: DeviceService,
    readonly layoutService: LayoutService,
    readonly navigationComponentsService: NavigationComponentsService,
    featureFlags: any
  ) {
    updateDensityCacheOnStartup();

    //FastClick(document.body);
    tasksService.startPolling();
    workplaceContextService.getProperty('process.beforeunload').then((prop: IWorkplaceProperty) => {
      if (!prop || prop.value !== 'false') {
        appBehavior.addUserPromptedUnloadHandler();
      } else {
        appBehavior.addUnloadHandler();
      }
    });

    sessionDataService.restoreSession();
    // Removed guided tour startup: guidedTourStartup.onStartup();
    Interact.interact.dynamicDrop(true);

    /**
     * Make sure the language query parameter is always sent by the store calls (so not only when doing 'findAll')
     */
    DSHttpAdapter.defaults.queryTransform = (resourceConfig: any, params: any): void =>
      Object.assign({}, params, { lang: params.lang || language.lang });

    authService.checkWenCookie();

    import('shell').then(() => {
      const shell = document.querySelector('mwp-shell');
      shell['shellLanguage'] = language.lang;
      shell['shellGetToken'] = getToken;
      shell['shellDeviceType'] = deviceService.device;
      shell['shellIsMobile'] = layoutService.mobileLayout;
      shell['shellDemoMode'] = workplaceContextService.demoMode;
      shell['shellAnalytics'] = this.navigationComponentsService.getPlatformAnalytics();
      setUserInfo(userService, shell);
      onboardingFlowService.startOnboardingScreen({ triggeredFromInsideMyWorkplace: true });
      shell.addEventListener('languageChange', (event: Event) => {
        workplaceContextService.languageChanged.publish(WorkplaceContextService.UPDATED_LANGUAGE, event['detail']);
      });
    });

    workplaceContextService.getProperty('workplace.environment').then((prop: IWorkplaceProperty) => {
      const isDev = prop.value === 'dev';
      const isTest = prop.value === 'test';
      const isProd = prop.value === 'prod';
      const isInt = prop.value === 'int';
      const isEdu = prop.value === 'edu';

      featureFlags.set([
        {
          key: 'mwp-search',
          active: isTest || isDev || isInt,
          name: 'Search',
          description: 'Search built with Angular',
        },
        {
          key: 'header-tabs',
          active: isTest || isDev || isInt,
          name: 'Header Tabs',
          description: 'Tab view built with Angular',
        },
        {
          key: 'finder',
          active: isTest || isDev || isInt,
          name: 'Finder',
          description: 'Finder menu built with Angular',
        },
      ]);

      //TODO Check how to handle token in the core apps
      if (isDev || isTest || isInt || isEdu || isProd) {
        this.authTokenService.saveTokenOnSessionStorage();
      }

      workplaceContextService.setFeatureFlags(featureFlags.get());
    });
  }
}

async function updateDensityCacheOnStartup(): Promise<void> {
  const cache = await caches.open('density-styles-cache');
  fetchAndCache(cache, '/widgets/static-assets/styles/density-styles-ng.css');
  fetchAndCache(cache, '/widgets/static-assets/assets/images/mwp.svg');
}

async function fetchAndCache(cache: Cache, url: string): Promise<void> {
  try {
    const response = await fetch(url);
    await cache.put(url, response);
  } catch (error) {
    console.error(`Failed to fetch and cache ${url}:`, error);
  }
}

function setUserInfo(userService, shell): void {
  userService.getUser().then(userInfo => {
    if (userInfo) {
      shell['shellUserInfo'] = userInfo;
    }
  });
}
