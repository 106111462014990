import { TabService } from '../tab/tab.service';
import { IDashboard } from './dashboard.model.interface';
import { ITabContent, TabContentType } from '../tab/tab.content.interface';
import { ITabContentTemplate } from '../tab/tab.content.interface';
import { DeviceService } from '../../util/device.service';
import { IWidgetDescriptor } from '../widget/widget.descriptor.model';
import { Icons } from '../app.icons';
import { NotificationService } from '../notification/notification.service';
import { UserService } from '../user/user.service';
import { WorkplaceContextService } from '../workplace/workplace.context.service';
import { UserSettingService } from '../userSetting/user-setting.service';
import { ILanguage } from '../../util/language.model.interface';
import { SharingService } from '../sharing/sharing.service';
import { AppsService } from '../apps/apps.service';
import JSData from 'js-data';
import angular from 'angular';
import _ from 'lodash';
import { IUser } from '../user/user.model.interface';
import { WorkplaceApiService } from '../workplace/workplace.api.service';
import { IUserSettingsStorage } from '../workplace/user-settings-storage.interface';
import { IUserSettingsStoreable } from '../workplace/user-settings-storeable.interface';
import { ActionConstants } from '../actionLog/action-constants';
import { ActionLogService } from '../actionLog/action-log.service';
/**
 * Service for managing dashboards
 *
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class DashboardService implements IUserSettingsStoreable {
  static CHANNEL: string = 'DashboardServiceChannel';
  static TOPIC_WIDGET_ADDED: string = 'DashboardServiceWidgetAdded';
  static DASHBOARD_CREATED: string = 'DashboardServiceDashboardCreated';
  static DASHBOARD_DELETED: string = 'DashboardServiceDashboardDeleted';
  static DASHBOARD_RENAMED: string = 'DashboardServiceDashboardRenamed';
  static DASHBOARD_SHARE_INFO_UPDATED: string = 'DashboardServiceDashboardShareInfoUpdated';
  static DASHBOARD_REPLACED: string = 'DashboardServiceDashboardReplaced';
  static TOPIC_DASHBOARD_CONTEXT_UPDATE: string = 'DashboardServiceTopicContextUpdate';
  static TOPIC_DASHBOARD_CONTEXT_MENU_OPEN: string = 'DashboardServiceTopicContextMenuOpen';
  static TOPIC_DASHBOARD_CLOSE_WIDGET_CONTEXT_MENU: string = 'DashboardCloseWidgetContextMenu';
  readonly settingsStorageKey = 'workplace';
  private readonly RECENTLY_STORED_LIST_MAX_SIZE: number = 6;

  channel: IChannelDefinition<IDashboardServiceMessage>;

  private _dashboardQueryParams: any;
  private _tabService: TabService;
  private _qService: ng.IQService;
  private _postal: IPostal;
  private _store: JSData.DSResourceDefinition<IDashboard>;
  private _deviceService: DeviceService;
  private _notificationService: NotificationService;
  private _userService: UserService;
  private _sharingService: SharingService;
  private _workplaceContextService: WorkplaceContextService;
  private _userSettingService: UserSettingService;
  private _httpService: ng.IHttpService;
  private _language: ILanguage;
  private _apiService: WorkplaceApiService;
  private _actionLogService: ActionLogService;
  /**
   * @ngInject
   */
  constructor(
    tabService: TabService,
    $q: ng.IQService,
    postal: IPostal,
    dashboardStore: JSData.DSResourceDefinition<IDashboard>,
    deviceService: DeviceService,
    notificationService: NotificationService,
    userService: UserService,
    workplaceContextService: WorkplaceContextService,
    userSettingService: UserSettingService,
    $http: ng.IHttpService,
    language: ILanguage,
    sharingService: SharingService,
    actionLogService: ActionLogService,
    private appsService: AppsService,
    private $log: ng.ILogService,
    private readonly userSettingsStorageService: IUserSettingsStorage
  ) {
    this._tabService = tabService;
    this._qService = $q;
    this._postal = postal;
    this._store = dashboardStore;
    this.channel = this._postal.channel(DashboardService.CHANNEL);
    this._deviceService = deviceService;
    this._notificationService = notificationService;
    this._userService = userService;
    this._workplaceContextService = workplaceContextService;
    this._userSettingService = userSettingService;
    this._sharingService = sharingService;
    this._httpService = $http;
    this._language = language;
    this._actionLogService = actionLogService;
    this._dashboardQueryParams = {
      lang: this._language.lang,
      deviceType: this._deviceService.device,
      demoMode: this._workplaceContextService.demoMode,
    };
    this._sharingService.channel.subscribe(SharingService.DASHBOARD_ACCEPTED, (dashboard: IDashboard) => {
      if (!this.addDashboardToStore(dashboard)) {
        this._notificationService.showWarn('notification.sharing.dashboard.available', {
          title: dashboard.description,
        });
        return;
      }
      this._notificationService.showSuccess('notification.sharing.dashboard.accepted', {
        title: dashboard.description,
      });
    });
  }

  getSettingsStoragePath(): string[] {
    return ['recently-open'];
  }

  setApiService(apiService: WorkplaceApiService): void {
    this._apiService = apiService;
  }

  /**
   * Returns all dashboards.
   * @returns {IPromise<IDashboard[]>}
   */
  getDashboards(bypassCache: boolean = false): ng.IPromise<IDashboard[]> {
    const deferred = this._qService.defer<IDashboard[]>();
    const existingDashboards = this._store.getAll();
    if (bypassCache || !existingDashboards || existingDashboards.length === 0) {
      this._store
        .findAll(this._dashboardQueryParams, <JSData.DSAdapterOperationConfiguration>{ bypassCache })
        .then((dashboards: IDashboard[]) => deferred.resolve(dashboards));
    } else {
      deferred.resolve(existingDashboards);
    }

    return deferred.promise;
  }

  /**
   * Returns a dashboard by name
   * @returns {IPromise<IDashboard>}
   */
  getDashboard(name: string, bypassCache: boolean = false, onConflict = 'merge'): ng.IPromise<IDashboard> {
    const deferred = this._qService.defer<IDashboard>();
    /**
     * Check for already stored dashboard details
     * On first load, dashboards have few values and are given initialState = true
     * After details are fetched, store is updated and initialState is set to false.
     * Therefore a dashboard with initialState = false is ready to be used directly from store
     */
    const storedValues = this._store.getAll();
    const storedDashboard =
      storedValues &&
      storedValues.length > 0 &&
      storedValues.find((d: IDashboard) => d.name === name && !d.initialState);

    if (!!storedDashboard && !bypassCache) {
      /** already stored dashboard */
      deferred.resolve(storedDashboard);
    } else {
      /** get dashboard details from BE */
      this._userService.getUser().then((user: IUser) => {
        const params = Object.assign({}, this._dashboardQueryParams);
        params.businessRoles = user && user.getSelectedRole() ? user.getSelectedRole().roleId : null;
        this._httpService
          .get(`./rest/v2/dashboards/${name}`, { params: params })
          .then((response: angular.IHttpPromiseCallbackArg<IDashboard>) => {
            if (response && response.data) {
              /** remove after BE change */
              response.data.name = response.data.id;
              response.data.userCanEdit = true;
              /** update store */
              this._store.inject(response.data, { onConflict });
              deferred.resolve(response.data);
            } else {
              deferred.reject();
            }
          })
          .catch(() => deferred.reject());
      });
    }
    return deferred.promise;
  }

  /**
   * Open a dashboard.
   * If the dashboard is a strong Auth one and the current environment is not strong authenticated,
   * it will enforce the dash in a new strong authenticated tab.
   *
   * @param dashboard
   * @param context
   */
  async openDashboard(dashboard: IDashboard, context?: any): Promise<void> {
    if (!dashboard) {
      console.warn('DashboardService: openDashboard -> The given dashboard is undefined');
      return this._qService.reject();
    }
    var dashboardActionInfo: string;

    if (dashboard.parentName) {
      dashboardActionInfo = JSON.stringify([{ id: dashboard.parentId, name: dashboard.parentTitle }]);
    } else {
      dashboardActionInfo = JSON.stringify([{ id: dashboard.id, name: dashboard.title }]);
    }

    // Log the dashboard opening action
    this._actionLogService.logAction({
      category: ActionConstants.CATEGORY_DASHBOARD,
      action: ActionConstants.ACTION_DASHBOARD_START,
      actionInfo: dashboardActionInfo,
    });
    /** validate strongAuth permissions for opening the dashboard */
    const validateOpenDashboard = await this._apiService.checkStrongAuthPermissions(dashboard);
    if (!validateOpenDashboard) {
      this._notificationService.showError('oidc.permissions.denied');
      return;
    }
    this.storeRecentlyOpenedDashboards(dashboard);
    /** open dashboard */
    if (
      (!dashboard.multipleWindows || dashboard.multipleWindows === 0) &&
      this._tabService.getTabById(dashboard.name)
    ) {
      this.channel.publish(DashboardService.TOPIC_DASHBOARD_CONTEXT_UPDATE, {
        dashboard,
        context,
      });
      return this._tabService.selectTab(dashboard.name);
    }
    return this.getTabServiceObject(dashboard, context).then((tabServiceObject: any) => {
      this._tabService.openTab(tabServiceObject);
    });
  }

  private storeRecentlyOpenedDashboards(dashboard: IDashboard) {
    let newRecentlyOpen: { id: string }[] = [];
    let storedRecentlyOpenDashboards: {
      id: string;
      name: string;
      type: string;
    }[] = this.userSettingsStorageService.loadSettings(this);
    console.log(this.userSettingsStorageService.loadSettings(this));
    let mappedRecentlyOpenWithId = [];
    let mappedRecentlyOpenWithName = [];

    if (!storedRecentlyOpenDashboards) {
      this.userSettingsStorageService.saveSettings(this, [
        { id: dashboard.id, name: dashboard.name, type: 'dashboard' },
      ]);
      return;
    } else {
      // remove possible duplicates
      let noDuplicatesArray = [];
      for (const element of storedRecentlyOpenDashboards) {
        if (
          !(
            noDuplicatesArray.filter(
              c =>
                (element.id && c.id && element?.id === c?.id) || (c.name && element.name && c?.name === element?.name)
            ).length > 0
          )
        ) {
          noDuplicatesArray.push(element);

          if (element?.id !== undefined && element?.id !== null) {
            mappedRecentlyOpenWithId.push(element.id);
          }
          if (element?.name !== undefined && element?.name !== null) {
            mappedRecentlyOpenWithName.push(element.name);
          }
        }
      }

      storedRecentlyOpenDashboards = noDuplicatesArray;
    }

    if (mappedRecentlyOpenWithId.includes(dashboard.id)) {
      const index = storedRecentlyOpenDashboards.findIndex(dash => dash.id === dashboard.id);
      storedRecentlyOpenDashboards.splice(index, 1);
      this.userSettingsStorageService.saveSettings(this, [
        { id: dashboard.id, name: dashboard.name || '', type: 'dashboard' },
        ...storedRecentlyOpenDashboards,
      ]);
      return;
    }
    if (mappedRecentlyOpenWithName.includes(dashboard.name)) {
      const index = storedRecentlyOpenDashboards.findIndex(dash => dash.id === dashboard.id);
      storedRecentlyOpenDashboards.splice(index, 1);
      this.userSettingsStorageService.saveSettings(this, [
        { id: dashboard.id || '', name: dashboard.name, type: 'dashboard' },
        ...storedRecentlyOpenDashboards,
      ]);
      return;
    }

    if (storedRecentlyOpenDashboards?.length === this.RECENTLY_STORED_LIST_MAX_SIZE) {
      storedRecentlyOpenDashboards.pop();
    }

    newRecentlyOpen = storedRecentlyOpenDashboards;
    this.userSettingsStorageService.saveSettings(this, [
      { id: dashboard.id, name: dashboard.name },
      ...newRecentlyOpen,
    ]);
  }

  /**
   * Refresh a dashboard.
   *
   * @param id
   */
  refreshDashboard(id: string): ng.IPromise<IDashboard> {
    const deferred = this._qService.defer<IDashboard>();
    this._userService.getUser().then((user: IUser) => {
      const params = Object.assign({}, this._dashboardQueryParams);
      params.businessRoles = user && user.getSelectedRole() ? user.getSelectedRole().roleId : null;
      this._store
        .refresh(id, { params: params })
        .then((dashboard: IDashboard) => {
          deferred.resolve(dashboard);
          this.channel.publish(DashboardService.DASHBOARD_SHARE_INFO_UPDATED, {
            dashboardId: id,
          });
        })
        .catch(() => deferred.reject());
    });

    return deferred.promise;
  }

  removeFromRecentlyOpened(dashboardId: string): void {
    let storedRecentlyOpenDashboards: { id: string; name: string }[] =
      this.userSettingsStorageService.loadSettings(this);
    const filteredDashboards = storedRecentlyOpenDashboards.filter(item => item.id !== dashboardId);
    this.userSettingsStorageService.saveSettings(this, filteredDashboards);
  }

  /**
   * Open a dashboard by name
   */
  openDashboardByName(name: string, params?: any): ng.IPromise<IDashboard> {
    return this.getDashboards()
      .then(() => this.findDashboardInCache(name))
      .then((dashboard: IDashboard) => this.openDashboard(dashboard, params).then(() => dashboard));
  }

  /**
   * Used by the deep link functionality. If the given name is not found, tries to locate a personal
   * clone of the possibly given system dashboard.
   * @param {string} name
   * @param params
   */
  openDeepLinkDashboard(name: string, params?: any): ng.IPromise<IDashboard> {
    return this.openDashboardByName(name, params).catch(() =>
      this.getDashboards().then((dashboards: IDashboard[]) => {
        // if the dashboard is not found, maybe the given id refers to a system dashboard so we need to search
        // a personal clone of it to open
        const personalClone: IDashboard = dashboards.find((d: IDashboard) => d.parentName === name);
        if (personalClone) {
          this.openDashboard(personalClone, params);
          return personalClone;
        }
        console.log(
          `DashboardService -> openDeepLinkDashoard: the given dashboard ${name} is not a dashboard of the user or it doesn't exist`
        );
      })
    );
  }

  createDashboard(description: string): ng.IPromise<IDashboard> {
    const deferred = this._qService.defer<IDashboard>();
    if (!description || description.trim() === '') {
      deferred.reject();
    } else {
      this._httpService
        .post(`./rest/v2/dashboards`, {
          name: description,
          backgroundImageUri: null,
        })
        .then((response: ng.IHttpPromiseCallbackArg<string>) => {
          if (!response || !response.data) {
            deferred.reject();
          }
          this.getDashboard(response.data).then((dashboard: IDashboard) => {
            this.channel.publish(DashboardService.DASHBOARD_CREATED, {
              dashboard,
            });
            deferred.resolve(dashboard);
          });
        })
        .catch((err: any) => {
          console.log(err);
          this._notificationService.showError(err.message);
          deferred.reject();
        });
    }
    return deferred.promise;
  }

  /**
   * Injects a dashboard into the store, without calling the backend, if it is not already present.
   * @param dashboard
   * @return true - if the dashboard has been injected; false - if the dashboard is already in the store.
   */
  addDashboardToStore(dashboard: IDashboard): boolean {
    if (this._store.get(dashboard.name)) {
      return false;
    }
    /**
     * Dashboard isn't in store, so it is also closed
     * add dashboard id and parentId if null
     */
    if (!dashboard.id) {
      dashboard.id = dashboard.name;
    }
    if (!dashboard.parentId) {
      dashboard.parentId = dashboard.parentName;
    }
    if (!dashboard.title) {
      dashboard.title = dashboard.description;
    }
    /** remove categories, because they are not built correctly, they will be retrieved when opening the dashboard */
    dashboard.categories = null;
    this._store.inject(dashboard);
    this.channel.publish(DashboardService.DASHBOARD_CREATED, { dashboard });
    return true;
  }

  /**
   * Updates dashboard title in store
   * @param id
   * @param title
   */
  updateDashboardTitle(id: string, title: string): void {
    let dashboard = this._store.get(id);
    dashboard = { ...dashboard, title };
    this._store.inject(dashboard);

    // Update tab's title in cache
    this._tabService.saveSettings();
  }

  /**
   * Decides whether the given dashboard is owned by the current user.
   *
   * @param d either the dashboard name or an IDashboard instance.
   * @returns {IDashboard|IUser|boolean}
   */
  isOwnDashboard(d: string | IDashboard): boolean {
    const dashboard: IDashboard = this.findDashboardInCache(d);
    return (
      dashboard &&
      dashboard.owner &&
      this._userService.user &&
      this._userService.user.userId &&
      this._userService.user.userId === dashboard.owner.userId
    );
  }

  publishWidgetContextMenuClose(): void {
    this.channel.publish(DashboardService.TOPIC_DASHBOARD_CLOSE_WIDGET_CONTEXT_MENU);
  }

  /**
   * Find a cached dashboard, will return undefined if dashboards have not been loaded yet
   * @param d
   * @returns {undefined|IDashboard}
   */
  findDashboardInCache(d: string | IDashboard): IDashboard {
    return _.isString(d) ? this._store.get(d) : d;
  }

  getTabServiceObject(dashboard: IDashboard, params?: any): ng.IPromise<any> {
    const deferred = this._qService.defer<any>();
    this._userSettingService
      .autoStartDashboard(dashboard.name)
      .then((found: boolean) => {
        deferred.resolve({
          id: dashboard.name,
          title: dashboard.title,
          iconCls: dashboard.iconCls === null ? Icons.DASHBOARD_DEFAULT : dashboard.iconCls,
          refreshable: false,
          closeable: !found,
          content: <ITabContentTemplate>{
            type: TabContentType.TEMPLATE,
            dashboardName: dashboard.name,
            dashboardSharedBy: dashboard.sharedBy,
            dashboardParentName: dashboard.parentName,
            dashboardContext: params,
            template: `<app-dashboard dashboard-name="${dashboard.name}" dashboard-params="$parent.$parent.vm.content.content.dashboardContext"></app-dashboard>`,
            containsDashboard: true,
          },
        });
      })
      .catch(() => deferred.reject());

    return deferred.promise;
  }
}

/**
 * Describes messages send on the dashboard service channel
 */
export interface IDashboardServiceMessage {
  dashboard?: IDashboard;
  widget?: IWidgetDescriptor<any>;
  widgetId?: string;
  categoryId?: string;
  context?: any;
  dashboardId?: string;
  originDashboard?: IDashboard;
  /**
   * Forces enable dropzone in order to create a draggable item; used when adding widgets trough workplace API
   */
  dropzoneForceEnabled?: boolean;
}
