import angular from 'angular';
import { ITabContentIFrame, ITabContentTemplate, TabContentType } from '../tab/tab.content.interface';
import {
  ILayoutServiceChannelSidebarMessage,
  ILayoutServiceChannelUpdateMessage,
  LayoutService,
} from '../layout/layout.service';
import { ITabServiceChannelTabMessage, TabService } from '../tab/tab.service';
import { DeviceService } from '../../util/device.service';
import { ErrorService } from '../errors/error.service';
import {
  APP_CATALOG,
  ApplicationControlOptions,
  ApplicationControls,
  IApplication,
  StartMode,
} from './application.model.interface';
import { AppsFrameType, IAppsFrame } from './apps.frame.interface';
import { Layout } from '../layout/layout.model';
import { UrlHelper } from '../../util/url.helper';
import { WorkplaceContextService } from '../workplace/workplace.context.service';
import { IWorkplaceContext } from '../workplace/workplace.context.interface';
import { Icons } from '../app.icons';
import { MenuService } from '../menu/menu.service';
import { UserService } from '../user/user.service';
import { User } from '../user/user.model';
import { Role } from '../user/role.model';
import { NotificationService } from '../notification/notification.service';
import { TabManager } from '../tab/tab.manager';
import { PopupService } from '../notification/popup.service';
import { ILanguage } from '../../util/language.model.interface';
import { IFrameLayout } from '../frameLayout/frame-layout.model.interface';
import { IOperationControl } from '../dashboard/dashboard.model.interface';
import { WorkplaceNativeDeviceService } from '../workplace/workplace-native-device.service';
import { ActionLogService } from '../actionLog/action-log.service';
import { ActionConstants } from '../actionLog/action-constants';
import JSData from 'js-data';
import _ from 'lodash';

import { WorkplaceApiService } from '../workplace/workplace.api.service';
import { IUserSettingsStorage } from '../workplace/user-settings-storage.interface';
import { IUserSettingsStoreable } from '../workplace/user-settings-storeable.interface';

const AUTH_FLOW_THRESHOLD = 1000 * 60 * 60 * 4; // 4 hours

const IOS_AUTH_COMPLETE_THROTTLE = 1500;

/**
 * @author Tobias Straller [Tobias.Straller.bp@nttdata.com]
 */
export class AppsService implements IUserSettingsStoreable {
  readonly settingsStorageKey = 'workplace';
  private _tabService: TabService;
  private _layoutService: LayoutService;
  private _user: User;
  private readonly RECENTLY_STORED_LIST_MAX_SIZE: number = 6;
  private readonly APP_URL_STRONG_AUTH_RE = /strongAuth=([0-9]+)/;
  private readonly STRONG_AUTH_SERVICE_RE = /strongAuth([0-9]+)Service/;

  /**
   * Maps a generated id to the original application.
   * For apps that allow multiple windows we are creating separate frames with generated ids.
   */
  private _runningApps: { [key: string]: IApplication };
  private _openedWindows: { [key: string]: Window };
  private _blockedApps: { [key: string]: IApplication };
  private _frames: { [key: string]: IAppsFrame };
  private _timeoutService: ng.ITimeoutService;
  private _deviceService: DeviceService;
  private _workplaceContextService: WorkplaceContextService;
  private _translateService: angular.translate.ITranslateService;
  private _store: JSData.DSResourceDefinition<IApplication>;
  private _queueService: ng.IQService;
  private _errorService: ErrorService;
  private _logService: ng.ILogService;
  private _menuService: MenuService;
  private _userService: UserService;
  private _$: JQueryStatic;
  private _httpService: ng.IHttpService;
  private _notificationService: NotificationService;
  private _popupService: PopupService;
  private _apisecUrl: string;
  private _oauthToken: string;
  private _currentModalFrame: IAppsFrame;
  private _currentModalInstance: angular.ui.bootstrap.IModalServiceInstance;
  private _language: ILanguage;
  // a map holding the backend computed controls for a given app
  // key - app name (id),
  private _actionLogService: ActionLogService;
  // value - the operations allowed on the app. They will be displayed on the app's tab.
  private _appControlsMap: { [key: string]: IOperationControl[] };
  private _authFlow: { [key: string]: number };
  private headerTabsEl: Element & { [key: string]: any };
  private _apiService: WorkplaceApiService;

  /**
   * @ngInject
   */
  constructor(
    tabService: TabService,
    layoutService: LayoutService,
    deviceService: DeviceService,
    $timeout: ng.ITimeoutService,
    appsStore: JSData.DSResourceDefinition<IApplication>,
    $translate: angular.translate.ITranslateService,
    $q: ng.IQService,
    errorService: ErrorService,
    workplaceContextService: WorkplaceContextService,
    $log: ng.ILogService,
    menuService: MenuService,
    userService: UserService,
    jQuery: JQueryStatic,
    $http: ng.IHttpService,
    notificationService: NotificationService,
    popupService: PopupService,
    language: ILanguage,
    actionLogService: ActionLogService,
    private workplaceNativeDeviceService: WorkplaceNativeDeviceService,
    private $interval: ng.IIntervalService,
    private readonly userSettingsStorageService: IUserSettingsStorage
  ) {
    this._tabService = tabService;
    this._runningApps = {};
    this._openedWindows = {};
    this._blockedApps = {};
    this._frames = {};
    this._timeoutService = $timeout;
    this._layoutService = layoutService;
    this._menuService = menuService;
    this._store = appsStore;
    this._translateService = $translate;
    this._queueService = $q;
    this._deviceService = deviceService;
    this._errorService = errorService;
    this._workplaceContextService = workplaceContextService;
    this._logService = $log;
    this._userService = userService;
    this._$ = jQuery;
    this._userService.getUser().then((user: User) => (this._user = user));
    this._httpService = $http;
    this._notificationService = notificationService;
    this._popupService = popupService;
    this._apisecUrl = null;
    this._oauthToken = null;
    this._language = language;
    this._actionLogService = actionLogService;
    this._appControlsMap = {};
    this._authFlow = {};

    layoutService.channelUpdate.subscribe(
      LayoutService.TOPIC_UPDATE,
      this.layoutServiceUpdateMessageCallback.bind(this)
    );
    layoutService.channelSidebar.subscribe(
      LayoutService.TOPIC_SIDEBAR,
      this.layoutServiceSidebarMessageCallback.bind(this)
    );
    menuService.channelSubMenu.subscribe(MenuService.TOPIC_SUBMENU_SHOW, this.showHidePdfFrames.bind(this));
    menuService.channelSubMenu.subscribe(MenuService.TOPIC_SUBMENU_HIDE, this.showHidePdfFrames.bind(this));
    tabService.channel.subscribe(TabService.TOPIC_TAB_SELECT, this.tabServiceTabSelectMessageCallback.bind(this));
    tabService.channel.subscribe(TabService.TOPIC_TAB_MOVE, this.tabServiceTabMoveMessageCallback.bind(this));
  }

  getSettingsStoragePath(): string[] {
    return ['recently-open'];
  }

  setApiService(apiService: WorkplaceApiService): void {
    this._apiService = apiService;
  }

  /**
   * Get apps.
   *
   * @param {JSData.DSAdapterOperationConfiguration} storeParams
   * @param {boolean} includeHiddenApps default false -> hidden apps are not returned
   * @returns {ng.IPromise<IApplication[]>}
   */
  getApps(
    storeParams?: JSData.DSAdapterOperationConfiguration,
    includeHiddenApps?: boolean
  ): ng.IPromise<IApplication[]> {
    const deferred: ng.IDeferred<IApplication[]> = this._queueService.defer<IApplication[]>();

    this._userService.getUser().then(() => {
      this._store
        .findAll(
          {
            deviceType: this._deviceService.device,
            lang: this._language.lang,
            demoMode: this._workplaceContextService.demoMode,
          },
          _.merge(
            <JSData.DSAdapterOperationConfiguration>{
              noCache: true,
            },
            storeParams
          )
        )
        .then((data: IApplication[]) => {
          if (!includeHiddenApps) {
            data = this.filterHiddenApps(data);
          }
          deferred.resolve(data);
        })
        .catch(r => {
          deferred.reject(r);
        });
    });
    return deferred.promise;
  }

  /**
   * Get an app or group by its name.
   */
  getApp(name: string): ng.IPromise<IApplication> {
    const deferred: ng.IDeferred<IApplication> = this._queueService.defer();
    this.getApps(null, true)
      .then((apps: IApplication[]) => {
        const found = this.findApp(name, apps);
        if (found) {
          deferred.resolve(found);
        } else {
          deferred.reject();
        }
      })
      .catch(() => {
        this._logService.info(`AppsService -> getApp: Cannot not find app "${name}".`);
        deferred.reject();
      });
    return deferred.promise;
  }

  /**
   * Reload apps
   * @returns {ng.IPromise<IApplication[]>}
   */
  reloadApps(): ng.IPromise<IApplication[]> {
    this._store.ejectAll({});
    return this.getApps({ bypassCache: true, cacheResponse: true });
  }

  /**
   * Get the frames for all apps
   */
  getFrames(): IAppsFrame[] {
    return Object.values(this._frames);
  }

  /**
   * Get a frame by its id
   * @param id
   */
  getFrame(id: string): IAppsFrame {
    return this._frames[id];
  }

  /**
   * Find app in tree structure
   * @param name
   * @param root
   * @returns {IApplication} undefined in case app was not found
   */
  findApp(name: string, apps: IApplication[]): IApplication {
    if (apps) {
      return _.first(
        apps
          .map((app: IApplication): IApplication => {
            if (app.name === name) {
              return app;
            } else if (app.children) {
              return this.findApp(name, app.children);
            }
          })
          .filter((item: IApplication) => typeof item !== 'undefined')
      );
    }
  }

  /**
   *
   * @param app
   */
  findRunningApps(app: IApplication): string[] {
    return Object.keys(this._runningApps).filter((key: string) => {
      if (this._runningApps[key].name === app.name) {
        // we have found a running app
        if (this._openedWindows[key]) {
          // app is running in new browser window
          if (!this._openedWindows[key].closed) {
            return true;
          } else {
            // window was closed in meantime, remove it from running apps
            this._openedWindows[key] = null;
            delete this._openedWindows[key];
            this._runningApps[key] = null;
            delete this._runningApps[key];
            return false;
          }
        }
        return true;
      }
    });
  }

  /**
   * Fetches an app by bypassing the store (results are not automatically pushed into the store).
   * Currently only used when trying to open a forbidden app by means of deep linking,
   * @param {string} name
   * @return {angular.IPromise<IApplication>}
   */
  fetchSingleApp(name: string): ng.IPromise<IApplication> {
    return this._userService.getUser().then((user: User) => {
      return this._httpService
        .get(`rest/apps/${name}`, {
          params: {
            deviceType: this._deviceService.device,
            lang: this._language.lang,
            demoMode: this._workplaceContextService.demoMode,
            businessRoles:
              user.getSelectedRole() && user.getSelectedRole() !== null ? [user.getSelectedRole().roleId] : [],
          },
        })
        .then((response: any) => <IApplication>response.data);
    });
  }

  /**
   * Open an application by name.
   * @param tabviewId - the tabview that contains the tab
   * @param tabIndex - the position of the tab in the tabview
   * @param autoSelect - the position of the tab in the tabview
   */
  openAppByName(
    param: string | IOpenAppConfig,
    tabviewId?: string,
    tabIndex?: number,
    autoSelect: boolean = true
  ): ng.IPromise<string> {
    let deferred = this._queueService.defer<string>();
    let name = typeof param === 'string' ? param : param.name;
    this.getApp(name)
      .then((app: IApplication) => {
        let appDefinition = app;
        /** in case we have a parent menu item with the same id, we need the child, else we need the parent */
        if (app.children && app.children.length > 0) {
          const childApp = app.children.find((child: IApplication) => child.name === name);
          appDefinition = childApp ? childApp : app;
        }
        this.openApp(appDefinition, typeof param === 'string' ? null : param, tabviewId, tabIndex, autoSelect)
          .then((id: string) => deferred.resolve(id))
          .catch((reason: Error) => deferred.reject(reason));
      })
      .catch((reason: Error) => {
        console.log('apps.service -> 2: ', reason);
        // fetch app restrictions, if any
        this.fetchSingleApp(name)
          .then((app: IApplication) => {
            this._openBlockedApp(app, null, autoSelect);
            this._notificationService.showError('error.userInput.openApp', { name: name });
            this._errorService
              .throwUserInputError(new Error('error.userInput.openApp'), { name: name })
              .catch((reason: Error) => deferred.reject(reason));
          })
          .catch(() => {
            this._notificationService.showError('error.userInput.openApp', { name: name });
            this._errorService
              .throwUserInputError(new Error('error.userInput.openApp'), { name: name })
              .catch((reason: Error) => deferred.reject(reason));
          });
      });
    return deferred.promise;
  }

  /**
   * Open an application.
   * The app object is being cloned, so any modifications will not affect the instance in the store.
   */
  openApp(
    app: IApplication,
    config?: IOpenAppConfig,
    tabviewId?: string,
    tabIndex?: number,
    autoSelect: boolean = true
  ): ng.IPromise<string> {
    this.storeRecentlyOpenedApps(app);
    const deferred = this._queueService.defer<string>();
    const providedContextList =
      config && config.options && config.options.provideContextList && config.options.provideContextList.length
        ? config.options.provideContextList
        : this.getAppDefaultContext(app);
    this._workplaceContextService.getAppContext(...providedContextList).then((context: IWorkplaceContext) => {
      context = config && config.context && app.provideContext ? <IWorkplaceContext>{ ...config.context } : context;
      this._actionLogService.logAction({
        category: ActionConstants.CATEGORY_APPS,
        action: ActionConstants.ACTION_APPS_START,
        actionInfo: app.name,
      });
      // always update the conversation id!
      if (app.multipleWindows === 1) {
        context.conversationid = this._workplaceContextService.generateConversationId();
      }
      const clone = this._createAppClone(app, config, context);
      if (clone.startMode === StartMode.ENDPOINT) {
        // currently, config.path already contains the processed url for the app, not need for url computing.
        window.open(config.path);
        return;
      }
      if (clone.startMode === StartMode.WINDOW) {
        deferred.resolve(this._openAppInWindow(clone, true));
      } else if (
        clone.startMode === StartMode.BROWSER_TAB ||
        (clone.startMode === StartMode.SECURE_BROWSER && !this.workplaceNativeDeviceService.isPlatformIOS())
      ) {
        deferred.resolve(this._openAppInNewBrowserTab(clone));
        return;
      } else if ((config && config.options && config.options.modal) || clone.startMode === StartMode.MODAL_WINDOW) {
        deferred.resolve(this._openAppInModal(clone, config, app.iconCls));
        return;
      } else if (clone.startMode === StartMode.SECURE_BROWSER && this.workplaceNativeDeviceService.isPlatformIOS()) {
        this.workplaceNativeDeviceService.openWindowIOS(clone.url);
      } else {
        const conf: IOpenAppInTab = {
          app: clone,
          tabviewId,
          refreshUrl: config ? !!config.refreshUrl : false,
          tabTitle: config ? config.tabTitle : '',
          path: config && config.path ? config.path : null,
          tabIndex,
        };
        if (!this._appControlsMap[clone.name]) {
          this.getAppControls(clone);
        }
        /** validate strongAuth permissions for opening the app */
        return this._apiService
          .checkStrongAuthPermissions(conf.app)
          .then(() => {
            return this.openAppAuthFlow(conf.app, autoSelect)
              .then(() => {
                this._userService.getUser().then((user: User) => {
                  conf.app.url = conf.app.url.replace(this.APP_URL_STRONG_AUTH_RE, `strongAuth=${user.authLevel}`);
                  this.setStrongAuthServiceQueryParam(conf.app);

                  deferred.resolve(this._openAppInTab(conf, autoSelect));
                });
              })
              .catch((e: any) => {
                this._openBlockedApp(clone, e);
                deferred.reject(e);
              });
          })
          .catch((e: any) => {
            this._openBlockedApp(clone, 'oidc.permissions.denied');
            deferred.reject(e);
          });
      }
    });
    return deferred.promise;
  }

  // get app tab controls from the backend
  getAppControls(app: IApplication): ng.IPromise<any> {
    return this._httpService
      .get(`rest/apps/${app.name}/controls`)
      .then((response: any) => this.mapAppControls(app, response.data));
  }

  mapAppControls(app: IApplication, response: IOperationControl[]): void {
    if (!app) {
      return;
    }
    this._appControlsMap[app.name] = [];
    if (app.helpUrl) {
      const helpMenuControl = {
        [ApplicationControlOptions.SHOW_CONTEXT_HELP]: ApplicationControlOptions.SHOW_CONTEXT_HELP,
      };
      this._appControlsMap[app.name][ApplicationControls.APP_SETTINGS] = helpMenuControl;
    }
    response.forEach((control: IOperationControl) => {
      this._appControlsMap[app.name][control.control] = [];
      control.options.forEach((option: string) => (this._appControlsMap[app.name][control.control][option] = option));
    });

    this.headerTabsEl = document.querySelector('header-tabs');
    if (this.headerTabsEl) {
      this.headerTabsEl.tabs = this.headerTabsEl.tabs.map((tab: any) => {
        if (tab.id === app.name) {
          tab.tabOptions = Object.keys(this._appControlsMap[app.name]['app-settings']);
        }
        return tab;
      });
    }
  }

  hasControlAndOption(appId: string, control: string, option?: string): boolean {
    return (
      this._appControlsMap[appId] &&
      this._appControlsMap[appId][control] &&
      (option ? this._appControlsMap[appId][control][option] : true)
    );
  }

  /**
   * Will return a new array that does not contain hidden apps.
   *
   * @returns {IApplication[]}
   */
  filterHiddenApps(apps: IApplication[]): IApplication[] {
    return apps
      .filter((app: IApplication) => {
        return !this._isHiddenApp(app);
      })
      .map((app: IApplication) => {
        var clone = _.clone(app);
        if (clone.children && clone.children.length) {
          clone.children = this.filterHiddenApps(clone.children);
        }
        return clone;
      });
  }

  getAppWithUpdatedContext(app: IApplication): ng.IPromise<IApplication> {
    return this._workplaceContextService
      .getAppContext(...this.getAppDefaultContext(app))
      .then((context: IWorkplaceContext) => {
        const clone = _.clone(app);
        if (clone.multipleWindows === 1) {
          context.conversationid = this._workplaceContextService.generateConversationId();
        }
        clone.url = UrlHelper.mergeQueryString(app.url, context);
        return clone;
      });
  }

  /**
   * As a naming convention, all app names that start with the prefix "direct_", "hidden_" or "_" are hidden.
   *
   * @param {IApplication} app
   * @returns {boolean}
   * @private
   */
  _isHiddenApp(app: IApplication): boolean {
    return _.startsWith(app.name, 'direct_') || _.startsWith(app.name, 'hidden_') || _.startsWith(app.name, '_');
  }

  /**
   *
   * @param {IApplication} app
   * @param {IOpenAppConfig} config
   * @param context
   * @returns {IApplication}
   * @private
   */
  _createAppClone(app: IApplication, config: IOpenAppConfig, context: any): IApplication {
    const clone = _.cloneDeep(app);
    const selectedRole: Role = this._user.getSelectedRole();
    if (clone.provideContext && selectedRole && selectedRole !== null) {
      context.role = [];
      context.role.push(selectedRole.roleId);
    }
    if (clone.strongAuth && !context.strongAuth) {
      return clone;
    }
    if (clone.url) {
      if (config && config.path) {
        const origin = UrlHelper.getOriginFromUrl(app.url);
        if (origin === null) {
          const path = config.path.charAt(0) === '/' ? config.path.substring(1) : config.path;
          clone.url = `${app.url}/${path}`;
        } else {
          clone.url = origin + config.path;
        }
      }

      this.setStrongAuthServiceQueryParam(clone);
      clone.url = UrlHelper.appendQueryString(clone.url, Object.assign({}, context, config ? config.queryParams : {}));
      if (config && config.hash) {
        clone.url = UrlHelper.replaceHash(clone.url, config.hash);
      }
    }

    return clone;
  }

  private setStrongAuthServiceQueryParam(app: IApplication): void {
    const strongAuthService = this._apiService.getStrongAuthServiceParam();
    if (!strongAuthService) return;

    const appUrl = app.url;
    if (appUrl.search(this.STRONG_AUTH_SERVICE_RE) !== -1) {
      app.url = appUrl.replace(this.STRONG_AUTH_SERVICE_RE, strongAuthService);
      return;
    }

    app.url = UrlHelper.appendQueryString(appUrl, {
      strongAuthService,
    });
  }

  /**
   * Open app in new browser window
   * @param app
   * @param createConnection wether to also create a connection for the app
   * @private
   */
  _openAppInWindow(app: IApplication, createConnection: boolean, id?: string): string {
    if (this.workplaceNativeDeviceService.isPlatformIOS()) {
      // we do not care about running apps here
      this.workplaceNativeDeviceService.openWindow(app.url);
      return;
    }
    if (!id) {
      const runningApps = this.findRunningApps(app);
      id = runningApps.length > 0 ? _.uniqueId(app.name + '_') : app.name;
    }
    this._runningApps[id] = app;
    var name = app.multipleWindows ? _.uniqueId(app.name) : app.name;
    var params = [
      'height=768',
      'width=1024',
      'status=yes',
      'scrollbars=yes',
      'resizable=yes',
      'location=no',
      'toolbar=no',
      'menubar=yes',
      'titlebar=yes',
    ].join(',');
    this._openedWindows[id] = window.open(app.url, `myworkplace-${name}`, params);
    if (createConnection) {
      this.createConnectionToWindow(app, this._openedWindows[id], id);
    }
    return id;
  }

  /**
   * Open app in new browser window
   * @param {IApplication} app
   * @param {string} id
   * @returns {Window}
   */
  openAppInWindow(app: IApplication, id?: string): Window {
    id = this._openAppInWindow(app, false, id);
    return this._openedWindows[id];
  }

  /**
   * Open app in new browser tab
   * @param app
   * @private
   */
  _openAppInNewBrowserTab(app: IApplication, id?: string): string {
    if (this.workplaceNativeDeviceService.isPlatformIOS()) {
      // we do not care about running apps here
      this.workplaceNativeDeviceService.openInAppBrowser(app.url);
      return;
    }
    if (!id) {
      const runningApps = this.findRunningApps(app);
      id = runningApps.length > 0 ? _.uniqueId(app.name + '_') : app.name;
    }
    this._runningApps[id] = app;
    var name = app.multipleWindows ? _.uniqueId(app.name) : app.name;
    this._openedWindows[id] = window.open(app.url, `myworkplace-${name}`);
    this.createConnectionToWindow(app, this._openedWindows[id], id);
    return id;
  }

  /**
   * Open an app in a new tab
   * @param config {IOpenAppInTab}
   * @param tabviewId
   * @private
   */
  _openAppInTab(config: IOpenAppInTab, autoSelect: boolean = true): string {
    const runningApps = this.findRunningApps(config.app);
    if (config.app.multipleWindows === 0 && !this._blockedApps[config.app.name]) {
      if (runningApps.length > 0) {
        this.updateRunningApps(config.app, config.refreshUrl);
        if (config.tabTitle) {
          let tab = this._tabService.getTabById(config.app.name);
          tab.providedTitle = config.tabTitle;
          tab.title = config.tabTitle;
        }
        this._tabService.selectTab(config.app.name);
        return config.app.name;
      }
      if (this._openedWindows[config.app.name] && !this._openedWindows[config.app.name].closed) {
        this._openedWindows[config.app.name].focus();
        return config.app.name;
      }
    }
    const id = runningApps.length > 0 ? _.uniqueId(config.app.name + '_') : config.app.name;
    this._tabService.openTabAt(
      {
        id: id,
        title: this.createTabTitleForApp(config.app, runningApps.length),
        iconCls: config.app.iconCls,
        providedTitle: config.tabTitle,
        neverSelected: true,
        path: config && config.path ? config.path : null,
        content: <ITabContentIFrame>{
          type: TabContentType.IFRAME,
          app: config.app,
          containsPdf: (function (): boolean {
            return (
              config.app &&
              config.app.url &&
              config.app.url !== null &&
              config.app.url.toLowerCase().match('.pdf') !== null
            );
          })(),
        },
      },
      config.tabIndex ? config.tabIndex : null,
      config.tabviewId,
      autoSelect
    );
    if (this._tabService.getTabManagerDefault().tabs.length <= TabManager.MAX_TABS) {
      this.createFrame(id, config.app);
    }
    return id;
  }

  /**
   *
   * @param app
   * @returns {string}
   * @private
   */
  _openAppInStrongAuthWorkplace(app: IApplication, config: IOpenAppConfig, context: IWorkplaceContext): string {
    var id = _.uniqueId('' + new Date().getTime());
    if (_.endsWith(app.url, '/')) {
      app.url = app.url.substring(0, app.url.length - 1);
    }
    if (config && config.path) {
      app.url += encodeURIComponent(encodeURIComponent(config.path));
    }
    var queryString = '';
    if (config && config.queryParams) {
      queryString = UrlHelper.toQueryString(config.queryParams);
    }
    if (queryString) {
      app.url += '/' + queryString;
    } else if (config && config.hash) {
      app.url += '/-';
    }
    if (config && config.hash) {
      app.url += '/' + encodeURIComponent(encodeURIComponent(config.hash));
    }
    window.open(UrlHelper.appendQueryString(app.url, { c: id, lang: context.lang }), 'workplace_strong_auth_' + id);
    return app.name;
  }

  /**
   * Opens an app in a modal dialog
   * @param {IApplication} app
   * @param {IOpenAppConfig} config
   * @private
   */
  _openAppInModal(app: IApplication, config: IOpenAppConfig, icon: string): string {
    var modalId = app.name + '-modal';
    if (this._currentModalFrame) {
      // there is another modal app running
      throw new Error(`Error while opening app ${app.name}: Can only open one modal app!`);
    }
    if (!app.multipleWindows) {
      var ids = this.findRunningApps(app);
      if (ids.length) {
        // app is already running... just refresh if necessary and focus
        this.updateRunningApps(app, config.refreshUrl);
        ids.forEach((id: string) => {
          this._tabService.selectTab(id);
          if (this._openedWindows[id] && !this._openedWindows[id].closed) {
            this._openedWindows[id].focus();
          }
        });
        return ids[0];
      }
    }

    this._runningApps[modalId] = app;
    const frame: IAppsFrame = {
      id: modalId,
      app: app,
      visible: true,
      boundingBox: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      type: AppsFrameType.APP,
    };

    let title =
      config && !angular.isUndefined(config.tabTitle) && config.tabTitle !== null ? config.tabTitle : app.description;
    this._currentModalInstance = this._popupService.createModalWindow(
      'appDialog',
      {
        title,
        icon,
        frame,
      },
      true
    );

    this._currentModalInstance.result.finally(() => {
      this._currentModalFrame = null;
      this._runningApps[modalId] = null;
      delete this._runningApps[modalId];
    });

    this._currentModalInstance.result.catch(() => (this._currentModalFrame = null));

    this._currentModalFrame = frame;
    return modalId;
  }

  openAppAuthFlow(app: IApplication, autoSelect: boolean = true): ng.IPromise<boolean> {
    if (!this._checkAuthFlow(app)) {
      return this._queueService.resolve(false);
    }
    if (this.workplaceNativeDeviceService.isPlatformIOS()) {
      return this._openAppAuthFlowForIOS(app, autoSelect);
    }
    let deferred = this._queueService.defer<boolean>();
    let progressDialog: angular.ui.bootstrap.IModalServiceInstance;
    let checkClose: ng.IPromise<void>;
    let progressTimer: ng.IPromise<void>;
    let listener: EventListenerOrEventListenerObject;
    let loginWin: Window;

    let cleanup = () => {
      this.$interval.cancel(checkClose);
      this._timeoutService.cancel(progressTimer);
      if (progressDialog) {
        progressDialog.close();
      }
      if (listener) {
        window.removeEventListener('message', listener);
      }
      if (loginWin && !loginWin.closed) {
        loginWin.close();
      }
    };

    if (!app.authFlowUrl) {
      const message = 'Start mode was set to "A", but authFlowUrl was not provided';
      return this._queueService.reject(message);
    }
    let authFlowUrl = UrlHelper.appendQueryString(app.authFlowUrl, {
      r: _.uniqueId(`${Date.now()}`),
    });
    this._logService.log(`Using auth flow url for app ${app.name}: ${authFlowUrl}`);
    loginWin = window.open(authFlowUrl, `auth-${app.name}-${_.uniqueId()}`, 'width=375,height=700');
    if (!loginWin || loginWin.closed) {
      this._notificationService.showWarn('error.popup.blocked');
      return this._queueService.reject('error.popup.blocked');
    }

    checkClose = this.$interval(() => {
      if (loginWin && loginWin.closed) {
        cleanup();
        deferred.reject('apps.open.auth.flow.url.fail');
      }
    }, 200);

    const appOrigin = UrlHelper.getOriginFromUrl(authFlowUrl);
    listener = (e: MessageEvent): void => {
      if (e.origin !== appOrigin && e.origin !== window.location.origin) {
        return;
      }
      if (e.data === 'auth-complete') {
        cleanup();
        this._authFlow[app.name] = Date.now();
        deferred.resolve(true);
      }
    };
    window.addEventListener('message', listener);

    progressTimer = this._timeoutService(() => {
      progressDialog = this._popupService.createModalWindow('authFlowProgressDialog');
      progressDialog.result.catch(() => {
        cleanup();
        deferred.reject('apps.open.auth.flow.user.cancel');
      });
    }, 500);

    return deferred.promise;
  }

  _openAppAuthFlowForIOS(app: IApplication, autoSelect: boolean = true): ng.IPromise<boolean> {
    let deferred = this._queueService.defer<boolean>();
    let loadListener: EventListenerOrEventListenerObject;
    let exitListener: EventListenerOrEventListenerObject;
    let cleanup: Function;
    let loginWin: Window;

    if (!app.authFlowUrl) {
      const message = 'Start mode was set to "A", but authFlowUrl was not provided';
      return this._queueService.reject(message);
    }
    let authFlowUrl = UrlHelper.appendQueryString(app.authFlowUrl, {
      r: _.uniqueId(`${Date.now()}`),
    });
    this._logService.log(`Using auth flow url for app ${app.name}: ${authFlowUrl}`);

    loadListener = (e: any): void => {
      console.log('apps.service -> _openAppAuthFlowForIOS: loadstop event', e.url);

      // Parse the current event URL and the configured auth flow URL
      const eventUrl = new URL(e.url);
      const authFlowUrl = new URL(app.authFlowUrl);

      // Extract the origin and the last path component from both URLs
      const eventUrlOrigin = eventUrl.origin;
      const authFlowUrlOrigin = authFlowUrl.origin;
      const eventUrlLastPathComponent = eventUrl.pathname.split('/').pop();
      const authFlowUrlLastPathComponent = authFlowUrl.pathname.split('/').pop();

      // Check if both the origin and the last path component match
      if (eventUrlOrigin === authFlowUrlOrigin && eventUrlLastPathComponent === authFlowUrlLastPathComponent) {
        setTimeout(() => {
          loginWin.close();
          this._authFlow[app.name] = Date.now();
          deferred.resolve(true);
          cleanup();
        }, IOS_AUTH_COMPLETE_THROTTLE);
      }
    };

    exitListener = () => {
      deferred.reject('apps.open.auth.flow.url.fail');
      cleanup();
    };
    cleanup = () => {
      loginWin.removeEventListener('loadstop', loadListener);
      loginWin.removeEventListener('exit', loadListener);
    };
    loginWin = this.workplaceNativeDeviceService.openInAppBrowser(authFlowUrl);
    loginWin.addEventListener('loadstop', loadListener);
    loginWin.addEventListener('exit', exitListener);
    return deferred.promise;
  }

  /**
   *  We only start the auth flow for apps with startMode=A
   *  and when a certain time has passed since the last auth flow
   *  @returns boolean true if authflow is neccessary
   * @private
   */
  _checkAuthFlow(app: IApplication): boolean {
    return (
      app.startMode === StartMode.AUTH_FLOW &&
      (!this._authFlow[app.name] || Date.now() - this._authFlow[app.name] >= AUTH_FLOW_THRESHOLD)
    );
  }

  _openBlockedApp(app: IApplication, message?: string, autoSelect: boolean = true): void {
    if (app && app.name && app.name !== null) {
      if (this._runningApps[app.name]) {
        this._tabService.selectTab(app.name);
        return;
      }
      this._runningApps[app.name] = app;
      this._blockedApps[app.name] = app;
      return this._tabService.openTab(
        {
          id: app.name,
          title: app.description,
          iconCls: app.iconCls === null ? Icons.APP_DEFAULT : app.iconCls,
          closeable: true,
          neverSelected: true,
          content: <ITabContentTemplate>{
            type: TabContentType.TEMPLATE,
            app,
            message,
            template: `<app-blocked message='$parent.$parent.vm.content.content.message' app='$parent.$parent.vm.content.content.app'></app-blocked>`,
          },
        },
        app.name,
        autoSelect
      );
    }
  }

  /**
   * Close the currently opened modal app
   */
  closeModalApp(appId: string): void {
    if (this._currentModalInstance && this._currentModalFrame.id === appId) {
      this._currentModalInstance.close();
    }
  }

  /**
   * Open a list of apps in the same tabview. The app passed first will be selected.
   * @param apps
   * @param tabviewId
   */
  openApps(apps: IApplication[], tabviewId?: string): ng.IPromise<any[]> {
    const promises: ng.IPromise<string>[] = [];
    apps.reverse().forEach((app: IApplication) => promises.push(this.openApp(app, null, tabviewId)));
    return this._queueService.all(promises);
  }

  /**
   * Open help for the given app in a new tab
   * @param app
   */
  openHelp(app: IApplication | IFrameLayout): ng.IPromise<string> {
    return this._translateService('workplace.api.help.label', { description: app.description }).then(
      (translation: string) => {
        return this.openApp({
          name: app.name + '-help',
          description: translation,
          multipleWindows: 0,
          url: app.helpUrl,
          iconCls: Icons.HELP,
          startMode: 'B',
        });
      }
    );
  }

  /**
   * Update a running apps. there can be multiple instance of an app.
   * All instances will be updated with the new information.
   *
   * @param app
   */
  updateRunningApps(app: IApplication, refreshUrl: boolean): void {
    const runningApps = this.findRunningApps(app);
    runningApps.forEach((id: string) => this.updateFrameUrl(id, app.url, refreshUrl));
  }

  /**
   * Update the url of an iframe.
   * @param id
   * @param url
   */
  updateFrameUrl(id: string, url: string, refreshUrl: boolean = false): void {
    var frame = this._frames[id];
    if (!frame && id === this._currentModalFrame.id) {
      frame = this._currentModalFrame;
    }
    if (frame) {
      if (refreshUrl) {
        frame.app.url = '._.';
      }
      this._timeoutService(() => {
        frame.app.url = url;
      });
    }
  }

  /**
   * Create a new iFrame for the application
   */
  createFrame(id: string, app: IApplication): void {
    this._runningApps[id] = app;
    this._frames[id] = {
      id: id,
      app: app,
      visible: false,
      boundingBox: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
      type: AppsFrameType.APP,
    };
  }

  /**
   * Remove the iFrame
   * @param id
   */
  removeFrame(id: string, blocked: boolean = false): void {
    var app = this._runningApps[id];
    this._runningApps[id] = null;
    delete this._runningApps[id];
    this._frames[id] = null;
    delete this._frames[id];
    if (blocked) {
      this._blockedApps[id] = null;
      delete this._blockedApps[id];
    }
    if (app) {
      // Update tab titles
      Object.keys(this._runningApps)
        .filter((key: string) => {
          return this._runningApps[key].name === app.name;
        })
        .forEach((key: string, index: number) => {
          var tab = this._tabService.getTabById(key);
          var app = this._runningApps[key];
          if (tab) {
            tab.title = this.createTabTitleForApp(app, index);
          }
        });
    }
    this.updateFrames();
  }

  /**
   * Layout service update callback
   * @param data
   */
  layoutServiceUpdateMessageCallback(data: ILayoutServiceChannelUpdateMessage): void {
    this.updateFrames();
  }

  /**
   * Layout sidebar update callback.
   * @param data
   */
  layoutServiceSidebarMessageCallback(data: ILayoutServiceChannelSidebarMessage): void {
    this.showHidePdfFrames();
  }

  /**
   * Tab service tab selection update
   * @param data
   */
  tabServiceTabSelectMessageCallback(data: ITabServiceChannelTabMessage): void {
    this.updateFrames();
  }

  /**
   * Tab has been moved
   * @param data
   */
  tabServiceTabMoveMessageCallback(data: ITabServiceChannelTabMessage): void {
    this.updateFrames();
  }

  /**
   * Update the frames according to the layout
   */
  updateFrames(): void {
    this._timeoutService().then(() => {
      // hide all iFrames
      Object.values(this._frames).forEach((frame: IAppsFrame) => {
        frame.visible = false;
      });
      this._layoutService.layout.leaves().forEach((layout: Layout) => {
        var activeTab = this._tabService.getTabManager(<string>layout.id).selectedTab;
        if (activeTab) {
          var frame = this._frames[activeTab.id];
          if (frame) {
            var dim = layout.dimensions;
            frame.boundingBox = {
              top: `${100 * dim.top}%`,
              left: `${100 * dim.left}%`,
              right: `${100 - (100 * dim.left + 100 * dim.width)}%`,
              bottom: `${100 - (100 * dim.top + 100 * dim.height)}%`,
              'margin-left':
                (<Layout>layout.parent).direction === 'row' &&
                layout.parent &&
                layout.parent.children.indexOf(layout) > 0
                  ? '12px'
                  : 0,
              'margin-top':
                (<Layout>layout.parent).direction === 'column' &&
                layout.parent &&
                layout.parent.children.indexOf(layout) > 0
                  ? '56px'
                  : '40px',
            };
            frame.visible = true;
          }
        }
      });
    });
  }

  /**
   * Hides PDF displayed inside iframes when app runs in IE.
   * There's a bug in IE that always pushes the PDF on top of everything in this case.
   * This is a workaround.
   */
  showHidePdfFrames(): void {
    var show = true;
    Object.keys(this._layoutService.sidebars).forEach((sidebar: string) => {
      if (this._layoutService.sidebars[sidebar].expanded) {
        show = false;
      }
    });
    show = show && !this._menuService.hasActiveSubMenu();
    if (this._deviceService.isInternetExplorer()) {
      this._layoutService.layout.leaves().forEach((layout: Layout) => {
        var activeTab = this._tabService.getTabManager(<string>layout.id).selectedTab,
          pdfFrame;

        if (
          activeTab &&
          activeTab.content.type === TabContentType.IFRAME &&
          (<ITabContentIFrame>activeTab.content).containsPdf === true
        ) {
          pdfFrame = this.getFrame(activeTab.id);
          if (!_.isUndefined(pdfFrame) && pdfFrame !== null && pdfFrame.visible !== show) {
            pdfFrame.visible = show;
          }
        }
      });
    }
  }

  /**
   * Closes all apps that have been started in a new browser window
   */
  closeOpenedWindows(): void {
    Object.keys(this._openedWindows).forEach((key: string) => this._openedWindows[key].close());
  }

  /**
   * Returns whether the passed id belongs to an external window
   * @param {string} id
   * @returns {boolean}
   */
  isExternalWindow(id: string): boolean {
    return this._openedWindows[id] && !this._openedWindows[id].closed;
  }

  /**
   * Returns a list of external window apps ids.
   * @return {string[]}
   */
  getExternalWindowIds(): string[] {
    return Object.keys(this._openedWindows).filter(
      (key: string) => this._openedWindows[key] && this._openedWindows[key] !== null && !this._openedWindows[key].closed
    );
  }

  /**
   * Stores the app in the beginning of the list of recently open apps. If it is a hidden app, then the app is ignored
   * and therefore not added to the list.
   * @param application
   */
  storeRecentlyOpenedApps(application: IApplication) {
    if (this._isHiddenApp(application)) {
      return;
    }

    let newRecentlyOpen: { name: string }[] = [];
    const storedRecentlyOpenApps: { name: string }[] = this.userSettingsStorageService.loadSettings(this);

    if (!storedRecentlyOpenApps) {
      this.userSettingsStorageService.saveSettings(this, [{ name: application.name, type: 'app' }]);
      return;
    }

    const mappedRecentlyOpen: string[] = storedRecentlyOpenApps.map(u => u.name);

    if (mappedRecentlyOpen.includes(application.name)) {
      const index: number = mappedRecentlyOpen.indexOf(application.name);
      storedRecentlyOpenApps.splice(index, 1);
      this.userSettingsStorageService.saveSettings(this, [
        { name: application.name, type: 'app' },
        ...storedRecentlyOpenApps,
      ]);
      return;
    }

    if (storedRecentlyOpenApps?.length === this.RECENTLY_STORED_LIST_MAX_SIZE) {
      storedRecentlyOpenApps.pop();
    }

    newRecentlyOpen = storedRecentlyOpenApps;
    this.userSettingsStorageService.saveSettings(this, [{ name: application.name, type: 'app' }, ...newRecentlyOpen]);
  }

  /**
   * Creates a title for the app's tab. The index is used to distinguish between multiple instances of an app.
   * @param app
   * @param index
   */
  private createTabTitleForApp(app: IApplication, index: number): string {
    return index > 0 ? `${app.description} (${index + 1})` : app.description;
  }

  private getAppDefaultContext(app: IApplication): string[] {
    return app.provideContext
      ? [
          'env',
          'lang',
          'locale',
          'moduleOrg',
          'plOrg',
          'derivative',
          'strongAuth',
          'role',
          'deviceType',
          'demoMode',
          'mwpOrigin',
        ]
      : ['env', 'lang', 'strongAuth', 'mwpOrigin'];
  }

  private createConnectionToWindow(app: IApplication, win: Window, id: string): void {
    if (!this._apiService) {
      throw new Error('Dependency apiService is missing!');
    }
    this._apiService.createConnectionToWindow(app, win, id);
  }
}

/**
 * Open app configuration
 */
export interface IOpenAppConfig {
  name?: string;
  /**
   * Additional parameters appended to the app's url as querystring
   */
  queryParams?: { [key: string]: string | boolean | number | string[] | number[] | boolean[] };
  hash?: string;
  path?: string;
  /**
   * If set, will not create a new context for the app
   */
  context?: { [key: string]: string | boolean | number | string[] | number[] | boolean[] };
  /**
   * Will force the iframe src attribute to be set again
   */
  refreshUrl?: boolean;
  /**
   * provide an optional title for the app's tab
   */
  tabTitle?: string;
  options?: IOpenAppConfigOptions;
}

export interface IOpenAppConfigOptions {
  connect?: boolean;
  refresh?: boolean;
  splitView?: boolean;
  modal?: boolean;
  provideContextList?: string[];
}

export interface IOpenAppInTab {
  app: IApplication;
  tabviewId?: string;
  refreshUrl?: boolean;
  tabTitle?: string;
  tabIndex?: number;
  path?: string;
}
